import styled from '@emotion/styled';

export const UserPageContent = styled.div`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 10px 20px;

    border: 1px solid ${props => props.theme.border};
    border-radius: 20px;
    padding: 20px;
`;

export const ChannelInfo = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.backgroundHover};
    border-radius: 5px;
    padding: 5px;
    color: ${props => props.theme.text};

    margin: 0 0 20px 0;

    .channelItem{
        padding: 5px 10px 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .selected{
        color: ${props => props.theme.text};
        background-color: ${props => props.theme.background};
    }
`;

export const ChannelContent = styled.div`
    width: calc(100% - 20px);
    height: 100%;
    border: 1px solid ${props => props.theme.border};
    border-radius: 20px;
    padding: 10px;
`;
