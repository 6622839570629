import React from 'react';
import { QuestionHistoryResultContainer, ChatRoomHeader, RoomContainer } from '../../../themes/CommonDesignTheme/QuestionHistoryResultTheme';
import { IoChatbubbleOutline,IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { HiSortDescending, HiSortAscending } from "react-icons/hi";

const QuestionHistoryResult = ({ searchResults, selectedRoom, setSelectedRoom, setSelectedUser, reverseFLG, setReverseFLG }) => {
    return (
        <QuestionHistoryResultContainer searchResults={searchResults}>
            <div className="chatRoomContainer">
                <div className="chatRoomContents">
                    <ChatRoomHeader>
                        <span className="roomItem">名前</span>
                        <span className="roomItem">
                            <div className="ItemTitle">日時</div>
                            <div className="ItemSort" onClick={() => setReverseFLG(!reverseFLG)}>
                                {reverseFLG ? 
                                    <HiSortDescending size={20} /> 
                                    : <HiSortAscending size={20} />}
                            </div>
                        </span>
                        <span className="roomItem">タイトル</span>
                        <span className="roomItem">教材</span>
                        <span className="roomItem">ステータス</span>
                        <span className="roomItem">質問種別</span>
                        <span className="roomItem">メッセージ数</span>
                        <span className="roomItem">質問内容</span>
                    </ChatRoomHeader>
                    <RoomContainer>
                        {searchResults?.map((chatRoom, index) => (
                            <div
                                key={`${index}-${chatRoom.conversationId}`}
                                className={`room ${selectedRoom === chatRoom.conversationId ? 'active' : ''}`}
                                // onClick={() => toggleRoom(chatRoom)}
                            >
                                <span className="roomItem">{chatRoom.lastName} {chatRoom.firstName}</span>
                                <span className="roomItem">{chatRoom.dateTime}</span>
                                <span className="roomItem">{chatRoom.title}</span>
                                <span className="roomItem">{chatRoom.part}</span>
                                <span className="roomItem">{chatRoom.deleteFlg === '0' ? 
                                    '入会中' : '退会済(' + chatRoom.leaveTypeName + ')'}
                                </span>
                                <span className="roomItem">{chatRoom.type === '0' ? 'チャットボット' : 'コードレビュー'}</span>
                                <span className="roomItem textContent"> <IoChatbubbleOutline size={20} />{chatRoom.messageCount}</span>
                                <div className="questionContent roomItem" onClick={() => {
                                    setSelectedRoom(chatRoom.conversationId);
                                    setSelectedUser(chatRoom.userId);
                                }}>
                                    <IoChatbubbleEllipsesOutline size={20} />
                                    <div className="textContent">会話履歴</div>
                                </div>
                            </div>
                        ))}
                    </RoomContainer>
                </div>
            </div>
        </QuestionHistoryResultContainer>
    )
}

export default QuestionHistoryResult;