import styled from '@emotion/styled';

export const AccessCategoryDeleteModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const AccessCategoryDeleteCard = styled.div`
    width: 600px;
    height: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.systemBackground};
    padding: 20px;
    border-radius: 10px;

    .closeButton {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .deleteModalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: ${props => props.theme.systemBackground};

        span {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .deleteModalContent {
        padding: 10px;
        background-color: ${props => props.theme.systemBackground};

        span {
            display: block;
            width: 100%;
            font-size: 16px;
            margin-bottom: 5px;
        }

        .deleteModalContentAccessType {
            font-size: 16px;
            font-weight: bold;
            margin-top: 30px;
        }
    }

    .deleteModalButton {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 30px;

        .deleteModalButtonCancel {
            width: 100px;
            height: 40px;
            border-radius: 5px;
            background-color: ${props => props.theme.backgroundHover};
            border: none;
            cursor: pointer;
            padding: 5px 10px;

            &:hover {
                background-color: ${props => props.theme.background};
            }
        }

        .deleteModalButtonDelete {
            width: 100px;
            height: 40px;
            border: none;
            border-radius: 5px;
            background-color: ${props => props.theme.error};
            color: ${props => props.theme.text};
            cursor: pointer;
            padding: 5px 10px;
            margin-left: 10px;

            &:hover {
                background-color: ${props => props.theme.errorHover};
            }
        }
    }
`;
