import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegFaceSadTear } from "react-icons/fa6";
import { ErrorPageBox, ErrorContainer } from '../../../themes/Pages/Error/ErrorPageTheme';

const ErrorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { errorCode, errorMessage } = location.state || { errorCode: '', errorMessage: '' };

  const getErrorMessage = (code) => {
    switch (code) {
      case 404:
        return 'ページが見つかりませんでした';
      case 500:
        return 'サーバーエラーが発生しました';
      default:
        return 'エラーが発生しました';
    }
  };

  const handleGoHome = async () => {
    try {
      navigate('/login');
    } catch (error) {
      navigate('/error', { state: { errorCode: '', errorMessage: '' } });
    }
  };

  return (
    <ErrorPageBox>
      <ErrorContainer>
        <div className="errorMessageContainer">
          <FaRegFaceSadTear size={100} color={'#47845e'} />
          <h1>{errorCode}</h1>
          <h2>{errorMessage || getErrorMessage(errorCode)}</h2>
          <p>申し訳ありません、問題が発生しました。しばらくしてからもう一度お試しください。</p>
          <button onClick={handleGoHome}>トップページに戻る</button>
        </div>
      </ErrorContainer>
    </ErrorPageBox>
  );
};

export default ErrorPage;