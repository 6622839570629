import styled from '@emotion/styled';

export const BulkEditModalContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.modalBackground};
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .BulkEditModalEmpty{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .BulkEditModalEmptyTitle{
            font-size: 20px;
            font-weight: bold;
        }

        .BulkEditModalEmptyDescription{
            font-size: 16px;
        }
    }

    .BulkEditModalContent{
        width: 700px;
        height: 600px;
        background-color: ${props => props.theme.background};
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .CloseButton{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            font-size: 20px;
            color: ${props => props.theme.text};
        }

        .BulkEditModalHeader{
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .BulkEditModalHeaderTitle{
                font-size: 20px;
                font-weight: bold;
            }

            .BulkEditModalHeaderDescription{
                font-size: 16px;
            }
        }

        .BulkEditModalBody{
            width: 100%;
            height: calc(100% - 120px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .BodyHeader{
                position: relative;
                width: calc(100% - 40px);
                height: 50px;
                display: grid;
                grid-template-columns: 0.8fr 1.5fr 0.7fr 0.7fr;
                background-color: ${props => props.theme.backgroundHover};
                margin: 10px 10px 0px 10px;
                font-size: 14px;
                font-weight: bold;
                color: ${props => props.theme.text};
                align-items: center;

                .userNameHeader, .userEmailHeader, .memberTypeHeader, .accessTypeHeader{
                    padding-left: 10px;
                }
            }

            .BodyContent{
                position: relative;
                height: calc(100% - 50px);
                width: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                overflow-x: hidden;
                overflow-y: auto;

                ::-webkit-scrollbar{
                    width: 5px;
                }

                ::-webkit-scrollbar-thumb{
                    background-color: ${props => props.theme.border};
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-track{
                    background-color: ${props => props.theme.backgroundHover};
                }

                ::-webkit-scrollbar-track-piece{
                    background-color: ${props => props.theme.backgroundHover};
                }
                

                .user{
                    width: 100%;
                    height: 50px;
                    display: grid;
                    grid-template-columns: 0.8fr 1.5fr 0.7fr 0.7fr;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 0px;
                    border-bottom: 1px solid ${props => props.theme.border};

                    .userName, .userEmail, .memberType, .accessType{
                        padding-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }

        .BulkEditModalButton{
            width: calc(100% - 40px);
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .selectMemberType{
                width: fit-content;
                height: 30px;
                background-color: ${props => props.theme.backgroundHover};
                border: none;
                border-radius: 5px;
                margin-right: 10px;
                cursor: pointer;

                &:hover{
                    background-color: ${props => props.theme.backgroundHover};
                }
                
            }

            .selectAccessType{
                width: fit-content;
                height: 30px;
                background-color: ${props => props.theme.backgroundHover};
                border: none;
                border-radius: 5px;
                margin-right: 10px;
                cursor: pointer;

                &:hover{
                    background-color: ${props => props.theme.backgroundHover};
                }
            }

            .BulkEditModalButtonCancel{
                width: 100px;
                height: 30px;
                background-color: ${props => props.theme.backgroundHover};
                border: none;
                border-radius: 5px;
                cursor: pointer;

                &:hover{
                    background-color: ${props => props.theme.backgroundHover};
                }
            }

            .BulkEditModalButtonApply{
                margin-left: 10px;
                width: 100px;
                height: 30px;
                background-color: ${props => props.theme.primary};
                color: ${props => props.theme.buttonText};
                border: none;
                border-radius: 5px;
                cursor: pointer;

                &:hover{
                    background-color: ${props => props.theme.primaryHover};
                }

                &:disabled{
                    background-color: ${props => props.theme.disabled};
                    cursor: not-allowed;
                }
            }
        }
    }
`;
