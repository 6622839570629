export const fetchPartsProgressData = async (navigate, userId, partId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const partsProgressUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PARTS_PROGRESS_RATE}`;

        const response = await fetch(partsProgressUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ userId, partId })
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            console.error('パート別の進捗率の取得に失敗しました');
            navigate('/error', { state: { errorCode: response.status, errorMessage: 'パート別の進捗率の取得時にエラーが発生しました' } });
        }

    } catch (error) {
        console.error('APIエラー：パート別の進捗率の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'パート別の進捗率の取得に失敗しました' } });
    }
};

export const fetchPartDetailsData = async (navigate, userId, partId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const partDetailsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PART_DETAILS}?userId=${userId}&partId=${partId}`;

        const response = await fetch(partDetailsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            console.error('パート詳細の取得に失敗しました');
            navigate('/error', { state: { errorCode: response.status, errorMessage: 'パート詳細の取得時にエラーが発生しました' } });
        }

    } catch (error) {
        console.error('APIエラー：パート詳細の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'パート詳細の取得に失敗しました' } });
    }
};



