import styled from '@emotion/styled';

export const AdminPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
`;

export const MainContents = styled.main`
    width: calc(100vw - 230px);
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;

    ${props => props.isOpen && `
        width: calc(100% - 60px);
    `}
`;
