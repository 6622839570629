import React, { useState, useEffect } from 'react';
import { UserListDeleteCard, DeleteUserModalContainer } from '../../../themes/Pages/UserManagement/UserListDeleteModalTheme';
import { FaTimes } from 'react-icons/fa';
import FinalCheckModal from '../../Modals/FinalCheckModal';
import { fetchLeaveTypeList } from '../../../api/Common/common';
import { useNavigate } from 'react-router-dom';
import NotificationModal from '../../Modals/NotificationModal';

const UserListDeleteModal = ({ selectedUser, setOpenDeleteModal }) => {
    const navigate = useNavigate();
    const [openFinalCheckModal, setOpenFinalCheckModal] = useState(false);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [selectedLeaveType, setSelectedLeaveType] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);

    useEffect(() => {
        async function getLeaveTypeList() {
            const result = await fetchLeaveTypeList(navigate);
            setLeaveTypeList(result);
        }
        getLeaveTypeList();
        //eslint-disable-next-line
    }, [navigate]);

    const handleCancel = () => {
        setOpenDeleteModal(false);
    }

    const handleFinalCheck = () => {
        setOpenFinalCheckModal(true);
    }

    return (
        <>
            <DeleteUserModalContainer>
                <UserListDeleteCard>
                    <div className='closeButton'>
                        <FaTimes size={20} onClick={handleCancel} cursor="pointer" />
                    </div>
                    <div className='DeleteUserModalTitle'>ユーザーを退会させますか？</div>
                    <div className='DeleteUserModalContent'>
                        <div className='DeleteUserModalContentText'>{selectedUser.userLastName}{selectedUser.userFirstName}({selectedUser.mail})のアカウントを退会させます。この操作は取り消せません。</div>
                    </div>
                    <div className='selectContainer'>
                        <select className='select' onChange={(e) => setSelectedLeaveType(e.target.value)}>
                            <option value=''>退会区分を選択してください</option>
                            {leaveTypeList?.map((leaveType) => (
                                <option key={leaveType.leaveTypeCode} value={leaveType.leaveTypeCode}>{leaveType.leaveTypeName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='buttonContainer'>
                        <button className='cancelButton' onClick={handleCancel}>キャンセル</button>
                        <button className='deleteButton' onClick={handleFinalCheck} disabled={!selectedLeaveType}>退会</button>
                    </div>
                </UserListDeleteCard>
            </DeleteUserModalContainer>
            {openFinalCheckModal &&
                <FinalCheckModal
                    data={selectedUser}
                    onClose={() => setOpenFinalCheckModal(false)}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenFinalCheckModal={setOpenFinalCheckModal}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                    selectedLeaveType={selectedLeaveType}
                    leaveTypeList={leaveTypeList}
                />
            }
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )
}

export default UserListDeleteModal;