import React, { useState } from 'react';
import { AccessCategoryResultContainer } from '../../../themes/Pages/OperationManagement/AccessCategoryResultTheme';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { formatDateDateAndTime } from '../../../utils/DateUtils';
import AccessCategoryEditModal from './AccessCategoryEditModal';
import AccessCategoryDeleteModal from './AccessCategoryDeleteModal';
import NotificationModal from '../../Modals/NotificationModal';

const AccessCategoryResult = ({ accessTypeList }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedAccessType, setSelectedAccessType] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');

    const handleEditAccessType = (accessType) => {
        setIsEditModalOpen(true);
        setSelectedAccessType(accessType);
    }

    const handleDeleteAccessType = (accessType) => {
        setIsDeleteModalOpen(true);
        setSelectedAccessType(accessType);
    }

    return (
        <>
            <AccessCategoryResultContainer>
                <div className="accessCategoryResultHeader">
                    <span className='resultContent'>No.</span>
                    <span className='resultContent'>アクセス区分コード</span>
                    <span className='resultContent'>アクセス区分名</span>
                    <span className='resultContent'>ステータス</span>
                    <span className='resultContent'>登録日時</span>
                    <span className='resultContent'>登録者</span>
                    <span className='resultContent'>更新日時</span>
                    <span className='resultContent'>更新者</span>
                    <span className='resultContent'>アクション</span>
                </div>
                <div className="accessCategoryResultContent">
                    {accessTypeList?.length > 0 && accessTypeList?.map((accessType, index) => (
                        <div key={`${index}-${accessType?.accessTypeCode}`} className="accessCategoryResultRow">
                            <span className='resultContent'>{index + 1}</span>
                            <span className='resultContent'>{accessType?.accessTypeCode}</span>
                            <span className='resultContent'>{accessType?.accessTypeName}</span>
                            <span className={`resultContent status ${accessType?.deleteFlg === '0' ? 'activeText' : 'inactiveText'}`}>
                                {accessType?.deleteFlg === '0' ? '有効' : '無効'}
                            </span>
                            <span className='resultContent'>{accessType?.createDate ? formatDateDateAndTime(accessType?.createDate) : '登録日時データなし'}</span>
                            <span className='resultContent'>{accessType?.createUser ? accessType?.createUser : '登録者データなし'}</span>
                            <span className='resultContent'>{accessType?.updateDate ? formatDateDateAndTime(accessType?.updateDate) : '更新日時データなし'}</span>
                            <span className='resultContent'>{accessType?.updateUser ? accessType?.updateUser : '更新者データなし'}</span>
                            <span className='resultContent'>
                                <div className="actionContainer">
                                    <button className='EditButton' onClick={() => handleEditAccessType(accessType)} disabled={accessType?.deleteFlg === '1'}>
                                        <FaRegEdit className='ButtonIcon' size={15} />
                                        <div className='ButtonText'>編集</div>
                                    </button>
                                    <button className='DeleteButton' onClick={() => handleDeleteAccessType(accessType)} disabled={accessType?.deleteFlg === '1'}>
                                        <FaRegTrashAlt className='ButtonIcon' size={15} />
                                        <div className='ButtonText'>削除</div>
                                    </button>
                                </div>
                            </span>
                        </div>
                    ))}
                </div>
            </AccessCategoryResultContainer>
            {isEditModalOpen && (
                <AccessCategoryEditModal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    selectedAccessType={selectedAccessType}
                    setSelectedAccessType={setSelectedAccessType}
                />
            )}
            {isDeleteModalOpen && (
                <AccessCategoryDeleteModal
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    selectedAccessType={selectedAccessType}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />
            )}
            {isNotificationOpen && (
                <NotificationModal
                    isOpen={isNotificationOpen}
                    message={notificationMessage}
                    onClose={() => setIsNotificationOpen(false)}
                    type={notificationType}
                />
            )}
        </>
    );
};

export default AccessCategoryResult;