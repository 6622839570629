import React, { useState, useEffect } from 'react';
import QuestionHistorySearch from '../CommonDesign/QuestionHistorySearch';
import QuestionHistoryResult from '../CommonDesign/QuestionHistoryResult';
import QuestionHistoryChatModal from '../../Modals/QuestionHistoryChatModal';
import Pagination from '../CommonDesign/Pagination';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import CommonLayout from '../../WholeDesign/CommonLayout';
import { useOutletContext } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';

const QuestionHistory = () => {
    const [searchResults, setSearchResults] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [page, setPage] = useState(1);
    const [reverseFLG, setReverseFLG] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { setSelectedMenu, sideMenuPageList } = useOutletContext();

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '03')?.id);
    }, [setSelectedMenu, sideMenuPageList]);

    const header = (
        <>
            <h2 className="title">AI質問履歴検索</h2>
        </>
    )

    const content = (
        <>
            <QuestionHistorySearch
                setSearchResults={setSearchResults}
                page={page}
                setPage={setPage}
                reverseFLG={reverseFLG}
                setReverseFLG={setReverseFLG}
                setIsLoading={setIsLoading}
            />
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                    <SyncLoader color="#36d7b7" />
                </div>
            ) : searchResults && searchResults.totalRooms > 0 && searchResults.chatRoomList && searchResults.chatRoomList.length > 0 ? (
                <>
                    <QuestionHistoryResult
                        searchResults={searchResults.chatRoomList}
                        selectedRoom={selectedRoom}
                        setSelectedRoom={setSelectedRoom}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        reverseFLG={reverseFLG}
                        setReverseFLG={setReverseFLG}
                    />
                    {searchResults?.totalRooms > UserDetailDisplayLimitList[0] && (
                        <Pagination
                            currentPage={page}
                            totalPages={Math.ceil(searchResults.totalRooms / UserDetailDisplayLimitList[0])}
                            onPageChange={setPage}
                        />
                    )}
                </>
            ) : (
                <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff4d4d', margin: '20px' }}>
                    データがありません
                </div>
            )}
            {selectedRoom && <QuestionHistoryChatModal selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} userId={selectedUser} />}
        </>
    )


    return (
        <CommonLayout
            header={header}
            content={content}
        />
    );
};

export default QuestionHistory;