import styled from '@emotion/styled';

export const UserPageProgressContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;

export const SearchPart = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .searchIcon{
        position: absolute;
        left: 15px;
        transform: translateY(10%);
    }

    input{
        padding-left: 30px;
        width: calc(100% - 55px);
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        margin: 5px 10px 5px 10px;
    }
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0 10px 10px;

    .tab{
        padding: 2px 10px;
        font-size: 12px;
        font-weight: bold;
        border: 1px solid ${props => props.theme.border};
        border-radius: 20px;
        cursor: pointer;
    }

    .active{
        background-color: ${props => props.theme.text};
        color: ${props => props.theme.buttonText};
    }
`;

export const PartsContainer = styled.div`
    width: calc(100% - 20px);
    margin: 5px 10px 5px 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
`;

export const PartCard = styled.div`
    border: 1px solid ${props => props.theme.border};
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 120px;

    .attributes{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 10px;
        height: 80%;

        .partTitleAndCategory{
            display: flex;
            flex-direction: column;

            .partTitle{
                font-size: 16px;
            }

            .categoryBox{
                display: flex;
                flex-wrap: wrap;

                .partCategory{
                    margin: 2px 5px 2px 5px;
                    padding: 2px 5px 2px 5px;
                    border-radius: 5px;
                    background-color: ${props => props.theme.backgroundHover};
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                }
            }
        }
    }

    img{
        width: 50px;
        height: 50px;
    }

    .progress{
        width: 100%;
        height: fit-content;
        border-radius: 5px;
        position: relative;

        .progressRate{
            width: 100%;
            height: fit-content;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .progressRateTitle{
                font-size: 12px;
                font-weight: bold;
            }

            .progressRateValue{
                font-size: 12px;
            }
        }

        .progressBar{
            width: 100%;
            height: 10px;
            position: relative;
            background-color: ${props => props.theme.backgroundHover};
            border-radius: 5px;

            .completeBar{
                height: 10px;
                border-radius: 5px;
            }
        }
    }

    &.selected{
        background-color: ${props => props.theme.backgroundHover};
    }

    &:hover{
        background-color: ${props => props.theme.backgroundHover};
        cursor: pointer;
    }
`;


