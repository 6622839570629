import React from 'react';
import { ActiveUserCard } from '../../../themes/Pages/Home/Card_ActiveUserTheme';

const ActiveUser = ({ activeUser }) => {
    return (
        <ActiveUserCard>
            <div className="Title">
                <p>アクティブユーザー数</p>
            </div>
            <div className="Value">
                <p>{activeUser}</p>
                <div className="Supplement">
                    <p>直近１週間以内にログインあり</p>
                </div>
            </div>
        </ActiveUserCard>
    )
}

export default ActiveUser;