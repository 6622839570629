import styled from '@emotion/styled';

export const LearningProgressResultContainer = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;

    .SearchResultContainer{
        display: flex;
        flex-direction: column;

        .SearchResultHeader{
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 0.8fr 0.6fr 0.5fr 0.7fr 1.4fr 1fr 1.2fr 1.1fr 1fr;
            background-color: ${props => props.theme.background};
            padding: 10px;
            font-size: 14px;
            font-weight: bold;
            color: ${props => props.theme.text};

            .SearchResultHeaderItem{
                font-weight: bold;

                .progressHeader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100% - 15px);
                }

                .ItemTitle{
                    font-weight: bold;
                }

                .ItemSort{
                    display: flex;
                    align-items: center;
                    padding: 5px; 
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    cursor: pointer;
                    margin-right: 5px;

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }
                }
            }
        }

        .SearchResultBody{
            display: flex;
            flex-direction: column;
            padding: 0px;
            margin-top: 0px;
            font-size: 14px;

            .SearchResultItem{
                width: calc(100% - 20px);
                display: grid;
                grid-template-columns: 0.8fr 0.6fr 0.5fr 0.7fr 1.4fr 1fr 1.2fr 1.1fr 1fr;
                border-bottom: 1px solid ${props => props.theme.border};
                padding: 15px 10px;
                align-items: center;

                .SearchResultBodyItem{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    
                    .SearchResultBodyItemLink{
                        border: 1px solid ${props => props.theme.border};
                        border-radius: 5px;
                        padding: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-decoration: none;
                        color: ${props => props.theme.text};
                        cursor: pointer;
                        transition: background-color 0.3s ease-in-out;

                        &:hover{
                            background-color: ${props => props.theme.backgroundHover};
                        }

                        .SearchResultBodyItemText{
                            margin: 0 10px 0 5px;
                        }

                        .SearchResultBodyItemIcon{
                            margin: 0 5px 0 0;
                        }
                    }
                }
            }
        }
    }
`;

export const ProgressContainer = styled.div`
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .progressBar{
        width: 60px;
        height: 10px;
        background-color: ${props => props.theme.border};
        border-radius: 5px;
        margin-right: 10px;
        overflow: hidden;
    }

    .progressBarInner{
        height: 100%;
        background-color: ${props => props.theme.text};
        border-radius: 5px;
    }

    .progressRate{
        font-size: 12px;
    }
`;


