import styled from '@emotion/styled';

export const UserListSearchContainer = styled.div`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
    padding: 20px;

    .InputArea {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 14px;
            width: 12rem;
            text-align: right;
        }

        input {
            height: 30px;
            width: calc(100% - 12rem - 20px);
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0 5px;
            margin-left: 10px;

            &:focus {
                outline: none;
                border: 1px solid ${props => props.theme.primary};
            }
        }
    }

    .SelectArea {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
            font-size: 14px;
            width: 12rem;
            text-align: right;
        }

        select {
            width: fit-content;
            height: 30px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0 5px;
            margin-left: 10px;

            &:focus {
                outline: none;
                border: 1px solid ${props => props.theme.primary};
            }
        }

        .displayLengthText {
            font-size: 14px;
            margin-left: 5px;
            margin-bottom: 5px;
            align-self: flex-end;
        }

        .date-container {
            display: flex;
            align-items: center;
            margin-left: 10px;
            flex-direction: row;

            input {
                width: 100px;
                height: 30px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 0 5px;
            }

            .date-separator {
                margin: 0 5px;
            }
        }
    }

    .ButtonArea {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
            width: 7%;
            height: 30px;
            background-color: ${props => props.theme.primary};
            color: ${props => props.theme.buttonText};
            border: none;
            border-radius: 5px;
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.primaryHover};
            }
        }
    }
`;