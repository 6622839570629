import React from 'react';
import { FinalCheckModalContainer, ModalCard } from '../../themes/ModalThemes/FinalCheckModalTheme';
import { quitUser/*, deleteUser */ } from '../../api/UserManagement/userList';
import { useNavigate } from 'react-router-dom';

const FinalCheckModal = ({
    data,
    onClose,
    setOpenDeleteModal,
    setOpenFinalCheckModal,
    setNotificationMessage,
    setNotificationType,
    setIsNotificationOpen,
    selectedLeaveType,
    leaveTypeList,
    onConfirm
}) => {
    const navigate = useNavigate();

    const handleConfirm = async () => {
        // カスタム確認ハンドラが提供されている場合はそれを使用
        if (onConfirm) {
            onConfirm();
            return;
        }

        // デフォルトの退会処理（通常のユーザー向け）
        data.leaveTypeCode = selectedLeaveType;
        data.leaveTypeName = leaveTypeList.find(leaveType => leaveType.leaveTypeCode === selectedLeaveType).leaveTypeName;
        const response = await quitUser(navigate, data);
        console.log('response', response);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);
            setOpenDeleteModal(false);
            setOpenFinalCheckModal(false);
            localStorage.setItem('notificationMessage', response.message);
            window.location.reload();
        } else {
            setNotificationMessage(response.message);
            setNotificationType('error');
            setIsNotificationOpen(true);
        }

    };

    // 管理者ユーザー退会用のモーダルテキスト
    const getModalText = () => {
        if (onConfirm) {
            return '管理者ユーザーを退会させます。この操作はデータベース上でユーザーを無効化しますが、削除はしません。';
        }
        return `ユーザーを退会させます。この操作はデータベース上でユーザーを無効化しますが、削除はしません。
        退会区分：${leaveTypeList.find(leaveType => leaveType.leaveTypeCode === selectedLeaveType).leaveTypeName}`;
    };

    return (
        <FinalCheckModalContainer>
            <ModalCard>
                <div className='modalTitle'>
                    <div className='mainTitle'>退会の確認</div>
                    <div className='modalTitleText'>
                        {getModalText()}
                    </div>
                </div>
                <div className='modalButtonContainer'>
                    <button className='modalButtonCancel' onClick={onClose}>キャンセル</button>
                    <button className='modalButtonConfirm' onClick={handleConfirm}>退会を実行</button>
                </div>
            </ModalCard>
        </FinalCheckModalContainer>
    );
};

export default FinalCheckModal;