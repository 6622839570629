import React from 'react';
import { AccessCategoryRegistForm, InputArea, ButtonWrapper } from '../../../themes/Pages/OperationManagement/AccessCategoryRegistTheme';
import { useForm } from 'react-hook-form';
import { insertAccessCategory } from '../../../api/OperationManagement/accessCategory';
import { useNavigate } from 'react-router-dom';

const AccessCategoryRegist = ({ setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, clearErrors, formState: { errors, isValid },/* getValues, watch,*/ trigger/*, setValue */ } = useForm({
        mode: 'all',
        defaultValues: {
            accessTypeCode: '',
            accessTypeName: '',
        }
    })

    const handleFieldChange = (fieldName) => {
        clearErrors(fieldName);
        trigger(fieldName);
    };

    const onSubmit = async (data) => {
        try {
            const response = await insertAccessCategory(navigate, data);
            if (response) {
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                setIsNotificationOpen(true);

                // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                navigate('/error', { state: { statusCode: '', errorMessage: response.message } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: error.message } });
        }
    };

    return (
        <AccessCategoryRegistForm onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        }}>
            <InputArea>
                <div className='CategoryInputArea'>
                    <div className='Category'>
                        <label htmlFor='accessTypeCode'>アクセス区分コード：</label>
                        <input
                            type='text'
                            id='accessTypeCode'
                            className='categoryInput'
                            {...register('accessTypeCode', {
                                required: 'アクセス区分を入力してください',
                                maxLength: {
                                    value: 20,
                                    message: '20文字以内で入力してください'
                                },
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: 'アクセス区分は半角数字で入力してください'
                                },
                                onChange: () => handleFieldChange('accessTypeCode')
                            })} />
                    </div>
                    {errors.accessTypeCode && <span className='errorMessage'>{errors.accessTypeCode.message}</span>}
                </div>
                <div className='CategoryNameInputArea'>
                    <div className='CategoryName'>
                        <label htmlFor='accessTypeName'>アクセス区分名：</label>
                        <input
                            type='text'
                            id='accessTypeName'
                            className='categoryNameInput'
                            {...register('accessTypeName', {
                                required: 'アクセス区分名を入力してください',
                                maxLength: {
                                    value: 20,
                                    message: '20文字以内で入力してください'
                                },
                                pattern: {
                                    value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                    message: 'アクセス区分名に全角文字を使用してください'
                                },
                                onChange: () => handleFieldChange('accessTypeName')
                            })} />
                    </div>
                    {errors.accessTypeName && <span className='errorMessage'>{errors.accessTypeName.message}</span>}
                </div>
            </InputArea>
            <ButtonWrapper>
                <button type='submit' className='registerButton' disabled={!isValid}>登録</button>
            </ButtonWrapper>
        </AccessCategoryRegistForm>
    );
};

export default AccessCategoryRegist;
