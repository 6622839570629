import React, { useState } from 'react';
import { IoMdSettings } from "react-icons/io";
import { Header, HeaderTitle, HeaderProfile, SettingButton, ProfileDropdown } from '../../themes/HeaderTheme/HeaderTheme';
import { FaUserCircle, FaChevronDown } from 'react-icons/fa';
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/Logout/logout';

const HeaderComponent = ({ adminUserInfo }) => {

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        await logout(navigate);
    };

    return (
        <>
            <Header>
                <HeaderTitle>管理画面</HeaderTitle>
                <HeaderProfile onClick={toggleDropdown}>
                    <div className="profile-icon"><FaUserCircle size={30} /></div>
                    <div className="profile-info">
                        <h4 className="profile-name">{adminUserInfo?.lastName} {adminUserInfo?.firstName}</h4>
                        <h4 className="profile-post">{adminUserInfo?.adminMemberTypeName}</h4>
                    </div>
                    <div className="profile-toggle"><FaChevronDown size={20} /></div>
                </HeaderProfile>
                {isOpen && (
                        <ProfileDropdown>
                            <div className="profileDropdownItem" onClick={handleLogout}>
                                <div className="profileDropdownItemTitle">
                                    <FiLogOut size={20} />
                                    ログアウト
                                </div>
                            </div>
                        </ProfileDropdown>
                    )}
                <SettingButton><IoMdSettings size={20} /></SettingButton>
            </Header>
        </>
    );
};

export default HeaderComponent;
