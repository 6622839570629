import styled from '@emotion/styled';

export const InviteCodeListContainer = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .inviteCodeHeader {
        width: calc(100% - 20px);
        display: grid;
        align-items: center;
        grid-template-columns: 1.6fr 0.6fr 1.1fr 1.1fr 0.7fr 0.8fr;
        background-color: ${props => props.theme.background};
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.text};

        .actionContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            
            .actionButtonContainer {
                display: flex;
                align-items: center;
                color: ${props => props.theme.error};
                cursor: pointer;
                border: 1px solid ${props => props.theme.error};
                border-radius: 5px;
                padding: 5px;
                margin: 0 15px 0 0;

                .ButtonText {
                    margin: 0 5px 0 0;
                }

                .ButtonIcon {
                    margin: 0 0 0 05px;
                }

                &:hover {
                    background-color: ${props => props.theme.errorHover};
                    color: ${props => props.theme.buttonText};
                }
            }
        }
    }

    .inviteCodeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 14px;

        .inviteCodeRow {
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 1.6fr 0.6fr 1.1fr 1.1fr 0.7fr 0.8fr;
            border-bottom: 1px solid ${props => props.theme.border};
            padding: 10px;
            align-items: center;

            .status {
                font-weight: 500;
                font-size: 12px;
                border-radius: 50px;
                width: fit-content;
                padding: 5px 10px 5px 10px;
                color: ${props => props.theme.buttonText};
                text-align: center;
            }

            .usedText {
                background-color: ${props => props.theme.error};
            }

            .notUsedText {
                background-color: ${props => props.theme.success};
            }

            .expiredText {
                background-color: ${props => props.theme.background};
            }

            .ButtonContainer {
                display: flex;
                align-items: center;
            }

            .CopyButton {
                margin: 0 5px 0 5px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 5px;
                cursor: pointer;
                width: fit-content;
                display: flex;
                align-items: center;
                background-color: transparent;
                color: ${props => props.theme.primary};

                .ButtonIcon {
                    margin: 0 5px 0 0;
                }

                &:hover {
                    background-color: ${props => props.theme.backgroundHover};
                }
            }

            .DeleteButton {
                margin: 0 10px 0 5px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 5px;
                cursor: pointer;
                width: fit-content;
                display: flex;
                align-items: center;
                background-color: transparent;
                color: ${props => props.theme.error};

                .ButtonIcon {
                    margin: 0 5px 0 0;
                }

                &:hover {
                    background-color: ${props => props.theme.backgroundHover};
                }
            }

            .noUseButton {
                background-color: ${props => props.theme.background} !important;
                cursor: not-allowed;
                color: ${props => props.theme.text};
            }
        }
    }
`;