import styled from '@emotion/styled';

export const QuestionHistorySearchContainer = styled.div`
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
    padding: 20px;
`;

export const QuestionWordContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    
    label{
        width: 6rem;
    }

    input{
        width: calc(100% - 6rem);
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;

        &::focus{
            outline: 1px solid ${props => props.theme.primary};
        }
    }
`;

export const QuestionTimingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 0px 10px 0px;

    label{
        width: 6rem;
    }

    .date-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 6rem);

        .date-separator{
            margin: 0px 5px;
        }

        input{
            width: 20%;
            height: 30px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            &::focus{
                outline: 1px solid ${props => props.theme.primary};
            }
        }
    }
`;

export const QuestionLanguageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 0px;

    label{
        width: 6rem;
    }

    .language{
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
    }
`;

export const KindOfQuestionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 0px;

    label{
        width: 6rem;
    }

    .kinds{
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
    }
`;

export const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 5px 0px;

    label{
        width: 6rem;
    }

    .status{
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
    }
`;

export const SearchButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px 0px 0px 0px;
    justify-content: flex-end;

    button{
        width: 80px;
        height: 35px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.tags};
        cursor: pointer;

        &:hover{
            background-color: ${props => props.theme.primaryHover};
        }
    }
`;
