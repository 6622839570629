import React from "react";
import { PaginationContainer, PageNumbers} from "../../../themes/CommonDesignTheme/PaginationTheme";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
    const getPageNumbers = () => {
        const pages = [];

        // 最初の2ページを追加
        if (totalPages >= 1) pages.push(1);
        if (totalPages >= 2 && !pages.includes(2)) pages.push(2);

        // 現在のページの前後を追加
        if (currentPage > 3 && currentPage !== 4) pages.push("..."); // 省略記号
        for (let i = Math.max(3, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
            pages.push(i);
        }
        if (currentPage < totalPages - 3) pages.push("..."); // 省略記号

        // 最後の2ページを追加
        if (totalPages > 1 && !pages.includes(totalPages - 1)) pages.push(totalPages - 1);
        if (!pages.includes(totalPages)) pages.push(totalPages);
        return pages;
    };

    const pageNumbers = getPageNumbers();

    return (
        <PaginationContainer>
            {currentPage !== 1 && (
                <button className={`prevButton ${currentPage === 1 ? "unClickable" : ""}`} onClick={() => onPageChange(currentPage - 1)}>←  前へ</button>
            )}
            <PageNumbers>
                {pageNumbers.map((pageNumber, index) => (
                    <button
                        key={index}
                        onClick={() => typeof pageNumber === "number" && onPageChange(pageNumber)}
                        className={`paginationButton 
                                    ${currentPage === pageNumber ? "currentPage" : ""}
                                    ${pageNumber === "..." ? "disabledButton" : ""}`}
                        disabled={pageNumber === "..."}
                    >
                        {pageNumber}
                    </button>
                ))}
            </PageNumbers>
            {currentPage !== totalPages && (
                <button className={`nextButton ${currentPage === totalPages ? "unClickable" : ""}`} onClick={() => onPageChange(currentPage + 1)}>次へ →</button>
            )}
        </PaginationContainer>
    );
}
