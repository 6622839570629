import styled from '@emotion/styled';

export const FinalCheckModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
`;

export const ModalCard = styled.div`
    background-color: ${props => props.theme.systemBackground};
    padding: 25px;
    border-radius: 10px;
    max-width: 600px;

    .modalTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .mainTitle {
            width: 100%;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 10px;
        }

        .modalTitleText {
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: ${props => props.theme.text};
        }
    }

    .modalButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;

        .modalButtonCancel {
            margin: 0 10px 0 0;
            background-color: transparent;
            color: ${props => props.theme.text};
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 7px 10px;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.backgroundHover};
            }
        }

        .modalButtonConfirm {
            background-color: ${props => props.theme.error};
            color: ${props => props.theme.tags};
            border: none;
            border-radius: 5px;
            padding: 7px 10px;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.errorHover};
            }
        }
    }
`;