import styled from '@emotion/styled';

export const ActiveUserCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 30px);
    height: calc(100% - 10px);
    border: 1px solid ${props => props.theme.border};
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 0px 20px 10px 10px;

    .Title {
        font-size: 15px;
        color: ${props => props.theme.text};
    }

    .Value {
        font-size: 20px;
        font-weight: bold;
        color: ${props => props.theme.text};
        p{
            margin: 0px;
        }
    }

    .Supplement {
        font-size: 12px;
        color: ${props => props.theme.text};
    }
`;