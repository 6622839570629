import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import NotificationModal from '../../Modals/NotificationModal';
import CommonLayout from '../../WholeDesign/CommonLayout';
import InviteCodeGenerate from './InviteCodeGenerate';
import InviteCodeList from './InviteCodeList';

const InviteCodeManagement = () => {
    const navigate = useNavigate();
    const { memberTypeList, setSelectedMenu, sideMenuPageList } = useOutletContext();
    const [errorMessage, setErrorMessage] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);

    useEffect(() => {
        const storedInviteCodeHistory = localStorage.getItem('inviteCodeHistory');
        if (storedInviteCodeHistory) {
            setNotificationMessage(storedInviteCodeHistory);
            setNotificationType('success');
            setIsNotificationOpen(true);
            localStorage.removeItem('inviteCodeHistory');
        }
    }, []);

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '04')?.id);
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, [setSelectedMenu, sideMenuPageList]);

    const header = (
        <>
            <h2 className="title">招待コード管理</h2>
        </>
    )

    const content = (
        <>
            <InviteCodeGenerate
                memberTypeList={memberTypeList}
                navigate={navigate}
                setErrorMessage={setErrorMessage}
                setNotificationMessage={setNotificationMessage}
                setNotificationType={setNotificationType}
                setIsNotificationOpen={setIsNotificationOpen}
            />
            <InviteCodeList 
                navigate={navigate}
                memberTypeList={memberTypeList}
                setErrorMessage={setErrorMessage}
                setNotificationMessage={setNotificationMessage}
                setNotificationType={setNotificationType}
                setIsNotificationOpen={setIsNotificationOpen}
            />
            {errorMessage && (
                <NotificationModal
                    isOpen={true}
                    message={errorMessage}
                    onClose={() => setErrorMessage('')}
                    type="error"
                />
            )}
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )

    return (
        <CommonLayout
            header={header}
            content={content}
        />
    );
};

export default InviteCodeManagement;
