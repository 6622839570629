import React from 'react';
import { UserIncRateCard } from '../../../themes/Pages/Home/Card_UserIncRateTheme';
import { IoMdTrendingUp, IoMdTrendingDown } from "react-icons/io";

const UserIncRate = ({ setUserIncRateCategory, userIncRateCategory, userIncRate }) => {
    return (
        <UserIncRateCard>
            <div className="Title">
                <p>ユーザー増加率</p>
                <select className="Select" value={userIncRateCategory} onChange={(e) => {
                    setUserIncRateCategory(e.target.value);
                }}>
                    <option value="week">前週比</option>
                    <option value="yearOnYear">前年比</option>
                </select>
            </div>
            <div className="Value">
                <p>
                    {userIncRate === -9999 
                        ? 'データなし' 
                        : (
                            <>
                                {` ${userIncRate}%`}
                                {userIncRate > 0 ? <IoMdTrendingUp /> : <IoMdTrendingDown />} 
                            </>
                        )
                    }
                </p>
            </div>
        </UserIncRateCard>
    );
};

export default UserIncRate;
