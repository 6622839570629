export const insertAccessCategory = async (navigate, data) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_INSERT_ACCESS_CATEGORY}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'アクセス区分の登録に失敗しました' } });
        }
    } catch (error) {
        console.error('insertAccessCategory.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'アクセス区分の登録に失敗しました' } });
    }
}

export const selectIPAddress = async (navigate, accessTypeCode) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_SELECT_IP_ADDRESS}?accessTypeCode=${accessTypeCode}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'こちらIPアドレスの取得に失敗しました' } });
        }
    } catch (error) {
        console.error('selectIPAddress.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'あちらIPアドレスの取得に失敗しました' } });
    }
}

export const insertIPAddress = async (navigate, data) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_INSERT_IP_ADDRESS}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'IPアドレスの登録に失敗しました' } });
        }
    } catch (error) {
        console.error('insertIPAddress.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'IPアドレスの登録に失敗しました' } });
    }
}

export const updateIPAddress = async (navigate, data) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_UPDATE_ACCESS_TYPE}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'IPアドレスの更新に失敗しました' } });
        }
    } catch (error) {
        console.error('updateIPAddress.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'IPアドレスの更新に失敗しました' } });
    }
}   

export const deleteAccessType = async (navigate, accessTypeCode) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_DELETE_ACCESS_TYPE}?accessTypeCode=${accessTypeCode}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'アクセス区分の削除に失敗しました' } });
        }
    } catch (error) {
        console.error('deleteAccessType.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'アクセス区分の削除に失敗しました' } });
    }
}

