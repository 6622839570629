import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Pages/Login/Login';
import AdminPanel from './components/WholeDesign/AdminPanel';
import Dashboard from './components/Pages/Home/Dashboard';
import UserPage from './components/Pages/UserManagement/UserPage';
import LearningProgress from './components/Pages/UsageAnalysis/LearningProgress';
import QuestionHistory from './components/Pages/UsageAnalysis/QuestionHistory';
import InviteCodeManagement from './components/Pages/OperationManagement/InviteCodeManagement';
import ErrorPage from './components/Pages/Error/ErrorPage';
import Statistics from './components/Pages/Statistics/Statistics';
import CreateUser from './components/Pages/OperationManagement/CreateUser';
import CreateAdminUser from './components/Pages/OperationManagement/CreateAdminUser';
import { AppContainer } from './themes/WholeDesignTheme/AppTheme';
import UserList from './components/Pages/UserManagement/UserList';
import AccessCategory from './components/Pages/OperationManagement/AccessCategory';
import AdminUserList from './components/Pages/UserManagement/AdminUserList';
import React from 'react';

// エラーバウンダリを追加
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("エラーバウンダリがキャッチしたエラー:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/error" state={{ statusCode: 500, errorMessage: '予期せぬエラーが発生しました' }} />;
    }
    return this.props.children;
  }
}

function App() {
  // 保護されたルートへのアクセスを制御する関数
  const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return <Navigate to="/error" state={{ statusCode: 401, errorMessage: '認証情報が存在しません' }} />;
    }
    return children;
  };

  return (
    <AppContainer>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adminPanel" element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }>
            <Route index element={<Dashboard />} />
            <Route path="invite-codes" element={<InviteCodeManagement />} />
            <Route path="learning-progress" element={<LearningProgress />} />
            <Route path="ai-questions" element={<QuestionHistory />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="create-user" element={<CreateUser />} />
            <Route path="access-type-management" element={<AccessCategory />} />          
            <Route path="create-admin-user" element={<CreateAdminUser />} />
            <Route path="admin-list" element={<AdminUserList />} />
          </Route>
          <Route path="user-page/:userId" element={
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          } />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/error" state={{ errorCode: 404, errorMessage: 'ページが見つかりませんでした' }} />} />
        </Routes>
      </ErrorBoundary>
    </AppContainer>
  );
};

export default App;
