import React, { useState, useEffect } from 'react';
import { AccessCategoryEditModalContainer, AccessCategoryEditCard } from '../../../themes/Pages/OperationManagement/AccessCategoryEditModalTheme';
import { IoMdClose } from 'react-icons/io';
import { formatDateDateAndTime } from '../../../utils/DateUtils';
import { selectIPAddress, insertIPAddress, updateIPAddress } from '../../../api/OperationManagement/accessCategory';
import { useNavigate } from 'react-router-dom';
import NotificationModal from '../../../components/Modals/NotificationModal';

const AccessCategoryEditModal = ({ isOpen, onClose, selectedAccessType, setSelectedAccessType }) => {
    const navigate = useNavigate();
    const [IPInfo, setIPInfo] = useState([]);
    const [editableIPInfo, setEditableIPInfo] = useState([]);
    const [newIPAddress, setNewIPAddress] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [originalAccessTypeName, setOriginalAccessTypeName] = useState('');

    useEffect(() => {
        if (selectedAccessType) {
            setOriginalAccessTypeName(selectedAccessType.accessTypeName);
        }
    }, [selectedAccessType]);

    const validateIPAddress = (ip) => {
        // アスタリスク一文字のみの場合を許可
        if (ip === '*') {
            return true;
        }
        const ipv4Pattern = /^((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d)$/;
        const ipv6Pattern = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;

        return ipv4Pattern.test(ip) || ipv6Pattern.test(ip);
    };

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const result = await selectIPAddress(navigate, selectedAccessType.accessTypeCode);
                setIPInfo(result);
                setEditableIPInfo(result);
            } catch (error) {
                console.error('selectIPAddress.error:', error);
            }
        }
        fetchIPInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (index, value) => {
        const updatedList = [...editableIPInfo];
        updatedList[index] = { ...updatedList[index], allowedIp: value };
        setEditableIPInfo(updatedList);
    };

    const handleStatusChange = (index, value) => {
        const updatedList = [...editableIPInfo];
        updatedList[index] = { ...updatedList[index], deleteFlg: value };
        setEditableIPInfo(updatedList);
    };

    const handleUpdateIPAddress = async () => {
        // Validate all IP addresses before updating
        const invalidIPs = editableIPInfo.filter(item => !validateIPAddress(item.allowedIp));
        if (invalidIPs.length > 0) {
            setIsNotificationOpen(true);
            setNotificationMessage('無効なIPアドレスが含まれています');
            setNotificationType('error');
            return;
        }
        const isIPInfoEqual = JSON.stringify(IPInfo) === JSON.stringify(editableIPInfo);
        const isAccessTypeNameUnchanged = selectedAccessType.accessTypeName === originalAccessTypeName;
        if (isIPInfoEqual && isAccessTypeNameUnchanged) {
            setIsNotificationOpen(true);
            setNotificationMessage('変更がありません');
            setNotificationType('error');
            return;
        }

        const updatedEntry = {
            ipInfo: editableIPInfo,
            accessTypeCode: selectedAccessType.accessTypeCode,
            accessTypeName: selectedAccessType.accessTypeName
        }

        try {
            const response = await updateIPAddress(navigate, updatedEntry);
            if (response) {
                setIsNotificationOpen(true);
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                navigate('/error', { state: { statusCode: '', errorMessage: response.message } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: error.message } });
        }
    };

    const handleRegisterIPAddress = async () => {
        if (!validateIPAddress(newIPAddress)) {
            setIsNotificationOpen(true);
            setNotificationMessage('無効なIPアドレス形式です');
            setNotificationType('error');
            return;
        }

        const data = {
            accessTypeCode: selectedAccessType.accessTypeCode,
            allowedIp: newIPAddress
        }
        try {
            const response = await insertIPAddress(navigate, data);
            if (response) {
                setNewIPAddress('');
                setIsNotificationOpen(true);
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                navigate('/error', { state: { statusCode: '', errorMessage: response.message } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: error.message } });
        }
    }

    return (
        <>
            {isOpen && (
                <AccessCategoryEditModalContainer>
                    <AccessCategoryEditCard>
                        <div className="closeButton" onClick={onClose}>
                            <IoMdClose size={20} />
                        </div>
                        <div className="CardBox">
                            <h1>アクセス区分</h1>
                            <div className="selectedAccessType">
                                <div className="accessTypeHeader">
                                    <span>コード</span>
                                    <span>区分名</span>
                                    <span>登録日時</span>
                                    <span>登録者</span>
                                    <span>更新日時</span>
                                    <span>更新者</span>
                                </div>
                                <div className="accessTypeContent">
                                    <span>{selectedAccessType?.accessTypeCode}</span>
                                    <input
                                        type="text"
                                        value={selectedAccessType?.accessTypeName}
                                        onChange={(e) => setSelectedAccessType({ ...selectedAccessType, accessTypeName: e.target.value })}
                                    />
                                    <span>{selectedAccessType?.createDate ? formatDateDateAndTime(selectedAccessType?.createDate) : '登録日時データなし'}</span>
                                    <span>{selectedAccessType?.createUser ? selectedAccessType?.createUser : '登録者データなし'}</span>
                                    <span>{selectedAccessType?.updateDate ? formatDateDateAndTime(selectedAccessType?.updateDate) : '更新日時データなし'}</span>
                                    <span>{selectedAccessType?.updateUser ? selectedAccessType?.updateUser : '更新者データなし'}</span>
                                </div>
                            </div>
                            <h1>許可IPアドレス</h1>
                            <div className="IPRegisterZone">
                                <div className="InputArea">
                                    <label htmlFor="IPAddress">IPアドレス</label>
                                    <input type="text" id="IPAddress" value={newIPAddress} onChange={(e) => setNewIPAddress(e.target.value)} />
                                </div>
                                <div className="ButtonArea">
                                    <button onClick={handleRegisterIPAddress}>登録</button>
                                </div>
                            </div>
                            <div className="AllowedIPAddress">
                                <div className="AllowedIPAddressHeader">
                                    <span>No.</span>
                                    <span>IPアドレス</span>
                                    <span>ステータス</span>
                                    <span>登録日時</span>
                                    <span>登録者</span>
                                    <span>更新日時</span>
                                    <span>更新者</span>
                                </div>
                                <div className="AllowedIPAddressContent">
                                    {editableIPInfo?.map((item, index) => (
                                        <div key={index} className="AllowedIPAddressContentItem">
                                            <span>{index + 1}</span>
                                            <input
                                                type="text"
                                                value={item.allowedIp}
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />
                                            <select
                                                value={item.deleteFlg}
                                                onChange={(e) => handleStatusChange(index, e.target.value)}
                                            >
                                                <option value="0">有効</option>
                                                <option value="1">無効</option>
                                            </select>
                                            <span>{item.createDate ? formatDateDateAndTime(item.createDate) : '登録日時データなし'}</span>
                                            <span>{item.createUser ? item.createUser : '登録者データなし'}</span>
                                            <span>{item.updateDate ? formatDateDateAndTime(item.updateDate) : '更新日時データなし'}</span>
                                            <span>{item.updateUser ? item.updateUser : '更新者データなし'}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className='updateButtonArea'>
                                    <button onClick={onClose} className='cancelButton'>キャンセル</button>
                                    <button onClick={handleUpdateIPAddress} className='updateButton'>更新</button>
                                </div>
                            </div>
                        </div>
                    </AccessCategoryEditCard>
                </AccessCategoryEditModalContainer>
            )}
            <NotificationModal
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
                message={notificationMessage}
                type={notificationType}
            />
        </>
    );
};

export default AccessCategoryEditModal;
