import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ThemeProvider } from '@emotion/react';
import { ThemeColor } from './utils/Constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={ThemeColor}>
    <Router basename="/">
        <App />
    </Router>
  </ThemeProvider>
);