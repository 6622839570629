import React, { useState } from 'react';
import QuestionHistorySearch from '../CommonDesign/QuestionHistorySearch';
import QuestionHistoryResult from '../CommonDesign/QuestionHistoryResult';
import { QuestionHistoryContainer } from '../../../themes/Pages/UserManagement/UserPageQuestionHistoryTheme';
import QuestionHistoryChatModal from '../../Modals/QuestionHistoryChatModal';
import Pagination from '../CommonDesign/Pagination';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { SyncLoader } from 'react-spinners';

const UserPageQuestionHistory = ({ userId }) => {

    const [searchResults, setSearchResults] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [page, setPage] = useState(1);
    const [reverseFLG, setReverseFLG] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <QuestionHistoryContainer>
                <QuestionHistorySearch
                    userId={userId}
                    setSearchResults={setSearchResults}
                    page={page}
                    setPage={setPage}
                    reverseFLG={reverseFLG}
                    setReverseFLG={setReverseFLG}
                    setSelectedUser={setSelectedUser}
                    setIsLoading={setIsLoading}
                />
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                        <SyncLoader color="#36d7b7" />
                    </div>
                ) : searchResults && searchResults.totalRooms > 0 && searchResults.chatRoomList && searchResults.chatRoomList.length > 0 ? (
                    <>
                        <QuestionHistoryResult
                            searchResults={searchResults.chatRoomList}
                            userId={userId}
                            selectedRoom={selectedRoom}
                            setSelectedRoom={setSelectedRoom}
                            selectedUser={selectedUser}
                            setSelectedUser={setSelectedUser}
                            reverseFLG={reverseFLG}
                            setReverseFLG={setReverseFLG}
                        />
                        {searchResults.totalRooms > UserDetailDisplayLimitList[0] && searchResults.chatRoomList.length > 0 && (
                            <Pagination
                                currentPage={page}
                                totalPages={Math.ceil(searchResults.totalRooms / UserDetailDisplayLimitList[0])}
                                onPageChange={setPage}
                            />
                        )}
                    </>
                ) : (
                    <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff4d4d', margin: '20px' }}>
                        データがありません
                    </div>
                )}
                {selectedRoom && <QuestionHistoryChatModal userId={userId} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} selectedUser={selectedUser} />}
            </QuestionHistoryContainer>
        </>
    );
};

export default UserPageQuestionHistory;