import React from 'react';
import { AccessCategoryDeleteModalContainer, AccessCategoryDeleteCard } from '../../../themes/Pages/OperationManagement/AccessCategoryDeleteModalTheme';
import { IoMdClose } from 'react-icons/io';
import { deleteAccessType } from '../../../api/OperationManagement/accessCategory';
import { useNavigate } from 'react-router-dom';

const AccessCategoryDeleteModal = ({ setIsDeleteModalOpen, selectedAccessType, setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {
    const navigate = useNavigate();
    const handleDelete = async () => {
        try {
            const result = await deleteAccessType(navigate, selectedAccessType.accessTypeCode);
            if (result) {
                setIsDeleteModalOpen(false);
                setIsNotificationOpen(true);
                setNotificationMessage(result.message);
                setNotificationType(result.success ? 'success' : 'error');

                if (result.success) {
                    localStorage.setItem('notificationMessage', result.message);
                    window.location.reload();
                }
            } else {
                setIsDeleteModalOpen(false);
                setIsNotificationOpen(true);
                setNotificationMessage('アクセス区分の削除に失敗しました');
                setNotificationType('error');
            }
        } catch (error) {
            console.error('deleteAccessType.error:', error);
            setIsDeleteModalOpen(false);
            setIsNotificationOpen(true);
            setNotificationMessage('アクセス区分の削除に失敗しました');
            setNotificationType('error');
        }
    };

    return (
        <AccessCategoryDeleteModalContainer>
            <AccessCategoryDeleteCard>
                <div className="closeButton" onClick={() => setIsDeleteModalOpen(false)}>
                    <IoMdClose size={20} />
                </div>
                <div className="deleteModalHeader">
                    <span>アクセス区分を削除しますか？</span>
                </div>
                <div className="deleteModalContent">
                    <span>以下のアクセス区分を削除します。</span>
                    <span>削除すると、このアクセス区分の会員はサービスの利用が出来なくなります。</span>
                    <span>削除する前に会員のアクセス区分を変更して下さい。</span>
                    <span className="deleteModalContentAccessType">{selectedAccessType.accessTypeCode}：{selectedAccessType.accessTypeName}</span>
                </div>
                <div className="deleteModalButton">
                    <button className="deleteModalButtonCancel" onClick={() => setIsDeleteModalOpen(false)}>キャンセル</button>
                    <button className="deleteModalButtonDelete" onClick={handleDelete}>削除する</button>
                </div>
            </AccessCategoryDeleteCard>
        </AccessCategoryDeleteModalContainer>
    );
};

export default AccessCategoryDeleteModal;
