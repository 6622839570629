import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardData } from '../../../api/Home/dashboard';
import TotalUser from './Card_TotalUser';
import UserIncRate from './Card_UserIncRate';
import ActiveUser from './Card_ActiveUser';
import RecentRegistration from './Card_RecentRegistration';
import News from './Card_News';
import { useOutletContext } from 'react-router-dom';
import CommonLayout from '../../WholeDesign/CommonLayout';
import { ContentRow1, TotalUserCard, UserIncRateCard, ActiveUserCard, ContentRow2, NewsCard, RecentRegistrationCard } from '../../../themes/Pages/Home/DashboardTheme';

const Dashboard = () => {
    const { setSelectedMenu } = useOutletContext();
    const [userIncRateCategory, setUserIncRateCategory] = useState('week');
    const navigate = useNavigate();
    //Stateを用いてデータを管理
    const [dashboardData, setDashboardData] = useState({
        userCount: 0,
        userIncRate: 0,
        activeUser: 0,
        recentUsers: [],
        recentQuestions: []
    });

    const listRef = useRef(null);

    //ダッシュボードデータを取得
    useEffect(() => {
        setSelectedMenu(0);
        const fetchData = async () => {
            try {
                const data = await fetchDashboardData(navigate, userIncRateCategory);
                setDashboardData(data);
            } catch (error) {
                console.error('ダッシュボードデータ取得エラー:', error);
            }
        };
        fetchData();
    }, [navigate, setSelectedMenu, userIncRateCategory]);

    return (
        <CommonLayout
            header={
                <>
                    <h2 className="title">ホーム</h2>
                </>
            }
            content={
                <>
                    {/* 総ユーザー数、総質問数 */}
                    <ContentRow1>
                        <TotalUserCard><TotalUser userCount={dashboardData?.userCount} /></TotalUserCard>
                        <UserIncRateCard><UserIncRate userIncRateCategory={userIncRateCategory} setUserIncRateCategory={setUserIncRateCategory} userIncRate={dashboardData?.userIncRate} /></UserIncRateCard>
                        <ActiveUserCard><ActiveUser activeUser={dashboardData?.activeUser} /></ActiveUserCard>
                    </ContentRow1>

                    {/* お知らせ、最近のユーザー登録*/}
                    <ContentRow2>
                        <NewsCard>
                            <News />
                        </NewsCard>
                        <RecentRegistrationCard>
                            <RecentRegistration recentUsers={dashboardData?.recentUsers} listRef={listRef}/>
                        </RecentRegistrationCard>
                    </ContentRow2>
                </>
            }
        />
    );
};

export default Dashboard;