import styled from '@emotion/styled';

export const AccessCategoryEditModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const AccessCategoryEditCard = styled.div`
    width: 80%;
    height: 80%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.systemBackground};
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border-radius: 10px;
        margin: 10px 0;
    }

    .closeButton {
        position: fixed;
        top: 10%;
        right: 10%;
        cursor: pointer;
        z-index: 1001;
        background-color: white;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        font-size: 25px;
        align-self: flex-start;
        margin-bottom: 10px;
    }

    .selectedAccessType {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        .accessTypeHeader {
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 0.6fr 1.4fr 0.9fr 0.9fr 0.9fr 0.9fr;
            background-color: ${props => props.theme.background};
            padding: 10px;
            font-size: 14px;
            font-weight: bold;
            color: ${props => props.theme.text};
        }

        .accessTypeContent {
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 0.6fr 1.4fr 0.9fr 0.9fr 0.9fr 0.9fr;
            border-bottom: 1px solid ${props => props.theme.border};
            padding: 10px;
            font-size: 14px;

            input {
                width: calc(100% - 20px);
                margin-right: 10px;
                height: 25px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 5px;
            }
        }
    }

    .IPRegisterZone {
        width: calc(100% - 20px);
        background-color: ${props => props.theme.background};
        padding: 10px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;

        .InputArea {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                align-self: flex-start;
                font-size: 14px;
                font-weight: bold;
                color: ${props => props.theme.text};
            }

            input {
                width: 15rem;
                height: 25px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 5px;
            }
        }

        .ButtonArea {
            width: fit-content;
            height: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-end;

            button {
                height: 35px;
                background-color: ${props => props.theme.primary};
                color: ${props => props.theme.buttonText};
                border: none;
                border-radius: 5px;
                padding: 5px 10px;
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    background-color: ${props => props.theme.primaryHover};
                }
            }
        }
    }

    .AllowedIPAddress {
        margin-top: 10px;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;

        .AllowedIPAddressHeader {
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            background-color: ${props => props.theme.background};
            padding: 10px;
            font-size: 14px;
            font-weight: bold;
            color: ${props => props.theme.text};
        }

        .AllowedIPAddressContent {
            width: calc(100% - 20px);
            display: flex;
            flex-direction: column;
            align-items: center;

            .AllowedIPAddressContentItem {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                padding: 10px;
                font-size: 14px;
                border-bottom: 1px solid ${props => props.theme.border};

                input {
                    width: calc(100% - 20px);
                    margin-right: 10px;
                    height: 25px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                }

                select {
                    width: calc(100% - 10px);
                    height: 36px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                }

                .active {
                    color: ${props => props.theme.success};
                }

                .inactive {
                    color: ${props => props.theme.error};
                }
            }
        }

        .updateButtonArea {
            width: 100%;
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .updateButton {
                height: 35px;
                background-color: ${props => props.theme.primary};
                color: ${props => props.theme.buttonText};
                border: none;
                border-radius: 5px;
                padding: 5px 10px;
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    background-color: ${props => props.theme.primaryHover};
                }
            }

            .cancelButton {
                height: 35px;
                background-color: ${props => props.theme.backgroundHover};
                color: ${props => props.theme.text};
                border: none;
                border-radius: 5px;
                padding: 5px 10px;
                cursor: pointer;
                margin-left: 10px;

                &:hover {
                    background-color: ${props => props.theme.background};
                }
            }
        }
    }
`;

