import styled from '@emotion/styled';

export const CreateForm = styled.form`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.background};
    padding: 20px;
    margin: 0 0 20px 0;
    border-radius: 10px;
`;

export const AccountInfo = styled.div`
    width: 50%;
    height: 100%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;

    .title {
        margin: 0 0 20px 0;
        border-bottom: 1px solid ${props => props.theme.border};
    }
`;

export const ProfileInfo = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        margin: 0 0 20px 0;
        border-bottom: 1px solid ${props => props.theme.border};
    }

    .ButtonZone {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 20px;

        button {
            width: 100px;
            height: 35px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0 10px;
            cursor: pointer;
            color: ${props => props.theme.buttonText};
            background-color: ${props => props.theme.primary};
            transition: all 0.3s ease;

            &:hover {
                background-color: ${props => props.theme.primaryHover};
                color: ${props => props.theme.buttonText};
            }

            &:disabled {
                background-color: ${props => props.theme.disabled};
                color: ${props => props.theme.text};
                cursor: not-allowed;
            }
        }
    }
`;

export const InputItem = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .titleZone {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .phoneNumberZone {
            display: flex;
            flex-direction: row;
        
            .phoneNumberLabel {
                font-size: 16px;
            }

            .phoneNumberNote {
                align-self: flex-end;
                margin: 0;
                font-size: 12px;
                color: ${props => props.theme.text};
            }
        }
    }

    .contentZone {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;

        .inputField {
            width: calc(100% - 20px);
            display: flex;
            flex-direction: column;
            margin-right: 20px;

            input {
                width: calc(100% - 20px);
                height: 35px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 0 10px;
            }

            select {
                width: 100%;
                height: 35px;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 0 10px;
            }

            .mailZone {
                width: 100%;
                display: flex;
                flex-direction: row;

                .domain {
                    align-self: flex-end;
                    margin: 0;
                    font-size: 14px;
                    color: ${props => props.theme.text};
                    margin-left: 10px;
                }
            }

            .passwordInputZone {
                width: 100%;
                display: flex;
                flex-direction: row;

                .InputArea {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                
                    .passwordInput {
                        width: calc(100% - 20px);
                        margin-right: 20px;
                    }

                    .ToggleVisibilityButton {
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 35px;
                        height: 35px;
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        color: ${props => props.theme.text};
                    }
                }

                .GeneratePasswordButton {
                    width: 4rem;
                    height: 35px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: ${props => props.theme.buttonText};
                    background-color: ${props => props.theme.primary};
                    transition: all 0.3s ease;
                    font-size: 14px;

                    &:hover {
                        background-color: ${props => props.theme.primaryHover};
                        color: ${props => props.theme.buttonText};
                    }
                }
            }

            .errorMessage {
                color: ${props => props.theme.error};
                font-size: 12px;
                margin-top: 5px;
            }
        }

        .birthZone {
            display: flex;
            flex-direction: row;
            width: 100%;

            .year {
                width: calc(100% - 30px);
                margin-right: 10px;
                display: flex;
                flex-direction: row;

                select {
                    width: calc(100% - 20px);
                    height: 35px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 0 10px;
                }

                span {
                    align-self: flex-end;
                }
            }

            .month {
                width: calc(100% - 30px);
                margin-right: 10px;
                display: flex;
                flex-direction: row;

                select {
                    width: calc(100% - 20px);
                    height: 35px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 0 10px;
                }

                span {
                    align-self: flex-end;
                }
            }

            .day {
                width: calc(100% - 30px);
                margin-right: 10px;
                display: flex;
                flex-direction: row;

                select {
                    width: calc(100% - 20px);
                    height: 35px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 0 10px;
                }

                span {
                    align-self: flex-end;
                }
            }
        }
    }
`;