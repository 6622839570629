import React, { useState } from 'react';
import { formatDateDateAndTime } from '../../../utils/DateUtils';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { AdminUserListResultContainer } from '../../../themes/Pages/UserManagement/AdminUserListResultTheme';
import NotificationModal from '../../Modals/NotificationModal';
import AdminUserListDeleteModal from './AdminUserListDeleteModal';
import { FormProvider, useForm } from 'react-hook-form';
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import AdminUserListBulkEditModal from './AdminUserListBulkEditModal';
import { RiCheckboxIndeterminateLine } from "react-icons/ri";
import AdminUserListEditModal from './AdminUserListEditModal';

const AdminUserListResult = ({ searchResults, adminMemberTypeList }) => {

    const methods = useForm();
    const [notificationMessage, setNotificationMessage] = useState('');

    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationType, setNotificationType] = useState('');

    // 選択したユーザー
    const [selectedUser, setSelectedUser] = useState(null);

    //一括編集用
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    // 編集モーダル
    const [openEditModal, setOpenEditModal] = useState(false);
    // 削除モーダル
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setOpenEditModal(true);
    }

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        setOpenDeleteModal(true);
    }

    const handleSelectUser = (user) => {
        if (user.deleteFlg === '1') return;
        const isSelected = selectedUsers.some(selected => selected.id === user.id);
        if (isSelected) {
            setSelectedUsers(selectedUsers.filter(selected => selected.id !== user.id));
        } else {
            setSelectedUsers([...selectedUsers, { 
                id: user.id, 
                adminUserLastName: user.adminUserLastName,
                adminUserFirstName: user.adminUserFirstName,
                mail: user.mail,
                deleteFlg: user.deleteFlg,
                adminMemberTypeCode: user.adminMemberTypeCode,
                accessTypeCode: user.accessTypeCode
            }]);
        }
    }

    const handleSelectAllUsers = () => {
        const selectableUsers = searchResults?.userInfo?.filter(user => user.deleteFlg !== '1');
        if (selectedUsers.length === selectableUsers.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(selectableUsers.map(user => ({ 
                id: user.id, 
                adminUserLastName: user.adminUserLastName,
                adminUserFirstName: user.adminUserFirstName,
                mail: user.mail,
                deleteFlg: user.deleteFlg,
                adminMemberTypeCode: user.adminMemberTypeCode,
                accessTypeCode: user.accessTypeCode
            })));
        }
    }

    return (
        <>
            <AdminUserListResultContainer>
                <div className='BulkEditButtonContainer'>
                    <button className='BulkEditButton' onClick={() => setOpenBulkEditModal(true)}>一括編集</button>
                </div>
                <div className='SearchResultHeaderItem'>
                    <span className='tableItem Checkbox' onClick={() => handleSelectAllUsers()}>選択</span>
                    <span className='tableItem UserName'>氏名</span>
                    <span className='tableItem UserEmail'>メールアドレス</span>
                    <span className='tableItem UserMemberType'>管理者会員区分</span>
                    <span className='tableItem UserStatus'>ステータス</span>
                    <span className='tableItem UpdateDate'>最終ログイン日時</span>
                    <span className='tableItem Action'>アクション</span>
                </div>
                <div className='SearchResultBody'>
                    {searchResults?.userInfo?.map((result, index) => (
                        <div key={`${result.id}-${index}`} className='SearchResultItem'>
                            <span className='tableItem Checkbox' onClick={() => handleSelectUser(result)}>
                                {result.deleteFlg === '1' ? 
                                    <RiCheckboxIndeterminateLine size={15} cursor='not-allowed' /> : 
                                    (selectedUsers.some(selected => selected.id === result.id) ? 
                                        <GrCheckboxSelected size={15} cursor='pointer' /> : 
                                        <GrCheckbox size={15} cursor='pointer' />
                                    )
                                }
                            </span>
                            <span className='tableItem UserName'>{result.adminUserLastName} {result.adminUserFirstName}</span>
                            <span className='tableItem UserEmail'>{result.mail}</span>
                            <span className='tableItem UserMemberType'>{adminMemberTypeList.find(type => type.adminMemberTypeCode === result.adminMemberTypeCode)?.adminMemberTypeName}</span>
                            <span className='tableItem UserStatus'>{result.deleteFlg === '0' ? '在籍中' : '退会済'}</span>
                            <span className='tableItem loginHistory'>
                                {result.loginHistory ? formatDateDateAndTime(result.loginHistory) : 'ログイン履歴無し'}
                            </span>
                            <span className='tableItem Action'>
                                <button className='EditButton' onClick={() => handleEditUser(result)} disabled={result.deleteFlg === '1'}>
                                    <FaRegEdit className='ButtonIcon' size={15} />
                                    <div className='ButtonText'>編集</div>
                                </button>
                                <button className='DeleteButton' onClick={() => handleDeleteUser(result)} disabled={result.deleteFlg === '1'}>
                                    <FaRegTrashAlt className='ButtonIcon' size={15} />
                                    <div className='ButtonText'>退会</div>
                                </button>
                            </span>
                        </div>
                    ))}
                </div>
            </AdminUserListResultContainer>
            {openEditModal && selectedUser &&
                <FormProvider {...methods}>
                    <AdminUserListEditModal
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        setOpenEditor={setOpenEditModal}
                        setNotificationMessage={setNotificationMessage}
                        setNotificationType={setNotificationType}
                        setIsNotificationOpen={setIsNotificationOpen}
                    />
                </FormProvider>
            }
            {openDeleteModal &&
                <AdminUserListDeleteModal
                    selectedUser={selectedUser}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            }
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
            {openBulkEditModal && 
                <AdminUserListBulkEditModal
                    selectedUsers={selectedUsers}
                    setOpenBulkEditModal={setOpenBulkEditModal}
                    adminMemberTypeList={adminMemberTypeList}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />
            }
        </>
    )
}

export default AdminUserListResult;