import styled from '@emotion/styled';

export const Header = styled.div`
  background-color: ${props => props.theme.systemBackground};
  padding: 10px;
  width: calc(100vw-20px);
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.border};
  position: relative;
`;

export const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const HeaderProfile = styled.div`
  height: 100%;
  opacity: 1;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: 5px;
  color: ${props => props.theme.text};
  position: relative;

  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }

  .profile-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-left: 0px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .profile-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
  }

  .profile-post {
    font-size: 12px;
    margin: 0px;
  }

  .profile-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
  }
`;

export const SettingButton = styled.div`
  width: 20px;
  height: 20px;
  opacity: 1;
  padding: 10px;
  border-radius: 60%;
  cursor: pointer;
  position: relative;
  right: 0px;
  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }
`;

export const ProfileDropdown = styled.div`
  position: absolute;
  top: 105%;
  right: 70px;
  background-color: ${props => props.theme.systemBackground};
  border: 1px solid ${props => props.theme.border};
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 1001;

  .profileDropdownItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: fit-content;

    .profileDropdownItemTitle {
      width: calc(100% - 10px);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;
      padding: 5px 5px;

      &:hover {
        background-color: ${props => props.theme.backgroundHover};
      }
    }
  }
`;


