import styled from '@emotion/styled';

export const AccessCategoryResultContainer = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .accessCategoryResultHeader {
        width: calc(100% - 20px);
        display: grid;
        align-items: center;
        grid-template-columns: 0.5fr 1fr 1.2fr 0.6fr 1.2fr 1fr 1.2fr 1fr 1.1fr;
        background-color: ${props => props.theme.background};
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.text};

        .resultContent{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .accessCategoryResultContent {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 14px;

        .accessCategoryResultRow {
            width: calc(100% - 20px);
            display: grid;
            align-items: center;
            grid-template-columns: 0.5fr 1fr 1.2fr 0.6fr 1.2fr 1fr 1.2fr 1fr 1.1fr;
            padding: 10px;
            border-bottom: 1px solid ${props => props.theme.border};

            .resultContent{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .status {
                font-weight: 500;
                font-size: 12px;
                border-radius: 50px;
                width: fit-content;
                padding: 5px 10px 5px 10px;
                color: ${props => props.theme.buttonText};
                text-align: center;
            }

            .activeText {
                background-color: ${props => props.theme.success};
            }

            .inactiveText {
                background-color: ${props => props.theme.error};
            }

            .actionContainer {
                display: flex;
                align-items: center;

                .EditButton{
                    margin: 0 5px 0 5px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: ${props => props.theme.primary};

                    .ButtonIcon{
                        margin: 0 5px 0 0;
                    }
                    .ButtonText{
                        margin: 0 5px 0 0px;
                    }

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }

                    &:disabled{
                        background-color: ${props => props.theme.disabled};
                        color: ${props => props.theme.textHover};
                        cursor: not-allowed;
                    }
                }

                .DeleteButton{
                    margin: 0 10px 0 5px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: ${props => props.theme.error};

                    .ButtonIcon{
                        margin: 0 5px 0 0;
                    }
                    .ButtonText{
                        margin: 0 5px 0 0px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }

                    &:disabled{
                        background-color: ${props => props.theme.disabled};
                        color: ${props => props.theme.textHover};
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
`;

