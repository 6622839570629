export const fetchPartData = async (navigate) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
    const partDataUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PARTS}`;

    try {
        const response = await fetch(partDataUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            console.error('パートデータの取得に失敗しました');
            navigate('/error', { state: { errorCode: response.status, errorMessage: 'パートデータの取得時にエラーが発生しました' } });
        }
    } catch (error) {
        console.error('APIエラー：パートデータの取得', error);
        navigate('/error', { state: { errorCode: 500, errorMessage: 'パートデータの取得時にエラーが発生しました' } });
    }
};

export const fetchPageCount = async (navigate, startDate, endDate) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
    const pageCountUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PAGE_COUNT}`;

    try {
        const response = await fetch(pageCountUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
            }),
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        }
    } catch (error) {
        console.error('APIエラー：ページカウントの取得', error);
        navigate('/error', { state: { errorCode: 500, errorMessage: 'ページカウントの取得時にエラーが発生しました' } });
    }
};

export const fetchUserProgress = async (navigate, startYear, startMonth, endYear, endMonth, selectedPart, currentPage) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
    const userProgressUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_PROGRESS}`;

    try {
        const response = await fetch(userProgressUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth,
                selectedPart: selectedPart,
                currentPage: currentPage,
            }),
        }); 
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        }
    } catch (error) {
        console.error('APIエラー：ユーザー進捗の取得', error);
        navigate('/error', { state: { errorCode: 500, errorMessage: 'ユーザー進捗の取得時にエラーが発生しました' } });
    }
};
