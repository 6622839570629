//サイドメニューのサマリー取得
export const getSideMenuSummary = async (navigate, adminAuth) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const sideMenuSummaryUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_SIDE_MENU_SUMMARY}?adminAuth=${adminAuth}`;
        const response = await fetch(sideMenuSummaryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Side menu summary response error:', response.status);
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'サイドメニューのサマリー取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：サイドメニューのサマリー取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'サイドメニューのサマリー取得に失敗しましたよ' } });
    }
};

//サイドメニューのページリスト取得
export const getSideMenuPageList = async (navigate, adminAuth) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const sideMenuPageListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_SIDE_MENU_PAGE_LIST}?adminAuth=${adminAuth}`;
        const response = await fetch(sideMenuPageListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Side menu page list response error:', response.status);
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'サイドメニューのページリスト取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：サイドメニューのページリスト取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'サイドメニューのページリスト取得に失敗しましたよ' } });
    }
};

