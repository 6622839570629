export const fetchPartList = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const partListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_PART_LIST}`;

        const response = await fetch(partListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'partリストの取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：partリストの取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'partリストの取得に失敗しました' } });
    }
};