import styled from '@emotion/styled';

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 100%;
    margin-bottom: 30px;
    position: relative;

    .prevButton,
    .nextButton {
        background-color: transparent;
        cursor: pointer;
        border: none;
        color: ${props => props.theme.text};
        position: absolute;
    }

    .prevButton {
        left: 0;
    }

    .nextButton {
        right: 0;
    }

    .prevButton:hover,
    .nextButton:hover {
        color: ${props => props.theme.textHover};
    }

    .unClickable {
        pointer-events: none;
        cursor: help;
    }
`;

export const PageNumbers = styled.div`
    display: flex;
    gap: 5px;
    justify-content: center;
    flex-grow: 1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .paginationButton {
        cursor: pointer;
        border-radius: 50%;
        border: none;
        width: 30px;
        height: 30px;
        background-color: transparent;
        color: ${props => props.theme.text};

        &:hover {
            color: ${props => props.theme.textHover};
        }
    }

    .currentPage {
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.tags};
    }

    .disabledButton {
        pointer-events: none;
    }
`;
