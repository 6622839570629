import React, { useCallback, useState, useEffect } from 'react';
import { fetchChatHistoryData } from '../../api/UserManagement/userQuestionHistory';
import { useNavigate } from 'react-router-dom';
import { QuestionHistoryChatModalContainer
        , ChatHistoryHeader
        , ChatHistoryContents } from '../../themes/CommonDesignTheme/QuestionHistoryChatModalTheme';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import ChatResponseMarkdown from '../Markdown/ChatResponseMarkdown';

const QuestionHistoryChatModal = ({ userId, selectedUser, selectedRoom, setSelectedRoom }) => {

    const navigate = useNavigate();
    const [chatHistoryData, setChatHistoryData] = useState([]);
    const [userName, setUserName] = useState('');

    const fetchChatHistory = useCallback(async (conversationId) => {
        try {
            let chatHistoryData;
            if(userId){
                chatHistoryData = await fetchChatHistoryData(navigate, userId, conversationId);
            }else{
                chatHistoryData = await fetchChatHistoryData(navigate, selectedUser, conversationId);
            }
            if (chatHistoryData.length > 0) {
                setChatHistoryData(chatHistoryData);
                setUserName(chatHistoryData[0].userLastName + chatHistoryData[0].userFirstName);
            } else {
                setChatHistoryData([]);
            }
        } catch (error) {
            console.error('APIエラー：質問履歴の取得', error);
        }
    }, [navigate, userId, selectedUser]);

    useEffect(() => {
        fetchChatHistory(selectedRoom);
    }, [selectedRoom, fetchChatHistory]);

    const closeChat = () => {
        setSelectedRoom(null);
    };

    return (
        <QuestionHistoryChatModalContainer onClick={closeChat}>
            <div className="modalContent" onClick={(event) => event.stopPropagation()}>
                <ChatHistoryHeader>
                    <div className="chatHistoryTitle">
                        <IoChatbubblesOutline size={18} />
                        <span>{userName}の会話履歴</span>
                    </div>
                    <RxCross2 size={18} className="closeButton" onClick={closeChat} />
                </ChatHistoryHeader>
                <ChatHistoryContents>
                    {chatHistoryData.map((message, index) => (
                        <div key={index} className="messageContainer">
                            <div className={`message user`}>
                                {message.userMessage}
                            </div>
                            <div className={`message bot`}>
                                <ChatResponseMarkdown>{message.botMessage}</ChatResponseMarkdown>
                            </div>
                        </div>
                    ))}
                </ChatHistoryContents>
            </div>
        </QuestionHistoryChatModalContainer>
    );
};

export default QuestionHistoryChatModal;