import React, { useEffect, useState } from "react";
import { useOutletContext } from 'react-router-dom';
import { ja } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import CommonLayout from '../../WholeDesign/CommonLayout';
import { FormProvider, useForm } from 'react-hook-form';
import CreateAdminUserForm from './CreateAdminUserForm';
import NotificationModal from '../../Modals/NotificationModal';
registerLocale('ja', ja);

function CreateAdminUser() {
    const methods = useForm();
    const { setSelectedMenu, sideMenuPageList } = useOutletContext();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationType, setNotificationType] = useState('');

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '07')?.id);
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, [setSelectedMenu, sideMenuPageList]);    

    const header = (
        <>
            <h2 className="title">管理者アカウント作成</h2>
        </>
    )

    const content = (
        <>
            <FormProvider {...methods}>
                <CreateAdminUserForm 
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />
            </FormProvider>
        </>
    )

    return (
        <>
            <CommonLayout header={header} content={content} />
            <NotificationModal
                message={notificationMessage}
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    );
}

export default CreateAdminUser;
