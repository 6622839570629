import React, { useState, useEffect } from 'react';
import CommonLayout from '../../WholeDesign/CommonLayout';
import AccessCategoryRegist from './AccessCategoryRegist';
import AccessCategoryResult from './AccessCategoryResult';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import NotificationModal from '../../Modals/NotificationModal';
import { useOutletContext } from 'react-router-dom';

const AccessCategory = () => {
    const methods = useForm();
    const { setSelectedMenu, sideMenuPageList } = useOutletContext();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationType, setNotificationType] = useState('');
    const { accessTypeList } = useOutletContext();
    
    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '06')?.id);
        const notificationMessage = localStorage.getItem('notificationMessage');
        if (notificationMessage) {
            setNotificationMessage(notificationMessage);
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage');
        }
    }, [setSelectedMenu, sideMenuPageList]);

    const header = (
        <>
            <h2 className="title">アクセス区分管理</h2>
        </>
    )

    const content = (
        <>
            <FormProvider {...methods}>
                <AccessCategoryRegist
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />
            </FormProvider>
            <AccessCategoryResult
                accessTypeList={accessTypeList}
            />
        </>
    )

    return (
        <>
            <CommonLayout
                header={header}
                content={content}
            />
            <NotificationModal
                message={notificationMessage}
                isOpen={isNotificationOpen}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    );
};

export default AccessCategory;
