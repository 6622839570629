import styled from '@emotion/styled';

export const QuestionHistoryContainer = styled.div`
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
