import { useState, Suspense, lazy, useEffect, useCallback } from 'react';
import { FaUserFriends, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { LuArrowRightToLine, LuArrowLeftToLine } from "react-icons/lu";
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

import {
    SideMenuContainer,
    UncleAunt,
    Mother,
    Children,
    Child,
    ToggleButton,
} from '../../themes/SideMenuTheme/SideMenuTheme';

/**
 * サポートされているアイコンライブラリ:
 * - fa: Font Awesome (react-icons/fa)
 * - bs: Bootstrap Icons (react-icons/bs)
 * - md: Material Design Icons (react-icons/md)
 * - ri: Remix Icons (react-icons/ri)
 * - im: IcoMoon Icons (react-icons/im)
 * 
 * 使用例:
 * menuIcon: "FaHome", menuIconLibrary: "fa"
 * subMenuIcon: "BsGear", subMenuIconLibrary: "bs"
 */

// Error boundary component for handling lazy loading errors
class IconErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Icon loading error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || <FaUserFriends size={20} margin={10} />;
        }

        return this.props.children;
    }
}

const loadIcon = (iconLibrary, iconName) => {
    if (!iconLibrary || !iconName) return null;
    
    try {
        const IconComponent = lazy(() => {
            let importPath;
            switch(iconLibrary.toLowerCase()) {
                case 'fa':
                    importPath = import('react-icons/fa').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
                    break;
                case 'bs':
                    importPath = import('react-icons/bs').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.BsPerson || FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
                    break;
                case 'md':
                    importPath = import('react-icons/md').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.MdPerson || FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
                    break;
                case 'ri':
                    importPath = import('react-icons/ri').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.RiUserLine || FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
                    break;
                case 'im':
                    importPath = import('react-icons/im').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.ImUser || FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
                    break;
                default:
                    // Default to fa icons
                    importPath = import('react-icons/fa').then(module => {
                        if (!module[iconName]) {
                            console.warn(`${iconName}アイコンが見つかりません`);
                            return { default: module.FaUserFriends };
                        }
                        return { default: module[iconName] };
                    });
            }
            return importPath.catch(err => {
                console.error(`${iconLibrary}アイコンライブラリの読み込みに失敗しました:`, err);
                return import('react-icons/fa').then(module => ({ 
                    default: module.FaUserFriends 
                }));
            });
        });
        
        return (
            <IconErrorBoundary fallback={<FaUserFriends size={20} margin={10} />}>
                <Suspense fallback={<div className="icon-loading" style={{ width: '20px', height: '20px', display: 'inline-block' }}></div>}>
                    <IconComponent size={20} margin={10} />
                </Suspense>
            </IconErrorBoundary>
        );
    } catch (error) {
        console.error("Error loading icon:", error);
        return <FaUserFriends size={20} margin={10} />;
    }
};

const SideMenu = ({ selectedMenu, setSelectedMenu, toggleButton, setToggleButton, sideMenuSummary, sideMenuPageList }) => {
    const [summarizedOpen, setSummarizedOpen] = useState([]);
    const location = useLocation();
    
    /**
     * 現在のURLパスに基づいて選択メニューを更新
     * パスに一致するメニュー項目を探し、選択状態を設定
     * 親メニューがある場合は自動的に開く
     */
    useEffect(() => {
        const currentPath = location.pathname;
        
        // 現在のパスに基づいて一致する項目を探す
        if (sideMenuPageList) {
            const matchingMenuItem = sideMenuPageList.find(item => item.subMenuPath === currentPath);
            if (matchingMenuItem) {
                // 選択状態を更新
                setSelectedMenu(matchingMenuItem.id);
                
                // menuCodeがある場合のみ親メニューを開く
                if (matchingMenuItem.menuCode) {
                    setSummarizedOpen(prev => 
                        prev.includes(matchingMenuItem.menuCode) 
                            ? prev 
                            : [...prev, matchingMenuItem.menuCode]
                    );
                }
            }
        }
    }, [location.pathname, sideMenuPageList, setSelectedMenu, setSummarizedOpen]);

    /**
     * レスポンシブ対応: モバイル表示の場合、メニュー選択時にサイドメニューを閉じる
     */
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && selectedMenu !== null) {
                setToggleButton(false);
            }
        };

        // 初回実行
        handleResize();

        // リサイズイベントリスナー追加
        window.addEventListener('resize', handleResize);
        
        // クリーンアップ
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedMenu, setToggleButton]);

    /**
     * メニュークリック処理
     * 選択状態を更新し、親メニューがある場合は開く
     * モバイル表示の場合はサイドメニューを閉じる
     */
    const handleClick = useCallback((index, menuCode) => {
        // メニュー選択を更新
        setSelectedMenu(index);
        
        // 親メニューがある場合は開く
        if (menuCode) {
            setSummarizedOpen(prev => 
                prev.includes(menuCode) ? prev : [...prev, menuCode]
            );
        }
        
        // モバイル表示の場合、メニュー選択時にサイドメニューを閉じる
        if (window.innerWidth <= 768) {
            setToggleButton(false);
        }
    }, [setSelectedMenu, setSummarizedOpen, setToggleButton]);

    /**
     * 親メニューの開閉切り替え処理
     */
    const handleSummarizeSwitchClick = useCallback((index) => {
        // 親メニューの開閉状態を切り替え
        setSummarizedOpen((prev) =>
            prev.includes(index)
                ? prev.filter((item) => item !== index) // クリックされたものが開いていれば閉じる
                : [...prev, index] // それ以外なら開く
        );
    }, [setSummarizedOpen]);

    // アイコンをアイテムのプロップに基づいてレンダリングする関数
    const renderIcon = (item, defaultIcon = <FaUserFriends size={20} margin={10} />) => {
        const icon = item?.menuIcon || item?.subMenuIcon;
        const iconLibrary = item?.menuIconLibrary || item?.subMenuIconLibrary || 'fa';

        // 直接Reactコンポーネントが提供されている場合
        if (React.isValidElement(icon)) {
            return icon;
        }
        
        // ライブラリとアイコン名がある場合
        if (icon && typeof icon === 'string') {
            // ライブラリがサポートされている型であることを検証
            const validLibrary = ['fa', 'bs', 'md', 'ri', 'im'].includes(iconLibrary.toLowerCase()) 
                ? iconLibrary.toLowerCase() 
                : 'fa';
                
            return loadIcon(validLibrary, icon);
        }
        
        // デフォルトのアイコンにフォールバック
        return defaultIcon;
    };

    return (
        <SideMenuContainer toggleButton={toggleButton}>
            {/* menuCodeがnullの項目をすべてChildコンポーネントとして直接配置（Motherコンポーネントと兄弟要素として） */}
            {sideMenuPageList
                ?.filter(item => item.menuCode === null)
                .map(item => (
                    <UncleAunt
                        key={item.id}
                        isSelected={selectedMenu === item.id}
                    >
                        <Link 
                            to={item?.subMenuPath || '#'} 
                            onClick={() => handleClick(item.id)}
                        >
                            <div className="LinkIcon">
                                {renderIcon(item)}
                            </div>
                            <p className="LinkText">{item?.subMenuName || 'No Name'}</p>
                        </Link>
                    </UncleAunt>
                ))
            }

            {/* menuCodeがある項目はsideMenuSummaryと紐づけて表示 */}
            {sideMenuSummary?.map((summaryItem) => {
                // メニューコードに基づいてページをフィルタリング
                const pages = sideMenuPageList?.filter(page => page.menuCode === summaryItem.menuCode) || [];
                
                // ページが存在しない場合はサマリー項目を表示しない
                if (pages.length === 0) return null;

                return (
                    <React.Fragment key={summaryItem.menuCode}>
                        <Mother
                            onClick={() => handleSummarizeSwitchClick(summaryItem.menuCode)}
                            toggleButton={toggleButton}
                        >
                            <div className="mother-icon">
                                {renderIcon(summaryItem)}
                            </div>
                            <p className="mother-text">{summaryItem?.menuName || 'No Name'}</p>
                            <div className="UpDownIcon">
                                {summarizedOpen.includes(summaryItem.menuCode) ? (
                                    <FaChevronUp size={20} margin={10} />
                                ) : (
                                    <FaChevronDown size={20} margin={10} />
                                )}
                            </div>
                        </Mother>
                        <Children open={summarizedOpen.includes(summaryItem.menuCode)} toggleButton={toggleButton}>
                            {pages.map((item) => (
                                <Child
                                    key={item.id}
                                    isSelected={selectedMenu === item.id}
                                    toggleButton={toggleButton}
                                >
                                    <Link 
                                        to={item?.subMenuPath || '#'} 
                                        onClick={() => handleClick(item.id, item.menuCode)}
                                    >
                                        <div className="LinkIcon">
                                            {renderIcon(item)}
                                        </div>
                                        <p className="LinkText">{item?.subMenuName || 'No Name'}</p>
                                    </Link>
                                </Child>
                            ))}
                        </Children>
                    </React.Fragment>
                );
            })}

            {/* トグルボタン */}
            <ToggleButton onClick={() => setToggleButton(!toggleButton)}>
                <div className="toggle-icon">
                    {toggleButton ? (
                        <LuArrowRightToLine size={20} margin={10} />
                    ) : (
                        <LuArrowLeftToLine size={20} margin={10} />
                    )}
                </div>
            </ToggleButton>
        </SideMenuContainer>
    );
};

export default SideMenu;
