import React, { useEffect, useState } from 'react';
import { userListSearchUser } from '../../../api/UserManagement/userList';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { UserListSearchContainer } from '../../../themes/Pages/UserManagement/UserListSearchTheme';

const UserListSearch = ({
    setSearchResults,
    memberTypeList,
    page,
    setPage,
    setIsLoading,
    userDetailDisplayLimitList,
    displayLength,
    setDisplayLength,
    lastLoginReverse
}) => {
    // ユーザー区分
    const { userCategoryList, accessTypeList } = useOutletContext();
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = useState('');
    const [selectedMemberType, setSelectedMemberType] = useState('00');
    const [selectedCategory, setSelectedCategory] = useState('00');
    const [selectedAccessType, setSelectedAccessType] = useState('00');
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [searchDateStart, setSearchDateStart] = useState('');
    const [searchDateEnd, setSearchDateEnd] = useState('');

    const handleSearch = async (currentPage) => {
        setPage(currentPage);
        setIsLoading(true);

        try {
            const results = await userListSearchUser(
                navigate,
                searchInput,
                selectedCategory,
                selectedAccessType,
                selectedMemberType,
                selectedStatus,
                searchDateStart,
                searchDateEnd,
                currentPage,
                displayLength,
                lastLoginReverse
            );
            if (results) {
                setSearchResults(results);
            }
        } catch (error) {
            console.error('APIエラー：ユーザー検索', error);
            navigate('/error', { state: { statusCode: '', errorMessage: '検索結果の取得に失敗しました' } });
        } finally {
            setIsLoading(false);
        }
    };

    // ページが変更された時に検索を実行
    useEffect(() => {
        handleSearch(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        handleSearch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastLoginReverse]);

    return (
        <UserListSearchContainer>
            <div className='InputArea'>
                <label>氏名またはメールアドレス：</label>
                <input type='text' placeholder='氏名またはメールアドレス' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
            </div>
            <div className='SelectArea'>
                <label>利用者区分：</label>
                <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                    <option value='00'>全て</option>
                    {userCategoryList?.map((category) => (
                        <option key={category.categoryId} value={category.userCategoryCode}>{category.userCategoryName}</option>
                    ))}
                </select>
            </div>
            <div className='SelectArea'>
                <label>会員区分：</label>
                <select value={selectedMemberType} onChange={(e) => setSelectedMemberType(e.target.value)}>
                    <option value='00'>全て</option>
                    {memberTypeList?.map((memberType) => (
                        <option key={memberType.memberTypeCode} value={memberType.memberTypeCode}>{memberType.memberTypeName}</option>
                    ))}
                </select>
            </div>
            <div className='SelectArea'>
                <label>ステータス：</label>
                <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                    <option value='all'>全て</option>
                    <option value='0'>入会中・未入会</option>
                    <option value='1'>退会済</option>
                </select>
            </div>
            <div className='SelectArea'>
                <label>アクセス区分：</label>
                <select value={selectedAccessType} onChange={(e) => setSelectedAccessType(e.target.value)}>
                    <option value='00'>全て</option>
                    {accessTypeList?.map((accessType) => (
                        accessType.deleteFlg === '0' && (
                            <option key={accessType.accessTypeCode} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                        )
                    ))}
                </select>
            </div>
            <div className='SelectArea'>
                <label>登録日：</label>
                <div className="date-container">
                    <input type="date" value={searchDateStart} onChange={(e) => setSearchDateStart(e.target.value)} />
                    <div className="date-separator">～</div>
                    <input type="date" value={searchDateEnd} onChange={(e) => setSearchDateEnd(e.target.value)} />
                </div>
            </div>
            <div className='SelectArea'>
                <label>表示件数：</label>
                <select className='displayLength' value={displayLength} onChange={(e) => setDisplayLength(e.target.value)}>
                    {userDetailDisplayLimitList.map((limit) => (
                        <option key={limit} value={limit}>{limit}</option>
                    ))}
                </select>
                <span className='displayLengthText'>件ずつ</span>
            </div>
            <div className='ButtonArea'>
                <button onClick={() => handleSearch(1)}>検索</button>
            </div>
        </UserListSearchContainer>
    );
};

export default UserListSearch;