import styled from '@emotion/styled';

export const AppContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
`;