import styled from '@emotion/styled';

export const ErrorPageBox = styled.div`
    width: 100vw;
    height: 100vh;
`;

export const ErrorContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    background: linear-gradient(to bottom, #e7f9ff, #ffffff);

    .errorMessageContainer {
        position: relative;
        margin: auto;
        padding: 30px;
        width: 500px;
        box-sizing: border-box;
        background-color: ${props => props.theme.systemBackground};
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
            margin-top: 20px;
            text-align: center;
        }

        h2 {
            margin: 10px 0;
            text-align: center;
        }

        p {
            text-align: center;
            margin-bottom: 20px;
            font-size: 14px;
        }

        button {
            padding: 8px 16px;  
            background-color: ${props => props.theme.primary};
            color: ${props => props.theme.tags};
            font-size: 16px;
            border: none;
            border-radius: 10px;
            transition: background-color 0.3s;      
            cursor: pointer;
        }

        button:hover {
            background-color: ${props => props.theme.primaryHover};
        }
        
    }
`;


