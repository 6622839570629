import { UserPageProgressDetailContainer } from '../../../themes/Pages/UserManagement/UserPageProgressDetailTheme';
import { useState, useCallback, useEffect } from 'react';
import { fetchPartDetailsData } from '../../../api/UserManagement/userProgress';
import { useNavigate } from 'react-router-dom';
import { IoChevronDownSharp, IoChevronForward, IoCheckmarkCircle } from 'react-icons/io5';
import {
    ChapterBox, ChapterIcon, ChapterTitleBox,
    SectionBox, SectionTitleBox, SectionArticleBox,
    ArticleBox, ArticleTitle, ExerciseArea, ArticleArea
} from '../../../themes/Pages/UserManagement/UserPageProgressDetailTheme';

const UserPageProgressDetail = ({ userId, partId, partTitle, progressRate }) => {
    const navigate = useNavigate();
    const [openChapters, setOpenChapters] = useState({});
    const [chapters, setChapters] = useState([]);
    const [partStatus, setPartStatus] = useState(null);

    const fetchPartDetails = useCallback(async () => {
        if (userId && partId) {
            const partDetailsData = await fetchPartDetailsData(navigate, userId, partId);
            if (
                partDetailsData.data &&
                partDetailsData.data.length > 0 &&
                partDetailsData.data[0].chapters
            ) {
                const firstData = partDetailsData.data[0];
                setChapters(firstData.chapters);   // chapters配列
                setPartStatus(firstData.partStatus);  // partStatus(必要なら)
            }
        }
    }, [userId, partId, navigate]);

    useEffect(() => {
        fetchPartDetails();
    }, [fetchPartDetails]);


    const toggleChapter = (chapterId) => {
        setOpenChapters(prev => ({ ...prev, [chapterId]: !prev[chapterId] }));
    };

    const Section = ({ section }) => {
        const [selectedArticle, setSelectedArticle] = useState(null);

        return (
            <SectionBox>
                <SectionTitleBox>
                    {section.sectionTitle} { }
                </SectionTitleBox>
                <SectionArticleBox>
                    {section.articles.map(article => (
                        <Article
                            key={article.articleId}
                            article={article}
                            onSelect={() => setSelectedArticle(selectedArticle?.articleId === article.articleId ? null : article)}
                            isSelected={selectedArticle?.articleId === article.articleId}
                        />
                    ))}
                </SectionArticleBox>
                {selectedArticle && selectedArticle.exercises.length > 0 && (
                    <ExerciseArea>
                        {/* <div className="selectedArticleTitle">{selectedArticle.articleTitle}</div> */}
                        {selectedArticle.exercises.map((exercise, index) => (
                            <div key={index} className="exercise" status={exercise.exerciseStatus}>
                                <div className="exerciseBox" style={{ backgroundColor: exercise.exerciseStatus === '完了' ? '#47845e' : '#ccc' }} />
                                {exercise.exerciseTitle}
                            </div>
                        ))}
                    </ExerciseArea>
                )}
            </SectionBox>
        )
    }

    const Article = ({ article, onSelect, isSelected }) => {
        const [hoverStatus, setHoverStatus] = useState(false);

        return (
            <ArticleArea>
                <ArticleBox
                    articleStatus={article.articleStatus}
                    onMouseOver={() => setHoverStatus(true)}
                    onMouseLeave={() => setHoverStatus(false)}
                    onClick={article.exercises.length > 0 ? () => onSelect() : undefined}
                    isSelected={isSelected}
                >
                    {hoverStatus && <ArticleTitle>{article.articleTitle}</ArticleTitle>}
                </ArticleBox>
            </ArticleArea>
        )
    }

    const Chapter = ({ chapter, isOpen, onToggle, index }) => {
        return (
            <ChapterBox isOpen={isOpen}>
                <ChapterIcon onClick={onToggle} >
                    <ChapterTitleBox>
                        チャプター{index + 1}: {chapter.chapterTitle}
                    </ChapterTitleBox>
                    {isOpen ? <IoChevronDownSharp /> : <IoChevronForward />}
                </ChapterIcon>
                {isOpen && chapter.sections.map(section => (
                    <Section
                        key={section.sectionId}
                        section={section}
                    />
                ))}
            </ChapterBox>
        )
    }

    return (
        <>
            <UserPageProgressDetailContainer key={partId}>
                <label className="partTitle">
                    <div className="partStatus">
                        {partStatus === '完了' ? <IoCheckmarkCircle size={20} style={{ color: '#47845e', margin: 'auto 5px' }} /> : ''}
                        {partTitle}
                    </div>
                    <div className="progressRate">{Math.floor(progressRate)}% 完了</div>
                </label>
                <div className="progressBar">
                    <div className="completeBar" style={{ width: `${Math.floor(progressRate)}%` }}></div>
                </div>
                {chapters.map((chapter, index) => (
                    <Chapter
                        index={index}
                        key={chapter.chapterId}
                        chapter={chapter}
                        isOpen={openChapters[chapter.chapterId]}
                        onToggle={() => toggleChapter(chapter.chapterId)}
                    />
                ))}
            </UserPageProgressDetailContainer>
        </>
    );
};

export default UserPageProgressDetail;