import React, { useState } from 'react';
import { BulkEditModalContainer } from '../../../themes/Pages/UserManagement/UserListBulkEditModalTheme';
import { GrClose } from 'react-icons/gr';
import { updateUsers } from '../../../api/UserManagement/userList';
import { useNavigate, useOutletContext } from 'react-router-dom';

const UserListBulkEditModal = ({ selectedUsers, setOpenBulkEditModal, memberTypeList, setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {

    const navigate = useNavigate();
    const { accessTypeList } = useOutletContext();
    const [selectedMemberType, setSelectedMemberType] = useState('');
    const [selectedAccessType, setSelectedAccessType] = useState('');

    const handleApply = async () => {

        const userDataArray = selectedUsers.map(user => ({
            ...user,
            memberTypeCode: selectedMemberType,
            accessTypeCode: selectedAccessType
        }));

        try {
            const response = await updateUsers(navigate, userDataArray);
            if (response) {
                // setOpenBulkEditModal(false);
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                setIsNotificationOpen(true);

                //成功した場合はローカルストレージに通知メッセージを保存し、リロード
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの更新に失敗しました' } });
        }
    }


    return (
        <BulkEditModalContainer>
            <div className='BulkEditModalContent'>
                <button className='CloseButton' onClick={() => setOpenBulkEditModal(false)}>
                    <GrClose />
                </button>
                {selectedUsers.length === 0 ? (
                    <div className='BulkEditModalEmpty'>
                        <div className='BulkEditModalEmptyTitle'>選択されていません</div>
                        <div className='BulkEditModalEmptyDescription'>一括編集するユーザーを選択してください。</div>
                    </div>
                ) : (
                    <>
                        <div className='BulkEditModalHeader'>
                            <div className='BulkEditModalHeaderTitle'>一括編集</div>
                            <div className='BulkEditModalHeaderDescription'>下記のユーザーを一括編集します。</div>
                        </div>
                        <div className='BulkEditModalBody'>
                            <div className='BodyHeader'>
                                <div className='userNameHeader userName'>氏名</div>
                                <div className='userEmailHeader userEmail'>メールアドレス</div>
                                <div className='memberTypeHeader memberType'>会員区分</div>
                                <div className='accessTypeHeader accessType'>アクセス区分</div>
                            </div>
                            <div className='BodyContent'>
                                {selectedUsers.map(user => (
                                    <div className='user' key={user.id}>
                                        <div className='userName'>{user.name}</div>
                                        <div className='userEmail'>{user.email}</div>
                                        <div className='memberType'>{memberTypeList.find(type => type.memberTypeCode === user.memberTypeCode)?.memberTypeName}</div>
                                        <div className='accessType'>{accessTypeList.find(type => type.accessTypeCode === user.accessTypeCode)?.accessTypeName}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='BulkEditModalButton'>
                            <select className='selectMemberType' onChange={(e) => setSelectedMemberType(e.target.value)}>
                                <option value=''>会員区分を選択してください</option>
                                {memberTypeList.map(memberType => (
                                    <option key={memberType.memberTypeCode} value={memberType.memberTypeCode}>{memberType.memberTypeName}</option>
                                ))}
                            </select>
                            <select className='selectAccessType' onChange={(e) => setSelectedAccessType(e.target.value)}>
                                <option value=''>アクセス区分を選択してください</option>
                                {accessTypeList.map(accessType => (
                                    <option key={accessType.accessTypeCode} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                                ))}
                            </select>
                            <div className='ActionContainer'>
                                <button className='BulkEditModalButtonCancel' onClick={() => setOpenBulkEditModal(false)}>キャンセル</button>
                                <button className='BulkEditModalButtonApply' disabled={selectedMemberType === '' && selectedAccessType === ''} onClick={handleApply}>適用</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </BulkEditModalContainer>
    );
};

export default UserListBulkEditModal;
