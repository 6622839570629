import styled from '@emotion/styled';

export const NewsCard = styled.div`
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.border};
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px 20px 10px 0px;

    .Title {
        font-size: 15px;
        color: ${props => props.theme.text};
    }

    .Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .News {
        font-size: 15px;
        color: ${props => props.theme.text};
        border-bottom: 1px solid ${props => props.theme.border};
    }
`;