import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import CommonLayout from '../../WholeDesign/CommonLayout';
import Pagination from '../CommonDesign/Pagination';
import NotificationModal from '../../Modals/NotificationModal';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { SyncLoader } from 'react-spinners';
import AdminUserListSearch from './AdminUserListSearch';
import AdminUserListResult from './AdminUserListResult';

const AdminUserList = () => {
    const navigate = useNavigate();
    const { adminMemberTypeList, setSelectedMenu, adminUserInfo, sideMenuPageList } = useOutletContext();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (adminUserInfo && adminUserInfo?.adminMemberTypeCode !== '02') {
            navigate('/error', { state: { statusCode: 403, errorMessage: 'アクセス権限がありません' } });
        }
    }, [adminUserInfo, navigate]);

    useEffect(() => {
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setNotificationType('success');
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後は削除
        }
    }, []);

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '08')?.id);
    }, [setSelectedMenu, sideMenuPageList]);

    // メンバータイプ
    const [selectedAdminMemberType, setSelectedAdminMemberType] = useState('00');
    // ページ
    const [page, setPage] = useState(1);
    // 検索入力
    const [searchInput, setSearchInput] = useState('');
    // 検索結果
    const [searchResults, setSearchResults] = useState([]);

    const header = (
        <>
            <h2 className="title">管理者一覧</h2>
        </>
    )

    const content = (
        <>
            <AdminUserListSearch
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                selectedAdminMemberType={selectedAdminMemberType}
                setSelectedAdminMemberType={setSelectedAdminMemberType}
                setSearchResults={setSearchResults}
                adminMemberTypeList={adminMemberTypeList}
                page={page}
                setPage={setPage}
                setIsLoading={setIsLoading}
            />
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                    <SyncLoader color="#36d7b7" />
                </div>
            ) : (
                searchResults.searchResultLength === 0 ?
                    <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff4d4d', margin: '20px' }}>
                        該当するユーザーは見つかりませんでした。
                    </div>
                    :
                    <>
                        <AdminUserListResult
                            searchResults={searchResults}
                            adminMemberTypeList={adminMemberTypeList}
                        />
                        {searchResults.searchResultLength > UserDetailDisplayLimitList[0] && (
                            <Pagination
                                currentPage={page}
                                totalPages={Math.ceil(searchResults.searchResultLength / UserDetailDisplayLimitList[0])}
                                onPageChange={setPage}
                            />
                        )}
                    </>
            )}
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )

    return (
        <>
            <CommonLayout
                header={header}
                content={content}
            />
        </>
    );
};

export default AdminUserList;