import React, { useState } from 'react';
import { formatDateDateAndTime } from '../../../utils/DateUtils';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { UserListResultContainer } from '../../../themes/Pages/UserManagement/UserListResultTheme';
import NotificationModal from '../../Modals/NotificationModal';
import UserListEditModal from './UserListEditModal';
import UserListDeleteModal from './UserListDeleteModal';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useOutletContext } from 'react-router-dom';
import { PiArrowSquareOut } from 'react-icons/pi';
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr";
import UserListBulkEditModal from './UserListBulkEditModal';
import { RiCheckboxIndeterminateLine } from "react-icons/ri";
import { HiSortDescending, HiSortAscending } from "react-icons/hi";

const UserListResult = ({ 
    searchResults, 
    memberTypeList,
    lastLoginReverse,
    setLastLoginReverse
}) => {

    const methods = useForm();
    const { adminUserInfo } = useOutletContext();
    const [notificationMessage, setNotificationMessage] = useState('');

    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationType, setNotificationType] = useState('');

    // 選択したユーザー
    const [selectedUser, setSelectedUser] = useState(null);

    //一括編集用
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);

    // 編集モーダル
    const [openEditModal, setOpenEditModal] = useState(false);
    // 削除モーダル
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setOpenEditModal(true);
    }

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        setOpenDeleteModal(true);
    }

    const handleSelectUser = (user) => {
        if (user.deleteFlg === '1') return;
        const isSelected = selectedUsers.some(selected => selected.id === user.id);
        if (isSelected) {
            setSelectedUsers(selectedUsers.filter(selected => selected.id !== user.id));
        } else {
            setSelectedUsers([...selectedUsers, {
                id: user.id,
                name: `${user.userLastName} ${user.userFirstName}`,
                email: user.mail,
                deleteFlg: user.deleteFlg,
                memberTypeCode: user.memberTypeCode,
                accessTypeCode: user.accessTypeCode
            }]);
        }
    }

    const handleSelectAllUsers = () => {
        const selectableUsers = searchResults?.userInfo?.filter(user => user.deleteFlg !== '1');
        if (selectedUsers.length === selectableUsers.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(selectableUsers.map(user => ({
                id: user.id,
                name: `${user.userLastName} ${user.userFirstName}`,
                email: user.mail,
                deleteFlg: user.deleteFlg,
                memberTypeCode: user.memberTypeCode,
                accessTypeCode: user.accessTypeCode
            })));
        }
    }

    return (
        <>
            <UserListResultContainer>
                {adminUserInfo?.adminMemberTypeCode !== '03' && (
                    <div className='BulkEditButtonContainer'>
                        <button className='BulkEditButton' onClick={() => setOpenBulkEditModal(true)}>一括編集</button>
                    </div>
                )}
                <div className='SearchResultHeaderItem'>
                    <span className='tableItem Checkbox' onClick={() => handleSelectAllUsers()}>選択</span>
                    <span className='tableItem UserName'>氏名</span>
                    <span className='tableItem UserEmail'>メールアドレス</span>
                    <span className='tableItem UserCategory'>利用者区分</span>
                    <span className='tableItem UserMemberType'>会員区分</span>
                    <span className='tableItem Status'>ステータス</span>
                    <span className='tableItem UpdateDate'>
                        <div className='ItemTitle'>最終ログイン日時</div>
                        <div className='ItemSort' onClick={() => setLastLoginReverse(!lastLoginReverse)}>
                            {lastLoginReverse ? 
                                <HiSortAscending size={20} /> 
                                : <HiSortDescending size={20} />}
                        </div>
                    </span>
                    <span className='tableItem CreateDate'>登録日時</span>
                    <span className='tableItem Action'>アクション</span>
                </div>
                <div className='SearchResultBody'>
                    {searchResults?.userInfo?.map((result, index) => (
                        <div 
                            key={`${result.id}-${index}`} 
                            className={`SearchResultItem ${selectedUsers.some(selected => selected.id === result.id) ? 'selected' : ''}`}
                        >
                            <span className='tableItem Checkbox' onClick={() => handleSelectUser(result)}>
                                {result.deleteFlg === '1' ?
                                    <RiCheckboxIndeterminateLine size={15} cursor='not-allowed' /> :
                                    (selectedUsers.some(selected => selected.id === result.id) ?
                                        <GrCheckboxSelected size={15} cursor='pointer' /> :
                                        <GrCheckbox size={15} cursor='pointer' />
                                    )
                                }
                            </span>
                            <span className='tableItem UserName'>{result.userLastName} {result.userFirstName}</span>
                            <span className='tableItem UserEmail'>{result.mail}</span>
                            <span className='tableItem UserCategory'>{result.categoryName}</span>
                            <span className='tableItem UserMemberType'>{memberTypeList.find(type => type.memberTypeCode === result.memberTypeCode)?.memberTypeName}</span>
                            <span className='tableItem Status'>
                                {result.deleteFlg === '1' ? '退会済(' + result.leaveTypeName + ')'
                                    : result.memberTypeCode === '02' ? '未入会' : '入会中'}
                            </span>
                            <span className='tableItem loginHistory'>
                                {result.loginHistory ? formatDateDateAndTime(result.loginHistory) : 'ログイン履歴無し'}
                            </span>
                            <span className='tableItem CreateDate'>{formatDateDateAndTime(result.createDate)}</span>
                            <span className='tableItem Action'>
                                <Link
                                    to={`/user-page/${result.id}`}
                                    target='_blank'
                                >
                                    <div className='ActionText'>詳細</div>
                                    <div className='ActionIcon'><PiArrowSquareOut size={15} /></div>
                                </Link>
                                {adminUserInfo?.adminMemberTypeCode !== '03' && (
                                    <>
                                        <button className='EditButton' onClick={() => handleEditUser(result)} disabled={result.deleteFlg === '1'}>
                                            <FaRegEdit className='ButtonIcon' size={15} />
                                            <div className='ButtonText'>編集</div>
                                        </button>
                                        <button className='DeleteButton' onClick={() => handleDeleteUser(result)} disabled={result.deleteFlg === '1'}>
                                            <FaRegTrashAlt className='ButtonIcon' size={15} />
                                            <div className='ButtonText'>退会</div>
                                        </button>
                                    </>
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            </UserListResultContainer>
            {openEditModal && selectedUser &&
                <FormProvider {...methods}>
                    <UserListEditModal
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        setOpenEditor={setOpenEditModal}
                        setNotificationMessage={setNotificationMessage}
                        setNotificationType={setNotificationType}
                        setIsNotificationOpen={setIsNotificationOpen}
                    />
                </FormProvider>
            }
            {openDeleteModal &&
                <UserListDeleteModal
                    selectedUser={selectedUser}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            }
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
            {openBulkEditModal &&
                <UserListBulkEditModal
                    selectedUsers={selectedUsers}
                    setOpenBulkEditModal={setOpenBulkEditModal}
                    memberTypeList={memberTypeList}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />
            }
        </>
    )
}

export default UserListResult;