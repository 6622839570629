import styled from '@emotion/styled';

export const TopHeaderTheme = styled.div`
    height: 46px;
    background-color: ${props => props.theme.background};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;

    .logoContainer {
        position: relative;
        height: 46px;
        width: 46px;
    }

    .titleLogo {
        margin: 12px;
    }
`;