export const logout = async (navigate) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_LOGOUT}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            localStorage.setItem('isLogout', 'true');
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'ログアウト時にエラーが発生しました' } });
        }
    } catch (error) {
        console.error('logout.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ログアウト時にエラーが発生しました' } });
    }
}

