import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import { UserListEditModalContainer, UserListEditCard, UserListInputItem, UserListEditForm } from '../../../themes/Pages/UserManagement/UserListEditModalTheme';
import { updateUser } from '../../../api/UserManagement/userList';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Prefectures } from '../../../utils/Constants';
import { SchoolTypeSearch, fetchSchoolSuggestions } from '../../../api/Common/common';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const UserListEditModal = ({ selectedUser, setSelectedUser, setOpenEditor, setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {

    const navigate = useNavigate();
    const { memberTypeList, accessTypeList } = useOutletContext();
    const { register, handleSubmit, formState: { errors, isValid }, trigger, setValue, clearErrors, watch, reset } = useFormContext({
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const years = useMemo(() => {
        return Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).reverse();
    }, []);
    const months = useMemo(() => {
        return Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
    }, []);
    const [days, setDays] = useState(Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0')));

    const [selectedYear, setSelectedYear] = useState(() => {
        const date = new Date(selectedUser.birthDate);
        return date.getFullYear().toString();
    });
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date(selectedUser.birthDate);
        return String(date.getMonth() + 1).padStart(2, '0');
    });
    const [selectedDay, setSelectedDay] = useState(() => {
        const date = new Date(selectedUser.birthDate);
        return String(date.getDate()).padStart(2, '0');
    });

    const [schoolTypes, setSchoolTypes] = useState([]);
    const [selectedSchoolType, setSelectedSchoolType] = useState(selectedUser.schoolType);
    const [schoolName, setSchoolName] = useState(selectedUser.schoolName);
    const [isSchoolSuggestions, setIsSchoolSuggestions] = useState(false);
    const [schoolSuggestions, setSchoolSuggestions] = useState([]);

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [passwordGenerated, setPasswordGenerated] = useState(false);

    // Update form values when selectedUser changes
    useEffect(() => {
        // Set form values using setValue
        setValue('id', selectedUser.id);
        setValue('lastName', selectedUser.userLastName);
        setValue('firstName', selectedUser.userFirstName);
        setValue('lastNameKana', selectedUser.userLastNameKana);
        setValue('firstNameKana', selectedUser.userFirstNameKana);
        setValue('mail', selectedUser.mail);
        setValue('nickName', selectedUser.nickName);
        setValue('gender', selectedUser.gender);
        setValue('prefecture', selectedUser.prefecture);
        setValue('phoneNumber', selectedUser.phoneNumber);
        setValue('schoolType', selectedUser.schoolType);
        setValue('schoolName', selectedUser.schoolName);
        setValue('password', '');
        setValue('memberTypeCode', selectedUser.memberTypeCode);
        setValue('accessTypeCode', selectedUser.accessTypeCode);
        setValue('birthdate', selectedUser.birthDate);

        // Update other state values
        setSelectedSchoolType(selectedUser.schoolType);
        setSchoolName(selectedUser.schoolName);
        setPasswordGenerated(false);

        // Update birth date values
        const birthDate = new Date(selectedUser.birthDate);
        setSelectedYear(birthDate.getFullYear().toString());
        setSelectedMonth(String(birthDate.getMonth() + 1).padStart(2, '0'));
        setSelectedDay(String(birthDate.getDate()).padStart(2, '0'));

        // Trigger validation
        trigger();
    }, [selectedUser, setValue, trigger]);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const handleSchoolNameChange = (e) => {
        setSchoolName(e);
        fetchSchoolSuggestions(navigate, selectedSchoolType, e).then((suggestions) => {
            setSchoolSuggestions(suggestions);
        });
    }

    const handleCancel = () => {
        setOpenEditor(false);
        setSelectedUser(null);
        setValue('password', '');
        setPasswordGenerated(false);
        setIsPasswordVisible(false);
        reset();
    }

    //学校種別の取得
    useEffect(() => {
        const fetchSchoolTypes = async () => {
            const schoolTypes = await SchoolTypeSearch(navigate);
            setSchoolTypes(schoolTypes);
        };
        fetchSchoolTypes();
    }, [navigate]);

    const handleDayChange = useCallback((day) => {
        setSelectedDay(day);
    }, []);

    useEffect(() => {
        if (selectedYear && selectedMonth && selectedDay) {
            const maxDays = new Date(selectedYear, parseInt(selectedMonth), 0).getDate();
            setDays(Array.from({ length: maxDays }, (_, i) => String(i + 1).padStart(2, '0')));
            // 現在選択されている日が不正な場合、最初の日に設定
            if (parseInt(selectedDay) > maxDays) {
                handleDayChange('01');
            } else {
                const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;
                setValue('birthdate', formattedDate);
                trigger('birthdate');
            }
        }
    }, [selectedYear, selectedMonth, selectedDay, handleDayChange, setValue, trigger]);

    const changeYear = (e) => {
        setSelectedYear(e.target.value);
    }

    const changeMonth = (e) => {
        setSelectedMonth(e.target.value);
    }

    const changeDay = (e) => {
        setSelectedDay(e.target.value);
    }

    const handleSchoolSelect = (schoolName) => {
        setSchoolName(schoolName);
        setValue('schoolName', schoolName);  // Update form value
        setIsSchoolSuggestions(false);
    }

    //ランダムパスワード生成
    const generatePassword = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*-_';
        const allChars = letters + numbers + specialChars;
        let newPassword = '';
        // 少なくとも1文字、1数字、1記号を含むように生成
        newPassword += letters.charAt(Math.floor(Math.random() * letters.length));
        newPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
        newPassword += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
        // パスワードの残りの桁数をランダムに生成
        for (let i = 3; i < 12; i++) {
            newPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }
        // シャッフル
        newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');
        setValue('password', newPassword);
        setPasswordGenerated(true);
        setIsPasswordVisible(true);
    }

    const handleIndividSubmit = async (data) => {
        // 変更検知のためのデータ比較
        const isDataChanged =
            data.id === selectedUser.id &&
            data.lastName === selectedUser.userLastName &&
            data.firstName === selectedUser.userFirstName &&
            data.lastNameKana === selectedUser.userLastNameKana &&
            data.firstNameKana === selectedUser.userFirstNameKana &&
            data.mail === selectedUser.mail &&
            data.memberTypeCode === selectedUser.memberTypeCode &&
            data.accessTypeCode === selectedUser.accessTypeCode &&
            data.nickName === selectedUser.nickName &&
            data.gender === selectedUser.gender &&
            data.birthdate === selectedUser.birthDate &&
            data.prefecture === selectedUser.prefecture &&
            data.phoneNumber === selectedUser.phoneNumber &&
            data.schoolType === selectedUser.schoolType &&
            schoolName === selectedUser.schoolName &&
            data.password === '';

        if (isDataChanged) {
            setNotificationMessage('変更がありません');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        const userData = {
            id: data.id,
            userLastName: data.lastName,
            userFirstName: data.firstName,
            userLastNameKana: data.lastNameKana,
            userFirstNameKana: data.firstNameKana,
            mail: data.mail,
            memberTypeCode: data.memberTypeCode,
            accessTypeCode: data.accessTypeCode,
            nickName: data.nickName,
            gender: data.gender,
            birthDate: data.birthdate,
            prefecture: data.prefecture,
            phoneNumber: data.phoneNumber,  // この値が正しく送信されているか確認
            schoolType: data.schoolType,
            schoolName: schoolName,
            password: data.password
        };

        //API呼び出し
        const response = await updateUser(navigate, userData);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            //成功した場合はローカルストレージに通知メッセージを保存し、リロード
            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    }

    useEffect(() => {
        // 初期値のバリデーションを実行
        trigger();
    }, [trigger]);

    // フォームの値が変更されたときにエラーをクリアする関数
    const handleFieldChange = (fieldName) => {
        clearErrors(fieldName);
        trigger(fieldName);
    };

    // パスワード入力フィールドの変更ハンドラを追加
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setValue('password', newPassword);
        if (newPassword === '') {
            setPasswordGenerated(false);
            setIsPasswordVisible(false);
        }
        clearErrors('password');
        trigger('password');
    }

    return (
        <UserListEditModalContainer>
            <UserListEditCard>
                <div className='closeButton'>
                    <FaTimes size={20} onClick={handleCancel} cursor="pointer" />
                </div>
                <UserListEditForm onSubmit={handleSubmit(handleIndividSubmit)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}>
                    <UserListInputItem>
                        <input type="hidden" name="id" value={selectedUser.id} {...register('id')} />
                        <label className='leftZone'>氏名</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="lastName" name="lastName"
                                    defaultValue={selectedUser.userLastName}
                                    className={errors.lastName ? 'error' : ''}
                                    {...register('lastName', {
                                        required: '苗字を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '苗字に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('lastName')
                                    })} />
                                {errors.lastName && <span className='errorMessage'>{errors.lastName.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstName" name="firstName"
                                    defaultValue={selectedUser.userFirstName}
                                    className={errors.firstName ? 'error' : ''}
                                    {...register('firstName', {
                                        required: '名前を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '名前に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('firstName')
                                    })} />
                                {errors.firstName && <span className='errorMessage'>{errors.firstName.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>氏名(カナ)</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="lastNameKana" name="lastNameKana"
                                    defaultValue={selectedUser.userLastNameKana}
                                    className={errors.lastNameKana ? 'error' : ''}
                                    {...register('lastNameKana', {
                                        required: '苗字のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('lastNameKana')
                                    })} />
                                {errors.lastNameKana && <span className='errorMessage'>{errors.lastNameKana.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstNameKana" name="firstNameKana"
                                    defaultValue={selectedUser.userFirstNameKana}
                                    className={errors.firstNameKana ? 'error' : ''}
                                    {...register('firstNameKana', {
                                        required: '名前のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('firstNameKana')
                                    })} />
                                {errors.firstNameKana && <span className='errorMessage'>{errors.firstNameKana.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>メールアドレス</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="mail" name="mail"
                                    defaultValue={selectedUser.mail}
                                    className={errors.mail ? 'error' : ''}
                                    {...register('mail', {
                                        required: 'メールアドレスを入力してください',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'メールアドレスの形式が不正です'
                                        },
                                        onChange: () => handleFieldChange('mail')
                                    })} />
                                {errors.mail && <span className='errorMessage'>{errors.mail.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>会員区分</label>
                        <div className='rightZone'>
                            <select value={watch('memberTypeCode')} {...register('memberTypeCode')}>
                                {memberTypeList?.map((memberType) => (
                                    <option key={`memberType-${memberType.memberTypeCode}`} value={memberType.memberTypeCode}>{memberType.memberTypeName}</option>
                                ))}
                            </select>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>アクセス区分</label>
                        <div className='rightZone'>
                            <select value={watch('accessTypeCode')} {...register('accessTypeCode')}>
                                {accessTypeList?.map((accessType) => (
                                    <option key={`accessType-${accessType.accessTypeCode}`} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                                ))}
                            </select>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>ニックネーム</label>
                        <div className='rightZone'>
                            <input type="text" id="nickName" name="nickName"
                                defaultValue={selectedUser.nickName}
                                className={errors.nickName ? 'error' : ''}
                                {...register('nickName', {
                                    maxLength: {
                                        value: 20,
                                        message: 'ニックネームは20文字以内で入力してください'
                                    },
                                    pattern: {
                                        value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                        message: 'ニックネームは全角文字で入力してください'
                                    },
                                    onChange: () => handleFieldChange('nickName')
                                })} />
                            {errors.nickName && <span className='errorMessage'>{errors.nickName.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>性別</label>
                        <div className='rightZone'>
                            <input type="radio" value="M" id="genderM" name="gender"
                                {...register('gender')}
                                defaultChecked={selectedUser.gender === 'M'}
                            />男性
                            <input type="radio" value="F" id="genderF" name="gender"
                                {...register('gender')}
                                defaultChecked={selectedUser.gender === 'F'}
                            />女性
                            <input type="radio" value="O" id="genderO" name="gender"
                                {...register('gender')}
                                defaultChecked={selectedUser.gender === 'O'}
                            />その他
                            {errors.gender && <span className='errorMessage'>{errors.gender.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>生年月日</label>
                        <div className='rightZone'>
                            <div className='birthZone'>
                                <div className='year'>
                                    <select
                                        {...register('birthYear')}
                                        className={`selectField year ${errors.birthYear ? 'error' : ''}`}
                                        value={selectedYear}
                                        onChange={changeYear}
                                    >
                                        {years?.map((year) => (
                                            <option key={`birthYear-${year}`} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                    <span>年</span>
                                </div>
                                <div className='month'>
                                    <select
                                        {...register('birthMonth')}
                                        className={`selectField month ${errors.birthMonth ? 'error' : ''}`}
                                        value={selectedMonth}
                                        onChange={changeMonth}
                                    >
                                        {months?.map((month) => (
                                            <option key={`birthMonth-${month}`} value={month}>
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                    <span>月</span>
                                </div>
                                <div className='day'>
                                    <select
                                        {...register('birthDay')}
                                        className={`selectField day ${errors.birthDay ? 'error' : ''}`}
                                        value={selectedDay}
                                        onChange={changeDay}
                                    >
                                        {days?.map((day) => (
                                            <option key={`birthDay-${day}`} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                    <span>日</span>
                                </div>
                                <input
                                    type="hidden" id="birthdate" name="birthdate"
                                    {...register('birthdate')}
                                />
                            </div>
                            {errors.birthdate && <span className='errorMessage'>{errors.birthdate.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>都道府県</label>
                        <div className='rightZone'>
                            <select
                                id="prefecture"
                                name="prefecture"
                                className={`selectField ${errors.prefecture ? 'error' : ''}`}
                                {...register('prefecture')}
                                defaultValue={selectedUser.prefecture}
                            >
                                <option value="">選択してください</option>
                                {Prefectures?.map((prefecture) => (
                                    <option value={prefecture.name}>{prefecture.name}</option>
                                ))}
                            </select>
                            {errors.prefecture && <span className='errorMessage'>{errors.prefecture.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>
                            <div className='phoneNumberLabel'>電話番号</div>
                            <p className='phoneNumberNote'>半角数字のみ入力可能</p>
                            <p className='phoneNumberNote'>※ハイフンは不要です</p>
                        </label>
                        <div className='rightZone'>
                            <input type="text" id="phoneNumber" name="phoneNumber"
                                defaultValue={selectedUser.phoneNumber}
                                className={`phoneNumberInput ${errors.phoneNumber ? 'error' : ''}`}
                                {...register('phoneNumber', {
                                    maxLength: {
                                        value: 15,
                                        message: '電話番号は15桁以内で入力してください'
                                    },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: '電話番号は数字で入力してください'
                                    },
                                    onChange: () => handleFieldChange('phoneNumber')
                                })} />
                            {errors.phoneNumber && <span className='errorMessage'>{errors.phoneNumber.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>学校種別</label>
                        <div className='rightZone'>
                            <select id="schoolType" name="schoolType"
                                className={`selectField ${errors.schoolType ? 'error' : ''}`}
                                {...register('schoolType', {
                                    onChange: (e) => {
                                        setSelectedSchoolType(e.target.value);
                                        handleFieldChange('schoolType');
                                    }
                                })}
                                defaultValue={selectedSchoolType}
                            >
                                {schoolTypes?.map((schoolType) => (
                                    <option key={`schoolType-${schoolType.typeCode}-${schoolType.code}`} value={schoolType.code}>{schoolType.codeName}</option>
                                ))}
                            </select>
                            {errors.schoolType && <span className='errorMessage'>{errors.schoolType.message}</span>}
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>学校名</label>
                        <div className='rightZone'>
                            <input
                                type="text"
                                id="schoolName"
                                name="schoolName"
                                className={`selectField ${errors.schoolName ? 'error' : ''}`}
                                defaultValue={selectedUser.schoolName}
                                placeholder='学校名は手入力も可能です'
                                {...register('schoolName', {
                                    onChange: (e) => handleSchoolNameChange(e.target.value)
                                })}
                                onFocus={() => setIsSchoolSuggestions(true)}
                                onBlur={() => setIsSchoolSuggestions(false)}
                            />
                            {isSchoolSuggestions &&
                                <div className='schoolSuggestions' length={schoolSuggestions.length}>
                                    <ul className='schoolSuggestionsList'>
                                        {schoolSuggestions?.map((school, index) => (
                                            <li key={`school-${school.schoolId}-${index}`}
                                                className='schoolSuggestion'
                                                onMouseDown={(e) => e.preventDefault()}
                                                onClick={() => handleSchoolSelect(school.schoolName)}
                                            >
                                                {school.schoolName}
                                            </li>
                                        ))}
                                    </ul>
                                </div>}
                        </div>
                        {errors.schoolName && <span className='errorMessage'>{errors.schoolName.message}</span>}
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>パスワードリセット</label>
                        <div className='rightZone'>
                            <div className='inputArea'>
                                <input
                                    type={isPasswordVisible && passwordGenerated ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className={`passwordInput ${errors.password ? 'error' : ''}`}
                                    placeholder='パスワードは8文字以上で入力してください'
                                    defaultValue=""
                                    {...register('password', {
                                        validate: (value) => {
                                            if (!value || value === '') return true; // パスワードは必須ではない
                                            if (value.length < 8) return 'パスワードは8文字以上で入力してください';
                                            if (value.length > 64) return 'パスワードは64文字以内で入力してください';
                                            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-_])[A-Za-z\d!@#$%^&*-_]{8,}$/.test(value)) {
                                                return 'パスワードは英字、数字、記号(!@#$%^&*-_)をそれぞれ1文字以上含む必要があります';
                                            }
                                            return true;
                                        },
                                        onChange: (e) => {
                                            handlePasswordChange(e);
                                        }
                                    })}
                                />
                                <button type="button" onClick={togglePasswordVisibility} className='ToggleVisibilityButton'>
                                    {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                </button>
                                {errors.password && <span className='errorMessage'>{errors.password.message}</span>}
                            </div>
                            <div onClick={generatePassword} className='GeneratePasswordButton'>
                                自動生成
                            </div>
                        </div>
                    </UserListInputItem>
                    <div className='ButtonZone'>
                        <div className='CancelButton' onClick={handleCancel}>キャンセル</div>
                        <button type="submit" className='UpdateButton' disabled={!isValid}>更新</button>
                    </div>
                </UserListEditForm>

            </UserListEditCard>
        </UserListEditModalContainer>
    )
}

export default UserListEditModal;