import styled from '@emotion/styled';

export const UserListResultContainer = styled.div`
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .BulkEditButtonContainer{
        width: calc(100% - 20px);
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        
        .BulkEditButton{
            background-color: ${props => props.theme.primary};
            color: ${props => props.theme.buttonText};
            border: none;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;

            &:hover{
                background-color: ${props => props.theme.primaryHover};
            }
        }
    }

    .SearchResultHeaderItem{
        width: calc(100% - 20px);
        display: grid;
        align-items: center;
        grid-template-columns: 0.4fr 0.7fr 1.2fr 0.7fr 0.7fr 0.9fr 1.2fr 1fr 1.6fr;
        background-color: ${props => props.theme.background};
        padding: 10px;
        font-size: 14px;
        font-weight: bold;
        color: ${props => props.theme.text};

        .Checkbox{
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .tableItem{
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .UpdateDate{
            display: flex;
            flex-direction: row;
            align-items: center;

            .ItemTitle{
                margin-right: 5px;
            }

            .ItemSort{
                display: flex;
                align-items: center;
                cursor: pointer;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 5px;

                &:hover{
                    background-color: ${props => props.theme.backgroundHover};
                }
            }
        }
    }

    .SearchResultBody{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin-top: 0px;
        font-size: 14px;
        overflow-x: hidden;

        .selected{
            background-color: ${props => props.theme.backgroundHover};
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease;
        }

        .SearchResultItem{
            width: calc(100% - 20px);
            display: grid;
            grid-template-columns: 0.4fr 0.7fr 1.2fr 0.7fr 0.7fr 0.9fr 1.2fr 1fr 1.6fr;
            border-bottom: 1px solid ${props => props.theme.border};
            padding: 10px;
            align-items: center;
            transition: all 0.3s ease;
            position: relative;

            .tableItem{
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .Checkbox{
                display: flex;
                align-items: center;
            }
        
            .UserEmail{
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .Action{
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                width: fit-content;

                .DisabledDetails{
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: not-allowed;
                    width: fit-content;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background-color: ${props => props.theme.disabled};

                    .ActionText{
                        margin: 0 10px 0 5px;
                    }

                    .ActionIcon{
                        margin: 0 5px 0 0;
                    }
                }

                a{
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer;
                    width: fit-content;
                    text-decoration: none;
                    color: ${props => props.theme.text};
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .ActionText{
                        margin: 0 10px 0 5px;
                    }

                    .ActionIcon{
                        margin: 0 5px 0 0;
                    }

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }
                }

                .EditButton{
                    margin: 0 5px 0 5px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: ${props => props.theme.primary};

                    .ButtonIcon{
                        margin: 0 5px 0 0;
                    }
                    .ButtonText{
                        margin: 0 5px 0 0px;
                    }

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }

                    &:disabled{
                        background-color: ${props => props.theme.disabled};
                        color: ${props => props.theme.text};
                        cursor: not-allowed;
                    }
                }

                .DeleteButton{
                    margin: 0 10px 0 5px;
                    border: 1px solid ${props => props.theme.border};
                    border-radius: 5px;
                    padding: 5px;
                    cursor: pointer;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    color: ${props => props.theme.error};

                    .ButtonIcon{
                        margin: 0 5px 0 0;
                    }
                    .ButtonText{
                        margin: 0 5px 0 0px;
                    }

                    &:hover{
                        background-color: ${props => props.theme.backgroundHover};
                    }

                    &:disabled{
                        background-color: ${props => props.theme.disabled};
                        color: ${props => props.theme.text};
                        cursor: not-allowed;
                    }
                }
            }
            }
        }
    }
`;