import styled from '@emotion/styled';

export const SearchForm = styled.form`
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
`;

export const InputArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    label {
        width: 100px;
        font-size: 14px;
        margin-right: 10px;
    }

    select {
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        padding: 0 10px;   
    }

    input {
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        padding: 0 10px;
    }

    .date-separator {
        margin: 0 10px;
    }
`;

export const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
        width: 70px;
        height: 40px;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.buttonText};
        border: none;
        border-radius: 5px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
        bottom: 0px;

        &:hover {
            background-color: ${props => props.theme.primaryHover};
        }
    }
    
`;


