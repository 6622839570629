import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPageProgressContainer, SearchPart, PartsContainer, PartCard, Tabs } from '../../../themes/Pages/UserManagement/UserPageProgressTheme';
import { CiSearch } from "react-icons/ci";
import { fetchPartsProgressData } from '../../../api/UserManagement/userProgress';
import UserPageProgressDetail from './UserPageProgressDetail';
import { SyncLoader } from 'react-spinners';

const UserPageProgress = ({ userId }) => {

    const navigate = useNavigate();
    const [partsList, setPartsList] = useState([]);
    const [selectedPart, setSelectedPart] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedTabs, setSelectedTabs] = useState(['All']);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const strapiImageUrl = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_ENDPOINT_GET_IMAGE}?id=`;

    const tabs = useMemo(() => {
        const uniqueCategories = partsList.length > 0
            ? Array.from(new Set(partsList.flatMap(item => item.partCategory).filter(category => category !== null)))
            : [];
        return ['All', ...uniqueCategories];
    }, [partsList]);

    const handleTabClick = (tab) => {
        if (tab === 'All') {
            setSelectedTabs(['All']);
        } else if (selectedTabs.includes('All')) {
            setSelectedTabs([tab]);
        } else if (selectedTabs.includes(tab)) {
            setSelectedTabs(selectedTabs.filter(t => t !== tab));
            if (selectedTabs.length === 1) {
                setSelectedTabs(['All']);
            }
        } else {
            setSelectedTabs(prevTabs => [...prevTabs, tab]);
        }
    };

    const fetchPartsProgress = useCallback(async () => {
        if (userId) {
            setIsLoading(true);
            try {
                const partsProgressData = await fetchPartsProgressData(navigate, userId);
                if (partsProgressData.length > 0) {
                    const mappedPartsList = partsProgressData.map(part => ({
                        partId: part.partId,
                        partTitle: part.partTitle,
                        partCategory: part.category,
                        partImage: part.partImage,
                        progressRate: part.progressRate,
                        completedArticles: part.completedArticles,
                        totalArticles: part.totalArticles,
                        color: part.color
                    }));
                    setPartsList(mappedPartsList);
                } else {
                    setErrorMessage("データベースに欠損があります。");
                }
            } catch (error) {
                console.error('APIエラー：パート別の進捗率の取得', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [userId, setPartsList, navigate]);

    const handlePartClick = (part) => {
        if (selectedPart && selectedPart.partId === part.partId) {
            setSelectedPart(null);
        } else {
            setSelectedPart(part);
        }
    };

    useEffect(() => {
        fetchPartsProgress();
    }, [fetchPartsProgress]);

    return (
        <>
            <UserPageProgressContainer>
                <SearchPart>
                    <div className="searchIcon"><CiSearch size={20} /></div>
                    <input type="text" placeholder="言語を検索..." value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
                </SearchPart>
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                        <SyncLoader color="#36d7b7" />
                    </div>
                ) : (
                    <>
                        <Tabs>
                            {tabs.map((tab, index) => (
                                <div className={`tab ${selectedTabs.includes(tab) ? 'active' : ''}`} key={index} onClick={() => handleTabClick(tab)}>
                                    <div>{tab}</div>
                                </div>
                            ))}
                        </Tabs>
                        <PartsContainer>
                            {partsList.filter(part => selectedTabs.includes('All') || part.partCategory.some(category => selectedTabs.includes(category))).filter(part => part.partTitle.includes(searchKeyword)).map((part) => (
                                <PartCard key={part.partId} onClick={() => handlePartClick(part)} className={selectedPart === part ? 'selected' : ''}>
                                    <div className="attributes">
                                        <img src={part.partImage ? `${strapiImageUrl}${part.partImage}` : ''} alt={part.partTitle} />
                                        <div className="partTitleAndCategory">
                                            <div className="partTitle">{part.partTitle}</div>
                                            <div className="categoryBox">
                                                {part.partCategory.map((category, index) => (
                                                    <div className="partCategory" key={index}>{category}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="progress">
                                        <div className="progressRate">
                                            <div className="progressRateTitle">進捗状況</div>
                                            <div className="progressRateValue">{Math.floor(part.progressRate)}%（{part.completedArticles}/{part.totalArticles}問完了）</div>
                                        </div>
                                        <div className="progressBar">
                                            <div className="completeBar" style={{ width: `${Math.floor(part.progressRate)}%`, backgroundColor: part.color }}></div>
                                            {errorMessage && <div className="errorMessage">{errorMessage}</div>}
                                        </div>
                                    </div>
                                </PartCard>
                            ))}
                        </PartsContainer>
                    </>
                )}
            </UserPageProgressContainer>
            {selectedPart && (
                <UserPageProgressDetail userId={userId} partId={selectedPart.partId} partTitle={selectedPart.partTitle} progressRate={selectedPart.progressRate} />
            )}
        </>
    );
};

export default UserPageProgress;