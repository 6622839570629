//学校種別の取得
export const SchoolTypeSearch = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const schoolTypeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_SCHOOL_TYPE}`;
        const response = await fetch(schoolTypeUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '学校種別の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：学校種別の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '学校種別の取得に失敗しましたよ' } });
    }
};

//学校名の取得
export const fetchSchoolSuggestions = async (navigate, schoolType, schoolName) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const schoolSuggestionsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_SCHOOL_NAME}?schoolType=${schoolType}&schoolName=${schoolName}`;
        const response = await fetch(schoolSuggestionsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                schoolType: schoolType,
                schoolName: schoolName
            }),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '学校名の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：学校名の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '学校名の取得に失敗しましたよ' } });
    }
};

//退会区分の取得
export const fetchLeaveTypeList = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const leaveTypeListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_LEAVE_TYPE_LIST}`;
        const response = await fetch(leaveTypeListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '退会区分の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：退会区分の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '退会区分の取得に失敗しました' } });
    }
};

//ユーザー区分の取得
export const fetchUserCategoryList = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const userCategoryListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_CATEGORY_LIST}`;
        const response = await fetch(userCategoryListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'ユーザー区分の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：ユーザー区分の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザー区分の取得に失敗しました' } });
    }
};

//アクセス区分リストの取得
export const fetchAccessTypeList = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const accessTypeListUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_ACCESS_TYPE_LIST}`;
        const response = await fetch(accessTypeListUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'アクセス区分リストの取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：アクセス区分リストの取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'アクセス区分リストの取得に失敗しましたよ' } });
    }
};

export const fetchMemberTypes = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const memberTypeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_MEMBER_TYPE_LIST}`;
        const response = await fetch(memberTypeUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            return await response.json();
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '会員区分の取得に失敗しました' } });
        }
    } catch (error) {
        navigate('/error', { state: { statusCode: 500, errorMessage: '認証情報が削除されました。' } });
    }
};

export const fetchAdminMemberTypes = async (navigate) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const memberTypeUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_ADMIN_MEMBER_TYPE_LIST}`;
        const response = await fetch(memberTypeUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
        });
        if (response.ok) {
            return await response.json();
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '会員区分の取得に失敗しました' } });
        }
    } catch (error) {
        navigate('/error', { state: { statusCode: 500, errorMessage: '認証情報が削除されました。' } });
    }
};