import styled from '@emotion/styled';

export const DeleteUserModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const UserListDeleteCard = styled.div`
    background-color: ${props => props.theme.background};
    padding: 20px;
    border-radius: 10px;

    .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .DeleteUserModalTitle {
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        width: 100%;
        margin: 0 0 10px 0;
    }

    .DeleteUserModalContent {
        font-size: 14px;
        text-align: left;
        width: 100%;
        margin: 0 0 10px 0;
    }

    .selectContainer {
        width: 100%;
        margin: 0 0 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .select {
            width: 100%;
            height: 30px;
            padding: 5px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.backgroundHover};
            }
        }
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin: 10px 0 0 0;

        .cancelButton {
            margin: 0 10px 0 0;
            background-color: transparent;
            color: ${props => props.theme.text};
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 7px 10px;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.backgroundHover};
            }
        }

        .deleteButton {
            background-color: ${props => props.theme.error};
            color: ${props => props.theme.buttonText};
            border: none;
            border-radius: 5px;
            padding: 7px 10px;
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.errorHover};
            }

            &:disabled {
                background-color: ${props => props.theme.disabled};
                cursor: not-allowed;
            }
        }
    }
`;