export const adminUserListSearchUser = async (navigate, searchWord, selectedAccessType, selectedAdminMemberType, selectedStatus, page, displayLimit) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const searchResultsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_ADMIN_USER_LIST_SEARCH_USER}`;

        const response = await fetch(searchResultsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                searchWord: searchWord,
                accessType: selectedAccessType,
                adminMemberType: selectedAdminMemberType,
                status: selectedStatus,
                page: page,
                displayLimit: displayLimit
            }),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '管理者ユーザー検索に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：管理者ユーザー検索', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '管理者ユーザー検索に失敗しました' } });
    }
};

//個別ユーザーの更新
export const updateAdminUser = async (navigate, userData) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const editUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_ADMIN_USER_LIST_UPDATE_USER}`;

        const response = await fetch(editUserUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '管理者ユーザーの更新に失敗しました' } });
        }

    } catch (error) {
        console.error('APIエラー：管理者ユーザーの更新', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '管理者ユーザーの更新に失敗しました' } });
    }
};

//複数ユーザーの更新
export const updateAdminUsers = async (navigate, userData) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const editUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_ADMIN_USER_LIST_UPDATE_USERS}`;

        const response = await fetch(editUserUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '管理者ユーザーの更新に失敗しました' } });
        }

    } catch (error) {
        console.error('APIエラー：管理者ユーザーの更新', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '管理者ユーザーの更新に失敗しました' } });
    }
};

export const quitAdminUser = async (navigate, user) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const quitUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_ADMIN_USER_LIST_QUIT_USER}`;

        const response = await fetch(quitUserUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'ユーザーの退会に失敗しました' } });
        }

    } catch (error) {
        console.error('APIエラー：管理者ユーザーの退会', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '管理者ユーザーの退会に失敗しました' } });
    }
};