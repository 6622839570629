import React from 'react';
import { LearningProgressResultContainer, ProgressContainer } from '../../../themes/Pages/UsageAnalysis/LearningProgressResultTheme';
import { formatDateDateAndTime } from '../../../utils/DateUtils';
import { Link } from 'react-router-dom';
import { PiArrowSquareOut } from 'react-icons/pi';
import { HiSortDescending, HiSortAscending } from "react-icons/hi";

const LearningProgressResult = ({ memberTypeList, userCategoryList, searchResult, progressReverse, setProgressReverse }) => {
    return (
        <LearningProgressResultContainer>
            <div className='SearchResultContainer'>
                <div className='SearchResultHeader'>
                    <div className='SearchResultHeaderItem'>氏名</div>
                    <div className='SearchResultHeaderItem'>利用者区分</div>
                    <div className='SearchResultHeaderItem'>会員区分</div>
                    <div className='SearchResultHeaderItem'>ステータス</div>
                    <div className='SearchResultHeaderItem'>教材</div>
                    <div className='SearchResultHeaderItem'>
                        <div className='progressHeader'>
                            <div className='ItemTitle'>進捗率</div>
                            <div className='ItemSort' onClick={() => setProgressReverse(!progressReverse)}>
                                {progressReverse ? 
                                    <HiSortAscending size={20} /> 
                                    : <HiSortDescending size={20} />}
                            </div>
                        </div>
                    </div>
                    <div className='SearchResultHeaderItem'>登録日時</div>
                    <div className='SearchResultHeaderItem'>最終ログイン日時</div>
                    <div className='SearchResultHeaderItem'>詳細</div>
                </div>
                <div className='SearchResultBody'>
                    {searchResult?.userList?.map((result, index) => (
                        <div className='SearchResultItem' key={index}>
                            <div className='SearchResultBodyItem'>{result.userLastName} {result.userFirstName}</div>
                            <div className='SearchResultBodyItem'>{userCategoryList.find(category => category.userCategoryCode === result.categoryCode)?.userCategoryName}</div>
                            <div className='SearchResultBodyItem'>{memberTypeList.find(type => type.memberTypeCode === result.memberTypeCode)?.memberTypeName}</div>
                            <div className='SearchResultBodyItem'>{result.deleteFlg === '0' ? '入会中' : '退会済(' + result.leaveTypeName + ')'}</div>
                            <div className='SearchResultBodyItem'>{result.partTitle}</div>
                            <div className='SearchResultBodyItem'>
                                <ProgressContainer>
                                    <div className='progressBar'>
                                        <div className='progressBarInner' style={{ width: `${result.progressRate}%` }}></div>
                                    </div>
                                    <div className='progressRate'>
                                        {result.progressRate}%
                                    </div>
                                </ProgressContainer>
                            </div>
                            <div className='SearchResultBodyItem'>{formatDateDateAndTime(result.createDate)}</div>
                            <div className='SearchResultBodyItem'>{result.lastLogin ? formatDateDateAndTime(result.lastLogin) : 'ログイン履歴なし'}</div>
                            <div className='SearchResultBodyItem'>
                                <Link to={`/user-page/${result.memberId}`} target='_blank' className='SearchResultBodyItemLink'>
                                    <div className='SearchResultBodyItemText'>詳細</div>
                                    <div className='SearchResultBodyItemIcon'><PiArrowSquareOut size={20} /></div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </LearningProgressResultContainer>
    );
};

export default LearningProgressResult;