import styled from '@emotion/styled';

export const QuestionHistoryChatModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modalContent {
        width: 600px;
        height: 90%;
        background-color: ${props => props.theme.systemBackground};
        border: 1px solid ${props => props.theme.border};
        border-radius: 10px;
        overflow: hidden;
    }
`;

export const ChatHistoryHeader = styled.div`
    height: 40px;
    border-bottom: 1px solid ${props => props.theme.border};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: ${props => props.theme.text};

    .chatHistoryTitle {
        display: flex;
        align-items: center;
        font-size: 15px;

        span {
            margin-left: 10px;
        }
    }

    .closeButton {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        cursor: pointer;
        margin-left: auto;

        &:hover {
            background-color: ${props => props.theme.backgroundHover};
        }
    }
`;

export const ChatHistoryContents = styled.div`
    width: calc(100% - 40px);
    height: calc(100% - 80px);
    overflow-y: auto;
    padding: 20px;

    .messageContainer {
        display: flex;
        flex-direction: column;
    }

    .message {
        margin: 5px 0;
        padding: 10px;
        border-radius: 10px;
        max-width: 80%;
    }

    .message.user {
        align-self: flex-end;
        background-color: ${props => props.theme.chatUserBackground};
        white-space: pre-wrap;
    }

    .message.bot {
        align-self: flex-start;
        background-color: ${props => props.theme.chatBotBackground};
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.backgroundHover};
        border-radius: 5px;
    }
`;
