import React, { useState, useEffect } from 'react';
import { CreateForm, InputItem, AccountInfo, ProfileInfo } from '../../../themes/Pages/OperationManagement/CreateUserFormTheme';
import { createAdminUser } from '../../../api/OperationManagement/createAdminUser';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FiEyeOff, FiEye } from 'react-icons/fi';

const CreateAdminUserForm = ({ setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {
    const navigate = useNavigate();
    const { adminMemberTypeList, accessTypeList } = useOutletContext();

    const { register, handleSubmit, clearErrors, formState: { errors, isValid }, getValues, watch, trigger, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            mail: '',
            password: '',
            confirmPassword: '',
            adminMemberTypeCode: adminMemberTypeList && adminMemberTypeList.length > 0 ? adminMemberTypeList[0].adminMemberTypeCode : '',
            accessTypeCode: accessTypeList && accessTypeList.length > 0 ? accessTypeList[0].accessTypeCode : '',
            lastName: '',
            firstName: '',
            lastNameKana: '',
            firstNameKana: ''
        }
    })


    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'password') {
                trigger('confirmPassword');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    const onSubmit = async (data) => {
        try {
            const response = await createAdminUser(navigate, data);
            if (response) {
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                setIsNotificationOpen(true);

                // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                navigate('/error', { state: { statusCode: '', errorMessage: response.message } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: error.message } });
        }
    };

    const generatePassword = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*-_';
        const allChars = letters + numbers + specialChars;

        let newPassword = '';
        // 少なくとも1文字、1数字、1記号を含むように生成
        newPassword += letters.charAt(Math.floor(Math.random() * letters.length));
        newPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
        newPassword += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

        // パスワードの残りの桁数をランダムに生成
        for (let i = 3; i < 12; i++) {
            newPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }

        // シャッフル
        newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');

        setValue('password', newPassword);
        trigger('password');
    };

    // フォームの値が変更されたときにエラーをクリアする関数
    const handleFieldChange = (fieldName) => {
        clearErrors(fieldName);
        trigger(fieldName);
    };

    // コンポーネントマウント時にフォームをリセット
    useEffect(() => {
        // フォームの値をリセット
        setValue('mail', '');
        setValue('password', '');
        setValue('confirmPassword', '');
    }, [setValue]);

    // memberTypeListとaccessTypeListが変更されたときに値を設定
    useEffect(() => {
        if (adminMemberTypeList && adminMemberTypeList.length > 0) {
            setValue('adminMemberTypeCode', adminMemberTypeList[0].adminMemberTypeCode);
        }
    }, [adminMemberTypeList, setValue]);

    useEffect(() => {
        if (accessTypeList && accessTypeList.length > 0) {
            setValue('accessTypeCode', accessTypeList[0].accessTypeCode);
        }
    }, [accessTypeList, setValue]);

    return (
        <>
            <CreateForm onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }} autoComplete="off">
                <AccountInfo>
                    <h2 className='title'>アカウント情報</h2>
                    <InputItem>
                        <label className='titleZone'>メールアドレス</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <div className='mailZone'>
                                    <input 
                                        type="text" 
                                        id="mail" 
                                        name="mail"
                                        defaultValue=""
                                        autoComplete="new-email"
                                        className={errors.mail ? 'error' : ''}
                                        {...register('mail', {
                                            required: 'メールアドレスを入力してください',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: 'メールアドレスの形式が不正です'
                                            },
                                            onChange: () => handleFieldChange('mail')
                                        })} 
                                    />
                                </div>
                                {errors.mail && <span className='errorMessage'>{errors.mail.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>アクセス区分</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select 
                                    value={watch('accessTypeCode') || (accessTypeList && accessTypeList.length > 0 ? accessTypeList[0].accessTypeCode : '')} 
                                    {...register('accessTypeCode')}
                                >
                                    {accessTypeList?.map((accessType) => (
                                        accessType.deleteFlg === '0' && (
                                            <option key={`accessType-${accessType.accessTypeCode}`} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                                        )
                                    ))}
                                </select>
                                {errors.accessTypeCode && <span className='errorMessage'>{errors.accessTypeCode.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>パスワード</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <div className='passwordInputZone'>
                                    <div className='InputArea'>
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            autoComplete="new-password"
                                            data-lpignore="true"
                                            className={`passwordInput ${errors.password ? 'error' : ''}`}
                                            placeholder='パスワードは8文字以上で入力してください'
                                            defaultValue=""
                                            {...register('password', {
                                                validate: (value) => {
                                                    if (!value || value === '') return 'パスワードを入力してください';
                                                    if (value.length < 8) return 'パスワードは8文字以上で入力してください';
                                                    if (value.length > 64) return 'パスワードは64文字以内で入力してください';
                                                    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-_])[A-Za-z\d!@#$%^&*-_]{8,}$/.test(value)) {
                                                        return 'パスワードは英字、数字、記号(!@#$%^&*-_)をそれぞれ1文字以上含む必要があります';
                                                    }
                                                    return true;
                                                },
                                                onChange: () => {
                                                    handleFieldChange('password');
                                                }
                                            })}
                                        />
                                        <button type="button" onClick={togglePasswordVisibility} className='ToggleVisibilityButton'>
                                            {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                        </button>
                                    </div>
                                    <div onClick={generatePassword} className='GeneratePasswordButton'>
                                        生成
                                    </div>
                                </div>
                                {errors.password && <span className='errorMessage'>{errors.password.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>パスワード確認</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input 
                                    type="password" 
                                    id="confirmPassword" 
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    data-lpignore="true"
                                    className={`passwordInput ${errors.confirmPassword ? 'error' : ''}`}
                                    placeholder='パスワードを再入力してください'
                                    {...register('confirmPassword', {
                                        required: 'パスワードを再入力してください',
                                        validate: (value) => value === getValues('password') || 'パスワードが一致しません'
                                    })}
                                />
                                {errors.confirmPassword && <span className='errorMessage'>{errors.confirmPassword.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>管理者会員区分</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select 
                                    value={watch('adminMemberTypeCode') || (adminMemberTypeList && adminMemberTypeList.length > 0 ? adminMemberTypeList[0].adminMemberTypeCode : '')}
                                    {...register('adminMemberTypeCode')}
                                >
                                    {adminMemberTypeList?.map((adminMemberType) => (
                                        <option key={`adminMemberType-${adminMemberType.adminMemberTypeCode}`} value={adminMemberType.adminMemberTypeCode}>{adminMemberType.adminMemberTypeName}</option>
                                    ))}
                                </select>
                                {errors.adminMemberTypeCode && <span className='errorMessage'>{errors.adminMemberTypeCode.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                </AccountInfo>
                <ProfileInfo>
                    <h2 className='title'>プロフィール情報</h2>
                    <InputItem>
                        <label className='titleZone'>氏名</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="lastName" name="lastName"
                                    defaultValue={getValues('lastName')}
                                    className={errors.lastName ? 'error' : ''}
                                    {...register('lastName', {
                                        required: '苗字を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '苗字に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('lastName')
                                    })} />
                                {errors.lastName && <span className='errorMessage'>{errors.lastName.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstName" name="firstName"
                                    defaultValue={getValues('firstName')}
                                    className={errors.firstName ? 'error' : ''}
                                    {...register('firstName', {
                                        required: '名前を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '名前に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('firstName')
                                    })} />
                                {errors.firstName && <span className='errorMessage'>{errors.firstName.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>氏名(カナ)</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="lastNameKana" name="lastNameKana"
                                    defaultValue={getValues('lastNameKana')}
                                    className={errors.lastNameKana ? 'error' : ''}
                                    {...register('lastNameKana', {
                                        required: '苗字のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('lastNameKana')
                                    })} />
                                {errors.lastNameKana && <span className='errorMessage'>{errors.lastNameKana.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstNameKana" name="firstNameKana"
                                    defaultValue={getValues('firstNameKana')}
                                    className={errors.firstNameKana ? 'error' : ''}
                                    {...register('firstNameKana', {
                                        required: '名前のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('firstNameKana')
                                    })} />
                                {errors.firstNameKana && <span className='errorMessage'>{errors.firstNameKana.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <div className='ButtonZone'>
                        <button type="submit" className='UpdateButton' disabled={!isValid}>作成</button>
                    </div>
                </ProfileInfo>

            </CreateForm >
        </>
    )
}

export default CreateAdminUserForm;