import styled from '@emotion/styled';

export const InviteCodeGenerateContainer = styled.div`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0px 20px;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
`;

export const GenerateCodeContainer = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    label {
        width: 80px;
        margin-right: 10px;
    }

    .valueInput {
        width: 10vw;
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        padding: 0 10px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .GenerateCodeButton {
        width: 100px;
        height: 40px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        padding: 0 10px;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.buttonText};
        font-size: 14px;
        cursor: pointer;

        &:hover {
            background-color: ${props => props.theme.primaryHover};
        }
    }
`;
