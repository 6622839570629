import styled from '@emotion/styled';

export const UserPageProgressDetailContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .partTitle{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 600;
        margin: 10px 20px 10px 10px;

        .partStatus{
            display: flex;
            align-items: center;
        }

        .progressRate{
            margin: 0 0 0 20px;
            font-size: 14px;
            font-weight: 400;
            color: ${props => props.theme.text};
        }
    }

    .progressBar{
        margin: 10px 10px 10px 10px;
        height: 5px;
        background-color: ${props => props.theme.backgroundHover};
        border-radius: 5px;

        .completeBar{
            height: 5px;
            background-color: ${props => props.theme.primary};
            border-radius: 5px;
        }
    }
`;

export const ChapterBox = styled.div`
    width: calc(100% - 20px);
    height: fit-content;
    border: 1px solid ${props => props.theme.border};
    border-radius: 5px;
    margin: 10px;
`;

export const ChapterIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        background-color: ${props => props.theme.backgroundHover};
    }
`;

export const ChapterTitleBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 17px;
    font-weight: 600;
`;

export const SectionBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
`;

export const SectionTitleBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SectionArticleBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const ArticleArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ArticleBox = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: ${props => 
        props.articleStatus === '完了' 
            ? props.theme.primary 
            : props.theme.backgroundHover
    };
    position: relative;
    cursor: pointer;

    .selectedArticleTitle{
        white-space: nowrap;
    }

    outline: ${props => 
        props.isSelected 
            ? `2px solid ${props.theme.text}` 
            : 'none'
    };
`;

export const ArticleTitle = styled.div`
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: -30px;
    left: 0;
    display: inline-block;
    background-color: ${props => props.theme.backgroundHover};
    padding: 5px;
    border-radius: 5px;
    color: ${props => props.theme.text};
    width: auto;
    z-index: 100;
    white-space: nowrap;
`;

export const ExerciseArea = styled.div`
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    width: fit-content;

    .selectedArticleTitle{
        font-size: 17px;
        font-weight: 600;
        margin: 0px 0px 10px -10px;
    }

    .exercise {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 3px 0;

        .exerciseBox{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            background-color: ${props => props.theme.backgroundHover};
        }
    }
`;