import React, { useState } from 'react';
import { AdminUserListDeleteCard, AdminUserListDeleteModalContainer } from '../../../themes/Pages/UserManagement/AdminUserListDeleteModalTheme';
import { FaTimes } from 'react-icons/fa';
import NotificationModal from '../../Modals/NotificationModal';
import { quitAdminUser } from '../../../api/UserManagement/adminUserList';
import { useNavigate } from 'react-router-dom';

const AdminUserListDeleteModal = ({ selectedUser, setOpenDeleteModal }) => {
    const navigate = useNavigate();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleCancel = () => {
        setOpenDeleteModal(false);
    }

    const handleConfirmTextChange = (e) => {
        const value = e.target.value;
        setConfirmText(value);
        setIsButtonEnabled(value === 'delete');
    }

    const handleDelete = async () => {
        if (confirmText !== 'delete') return;
        
        setIsProcessing(true);
        try {
            const response = await quitAdminUser(navigate, selectedUser);
            if (response) {
                if (response.success) {
                    setNotificationMessage(response.message);
                    setNotificationType('success');
                    setIsNotificationOpen(true);
                    setOpenDeleteModal(false);
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                } else {
                    setNotificationMessage(response.message);
                    setNotificationType('error');
                    setIsNotificationOpen(true);
                }
            }
        } catch (error) {
            console.error('退会処理エラー:', error);
            setNotificationMessage('退会処理中にエラーが発生しました');
            setNotificationType('error');
            setIsNotificationOpen(true);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            <AdminUserListDeleteModalContainer>
                <AdminUserListDeleteCard>
                    <div className='closeButton'>
                        <FaTimes size={20} onClick={handleCancel} cursor="pointer" />
                    </div>
                    <div className='AdminUserListDeleteModalTitle'>ユーザーを退会させますか？</div>
                    <div className='AdminUserListDeleteModalContent'>
                        <div className='AdminUserListDeleteModalContentText'>{selectedUser.userLastName}{selectedUser.userFirstName}({selectedUser.mail})のアカウントを退会させます。この操作は取り消せません。</div>
                        <div className='confirmInputContainer'>
                            <p>確認のため「delete」と入力してください</p>
                            <input 
                                type="text" 
                                value={confirmText} 
                                onChange={handleConfirmTextChange} 
                                placeholder="delete" 
                                className='confirmInput'
                            />
                        </div>
                    </div>
                    <div className='buttonContainer'>
                        <button className='cancelButton' onClick={handleCancel}>キャンセル</button>
                        <button 
                            className='deleteButton' 
                            onClick={handleDelete} 
                            disabled={!isButtonEnabled || isProcessing}
                            style={{ 
                                opacity: isButtonEnabled && !isProcessing ? 1 : 0.5, 
                                cursor: isButtonEnabled && !isProcessing ? 'pointer' : 'not-allowed' 
                            }}
                        >
                            {isProcessing ? '処理中...' : '退会'}
                        </button>
                    </div>
                </AdminUserListDeleteCard>
            </AdminUserListDeleteModalContainer>
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )
}

export default AdminUserListDeleteModal;