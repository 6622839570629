import React from 'react';
import { TotalUserCard } from '../../../themes/Pages/Home/Card_TotalUserTheme';

const TotalUser = ({ userCount }) => {
    return (
        <TotalUserCard>
            <div className="Title">
                <p>総ユーザー数</p>
            </div>
            <div className="Value">
                <p>{userCount}</p>
            </div>
        </TotalUserCard>
    );
};

export default TotalUser;
