import styled from '@emotion/styled';

export const AdminUserListSearchContainer = styled.div`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
    padding: 20px;

    .InputArea {
        width: calc(70% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 20px;

        label {
            font-size: 14px;
            margin-bottom: 5px;
        }

        input {
            height: 30px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0 10px;

            &:focus {
                outline: none;
                border: 1px solid ${props => props.theme.primary};
            }
        }
    }

    .SelectArea {
        width: calc(25% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 20px;

        label {
            font-size: 14px;
            margin-bottom: 5px;
        }

        select {
            width: 100%;
            height: 30px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0 10px;

            &:focus {
                outline: none;
                border: 1px solid ${props => props.theme.primary};
            }
        }
    }

    .ButtonArea {
        width: 5%;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;

        button {
            width: 100%;
            height: 30px;
            background-color: ${props => props.theme.primary};
            color: ${props => props.theme.buttonText};
            border: none;
            border-radius: 5px;
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                background-color: ${props => props.theme.primaryHover};
            }
        }
    }
`;