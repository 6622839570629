import UserPageProfile from '../components/Pages/UserManagement/UserPageProfile';
import UserPageProgress from '../components/Pages/UserManagement/UserPageProgress';
import UserPageQuestionHistory from '../components/Pages/UserManagement/UserPageQuestionHistory';

//検索結果表示件数の指定
export const UserDetailDisplayLimitList = [15, 50, 100];

//チャンネルリスト
export const ChannelList = [
    { 
        id: 0, 
        name: 'プロフィール', 
        userPageComponent: UserPageProfile
    },
    { 
        id: 1, 
        name: '学習進捗状況', 
        userPageComponent: UserPageProgress
    },
    { 
        id: 2, 
        name: 'AI質問履歴', 
        userPageComponent: UserPageQuestionHistory
    },
];

//テーマカラー
export const ThemeColor = {
    primary: '#47845e',
    primaryHover: '#3c6e49',
    systemBackground: '#ffffff',
    background: '#f8f8f8',
    backgroundHover: '#e6e6e6',
    modalBackground: 'rgba(0, 0, 0, 0.5)',
    chatUserBackground: '#e1e8fa',
    chatBotBackground: '#FAF3E0',
    markdownBackground: '#ebebeb',
    markdownCodeBackground: '#575757',
    text: '#4d4d4d',
    textHover: '#b2b2b2',
    buttonText: '#ffffff',
    border: '#e6e6e6',
    borderHover: '#999999',
    tags: '#ffffff',
    tagsHover: '#a2a2a2',
    disabled: '#d8d8d8',
    error: '#ff8080',
    errorHover: '#ff1a1a',
    success: '#a8e063',
    successHover: '#82c94c',
    warning: '#ff4d4d',
    warningHover: '#e0a800'
};

//都道府県リスト
export const Prefectures = [
    { name: '北海道' }, { name: '青森県' }, { name: '岩手県' }, { name: '宮城県' },
    { name: '秋田県' }, { name: '山形県' }, { name: '福島県' }, { name: '茨城県' },
    { name: '栃木県' }, { name: '群馬県' }, { name: '埼玉県' }, { name: '千葉県' },
    { name: '東京都' }, { name: '神奈川県' }, { name: '新潟県' }, { name: '富山県' },
    { name: '石川県' }, { name: '福井県' }, { name: '山梨県' }, { name: '長野県' },
    { name: '岐阜県' }, { name: '静岡県' }, { name: '愛知県' }, { name: '三重県' },
    { name: '滋賀県' }, { name: '京都府' }, { name: '大阪府' }, { name: '兵庫県' },
    { name: '奈良県' }, { name: '和歌山県' }, { name: '鳥取県' }, { name: '島根県' },
    { name: '岡山県' }, { name: '広島県' }, { name: '山口県' }, { name: '徳島県' },
    { name: '香川県' }, { name: '愛媛県' }, { name: '高知県' }, { name: '福岡県' },
    { name: '佐賀県' }, { name: '長崎県' }, { name: '熊本県' }, { name: '大分県' },
    { name: '宮崎県' }, { name: '鹿児島県' }, { name: '沖縄県' }
];