import React from 'react';
import { RecentRegistration, Header, Value } from '../../../themes/Pages/Home/Card_RecentRegistrationTheme';
import { formatDateDateOnly } from '../../../utils/DateUtils';

const RecentRegistrationCard = ({ recentUsers, listRef }) => {
    return (
        <>
            <RecentRegistration>
                <Header>
                    <div className="Title">
                        最近のユーザー登録
                    </div>
                </Header>
                <Value>
                    {recentUsers?.length > 0 ? (
                        <>
                            <div className="ListTitle">
                                <div className="UserName">
                                    ユーザー名
                                </div>
                                <div className="UserEmail">
                                    メールアドレス
                                </div>
                                <div className="UserDate">
                                    登録日
                                </div>
                            </div>
                            <ul ref={listRef} className="ScrollableList">
                                {recentUsers.map(user => (
                                    <li key={user.id} className="UserItem">
                                        <div className="UserName">
                                            {user.lastName} {user.firstName}
                                        </div>
                                        <div className="UserEmail">
                                            {user.mail}
                                        </div>
                                        <div className="UserDate">
                                            {formatDateDateOnly(user.createDate)}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <div className="NoData">
                            最近のユーザー登録はありません
                        </div>
                    )}
                </Value>
            </RecentRegistration>
        </>
    );
};
export default RecentRegistrationCard;