import React, { useEffect } from 'react';
import { NotificationModalContainer } from '../../themes/ModalThemes/NotificationModalTheme';

const NotificationModal = ({ isOpen, message, onClose, type }) => {
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(onClose, 3000);
            return () => clearTimeout(timer);
        }
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const modalClass = type === 'error' ? 'ErrorNotification' : 'SuccessNotification';

    return (
        <NotificationModalContainer modalClass={modalClass}>
            <p className='Message'>{message}</p>
            <button onClick={onClose} className='CloseButton'>×</button>
        </NotificationModalContainer>
    );
};

export default NotificationModal; 