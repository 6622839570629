import React, { useEffect, useState } from 'react';
import { selectInviteCodeHistory } from '../../../api/OperationManagement/inviteCode';
import { deleteInviteCode } from '../../../api/OperationManagement/inviteCode';
import { FaRegCopy, FaRegTrashAlt } from 'react-icons/fa';
import { InviteCodeListContainer } from '../../../themes/Pages/OperationManagement/InviteCodeListTheme';
import BulkDeleteModal from './BulkDeleteModal';

const InviteCodeList = ({ navigate, setNotificationMessage, setNotificationType, setIsNotificationOpen, memberTypeList }) => {

    const [inviteCodeHistory, setInviteCodeHistory] = useState([]);
    const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);

    const formatDateString = (dateString) => {
        const [year, month, day] = dateString.split('/');
        return new Date(year, month - 1, day, 23, 59, 59);
    };

    const isExpired = (expiryDate) => {
        const expiry = formatDateString(expiryDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return expiry < today;
    };

    const handleCopyToClipboard = (inviteCode) => {
        navigator.clipboard.writeText(inviteCode)
            .then(() => {
                setNotificationMessage('招待コードをコピーしました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
            })
            .catch(err => {
                console.error('コピーに失敗しました: ', err);
            });
    };

    const handleDeleteCode = async (inviteCode) => {
        try {
            const response = await deleteInviteCode(navigate, inviteCode);
            if (response > 0) {
                setNotificationMessage('招待コードを削除しました。');
                setNotificationType('success');
                setIsNotificationOpen(true);
                window.location.reload();
            } else {
                throw new Error('招待コードの削除に失敗しました。');
            }
        } catch (error) {
            setNotificationMessage(error.message || '招待コードの削除に失敗しました。');
            setNotificationType('error');
            setIsNotificationOpen(true);
        }
    };

    useEffect(() => {
        const getInviteCodeHistory = async () => {
            const response = await selectInviteCodeHistory(navigate);
            if (response) {
                setInviteCodeHistory(response);
            }
        };
        getInviteCodeHistory();
    }, [navigate]);

    return (
        <InviteCodeListContainer>
            <div className="inviteCodeHeader">
                <span>招待コード</span>
                <span>会員区分</span>
                <span>生成日時</span>
                <span>有効期限</span>
                <span>使用状態</span>
                <span className="actionContainer">
                    <div className="actionTitle">アクション</div>
                    <div className="actionButtonContainer" onClick={() => setOpenBulkDeleteModal(true)}>
                        <span className="ButtonText">一括</span>
                        <FaRegTrashAlt className="ButtonIcon" size={14} />
                    </div>
                </span>
            </div>
            <div className="inviteCodeContainer">
                {inviteCodeHistory?.length > 0 && inviteCodeHistory?.map((history, index) => (
                    <div key={`${index}-${history?.inviteCode}`} className="inviteCodeRow">
                        <span>{history.inviteCode}</span>
                        <span>
                            {memberTypeList?.find(memberType => memberType?.memberTypeCode === history?.memberTypeCode)?.memberTypeName}
                        </span>
                        <span>{history?.createDate}</span>
                        <span>{history?.expiryDate} 23:59:59</span>
                        <span className={`status ${history?.status === '1' ? 'usedText' :
                            isExpired(history?.expiryDate) ? 'expiredText' : 'notUsedText'}`}>
                            {history?.status === '1' ? '使用済み' :
                                isExpired(history?.expiryDate) ? '期限切れ' : '未使用'}
                        </span>
                        <span>
                            <div className="ButtonContainer">
                                <button className={`CopyButton ${history?.status === '1' || isExpired(history?.expiryDate) ? 'noUseButton' : ''}`}
                                    disabled={history?.status === '1' || isExpired(history?.expiryDate)}
                                    onClick={() => handleCopyToClipboard(history?.inviteCode)} >
                                    <FaRegCopy className="ButtonIcon" size={14} />
                                    <span className="ButtonText">コピー</span>
                                </button>
                                <button className="DeleteButton" onClick={() => handleDeleteCode(history?.inviteCode)}>
                                    <FaRegTrashAlt className="ButtonIcon" size={14} />
                                    <span className="ButtonText">削除</span>
                                </button>
                            </div>
                        </span>
                    </div>
                ))}
            </div>
            {openBulkDeleteModal && 
                <BulkDeleteModal 
                    setOpenBulkDeleteModal={setOpenBulkDeleteModal}
                    setNotificationMessage={setNotificationMessage}
                    setNotificationType={setNotificationType}
                    setIsNotificationOpen={setIsNotificationOpen}
                />}
        </InviteCodeListContainer>
    );
};

export default InviteCodeList;