import React from 'react';
import styles from './Statistics_category.module.css';

const StatisticsCategory = () => {
    return (
        <div className={styles.ChartContainer}>
            カテゴリー
        </div>
    );
};

export default StatisticsCategory;