export const fetchUserPageProfile = async (navigate, userId) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
    const userPageProfileUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_PAGE_PROFILE}?userId=${userId}`;
    try {
        const response = await fetch(userPageProfileUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });
        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'ユーザーページプロフィールの取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：ユーザーページプロフィールの取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーページプロフィールの取得に失敗しました' } });
    }
}