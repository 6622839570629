import styled from '@emotion/styled';

export const Header = styled.div`
    width: calc(100% - 20px);
    height: 60px;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
        font-size: 30px;
        font-weight: bold;
    }
        
    .information-icon {
        margin-right: 30px;
        cursor: pointer;

        &:hover {
            color: ${props => props.theme.text};
        }       
    }
`;

export const Content = styled.div`
    flex: 1;
    overflow-x: hidden;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;