import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLearningProgress } from '../../../api/UsageAnalysis/learningProgress';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { SearchForm, InputArea, ButtonArea } from '../../../themes/Pages/UsageAnalysis/LearningProgressSearchTheme';
import { fetchPartList } from '../../../api/SearchCriteria/SearchCriteria';
import { useOutletContext } from 'react-router-dom';

const LearningProgressSearch = ({ memberTypeList, setSearchResult, page, setPage, progressReverse, setIsLoading }) => {
    const { userCategoryList } = useOutletContext();
    const navigate = useNavigate();
    const [partList, setPartList] = useState([]);
    const [searchPartId, setSearchPartId] = useState('');
    const [searchDateStart, setSearchDateStart] = useState('');
    const [searchDateEnd, setSearchDateEnd] = useState('');
    const [searchMemberTypeCode, setSearchMemberTypeCode] = useState('00');
    const [searchLastLoginDateStart, setSearchLastLoginDateStart] = useState('');
    const [searchLastLoginDateEnd, setSearchLastLoginDateEnd] = useState('');
    const [searchCategoryCode, setSearchCategoryCode] = useState('00');
    const [searchStatus, setSearchStatus] = useState('all');

    const handlePartChange = (e) => {
        setSearchPartId(e.target.value);
    };

    useEffect(() => {
        fetchPartList(navigate).then((data) => {
            setPartList(data);
            const defaultPartId = data[0].partId;
            setSearchPartId(defaultPartId);
            setPage(1);
            setIsLoading(true);
            fetchLearningProgress(
                navigate,
                UserDetailDisplayLimitList[0],
                1,
                defaultPartId,
                searchDateStart,
                searchDateEnd,
                searchStatus,
                searchMemberTypeCode,
                searchCategoryCode,
                searchLastLoginDateStart,
                searchLastLoginDateEnd,
                progressReverse
            ).then((learningProgress) => {
                setSearchResult(learningProgress);
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    const handleSearch = useCallback(async (page) => {
        setPage(page);
        setIsLoading(true);
        try {
            const learningProgress = await fetchLearningProgress(
                navigate,
                UserDetailDisplayLimitList[0],
                page,
                searchPartId,
                searchDateStart,
                searchDateEnd,
                searchStatus,
                searchMemberTypeCode,
                searchCategoryCode,
                searchLastLoginDateStart,
                searchLastLoginDateEnd,
                progressReverse
            );
            setSearchResult(learningProgress);
        } catch (error) {
            console.error('Error fetching learning progress:', error);
        } finally {
            setIsLoading(false);
        }
    }, [navigate, searchPartId, searchDateStart, searchDateEnd, searchStatus, searchMemberTypeCode,
        searchCategoryCode, searchLastLoginDateStart, searchLastLoginDateEnd, progressReverse, setPage, setSearchResult, setIsLoading]);

    useEffect(() => {
        handleSearch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleSearch(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        setPage(1);
        handleSearch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressReverse]);

    const handleMemberTypeChange = (e) => {
        setSearchMemberTypeCode(e.target.value);
    };

    const handleCategoryChange = (e) => {
        setSearchCategoryCode(e.target.value);
    };

    const handleStatusChange = (e) => {
        setSearchStatus(e.target.value);
    };

    return (
        <SearchForm
            onSubmit={(e) => {
                e.preventDefault();
                handleSearch(page);
            }}
        >
            <InputArea>
                <label>利用者区分:</label>
                <select value={searchCategoryCode} onChange={handleCategoryChange}>
                    <option value='00'>全て</option>
                    {userCategoryList?.map((category) => (
                        <option key={category.categoryId} value={category.userCategoryCode}>
                            {category.userCategoryName}
                        </option>
                    ))}
                </select>
            </InputArea>
            <InputArea>
                <label>会員区分:</label>
                <select value={searchMemberTypeCode} onChange={handleMemberTypeChange}>
                    <option value='00'>全て</option>
                    {memberTypeList?.map((memberType) => (
                        <option key={memberType.memberTypeCode} value={memberType.memberTypeCode}>
                            {memberType.memberTypeName}
                        </option>
                    ))}
                </select>
            </InputArea>
            <InputArea>
                <label>ステータス</label>
                <select value={searchStatus} onChange={handleStatusChange}>
                    <option value='all'>全て</option>
                    <option value='0'>入会中・未入会</option>
                    <option value='1'>退会済</option>
                </select>
            </InputArea>
            <InputArea>
                <label>教材:</label>
                <select value={searchPartId} onChange={handlePartChange}>
                    {partList?.length === 0 ? (
                        <option value=''>Now Loading...</option>
                    ) : (
                        partList?.map((part) => (
                            <option key={part.partId} value={part.partId}>
                                {part.partTitle}
                            </option>
                        ))
                    )}
                </select>
            </InputArea>
            <InputArea>
                <label>登録日:</label>
                <input type="date" value={searchDateStart} onChange={(e) => setSearchDateStart(e.target.value)} />
                <div className="date-separator">～</div>
                <input type="date" value={searchDateEnd} onChange={(e) => setSearchDateEnd(e.target.value)} />
            </InputArea>
            <InputArea>
                <label>最終ログイン:</label>
                <input type="date" value={searchLastLoginDateStart} onChange={(e) => setSearchLastLoginDateStart(e.target.value)} />
                <div className="date-separator">～</div>
                <input type="date" value={searchLastLoginDateEnd} onChange={(e) => setSearchLastLoginDateEnd(e.target.value)} />
            </InputArea>
            <ButtonArea>
                <button type="submit">検索</button>
            </ButtonArea>
        </SearchForm>
    );
};

export default LearningProgressSearch;