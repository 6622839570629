import { Header, Content } from '../../themes/WholeDesignTheme/CommonLayoutTheme';

const CommonLayout = ({ header, content }) => {
    return (
        <>
            <Header>
                {header}
            </Header>
            <Content>
                {content}
            </Content>
        </>
    )
}

export default CommonLayout;
