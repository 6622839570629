import styled from '@emotion/styled';

export const RecentRegistration = styled.div`
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-height: 30vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.border};
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px 20px 10px 0px;
`;

export const Header = styled.div`
    margin: 0px 0px 10px 0px;

    .Title {
        font-size: 15px;
        color: ${props => props.theme.text};
    }
`;

export const Value = styled.div`

    .ListTitle {
        display: grid;
        grid-template-columns: 0.7fr 1.5fr 0.8fr;
        grid-template-rows: 1fr;
        border-bottom: 1px solid ${props => props.theme.border};
        padding: 5px 15px 5px 15px;
        width: calc(100% - 35px);

        .UserName {
            width: 100%;
            height: 100%;
        }

        .UserEmail {
            width: calc(100% - 5px);
            height: 100%;
        }

        .UserDate {
            width: 100%;
            height: 100%;
        }
    }

    .ScrollableList {
        width: 100%;
        height: 100%;
        max-height: 20vh;
        display: flex;
        flex-direction: column;
        padding: 0px;
        margin: 0px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${props => props.theme.border};
            border-radius: 5px;
        }

        .UserItem {
            display: grid;
            grid-template-columns: 0.7fr 1.5fr 0.8fr;
            grid-template-rows: 1fr;
            padding: 5px 15px 5px 15px;
            border-bottom: 1px solid ${props => props.theme.border};
            width: calc(100% - 30px);

            .UserName {
                width: 100%;
                height: 100%;
            }

            .UserEmail {
                width: 100%;
                height: 100%;

                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .UserDate {
                width: calc(100% - 5px);
                height: 100%;
            }
        }
    }

    .NoData {
        width: 100%;
        height: 100%;
    }
`;
