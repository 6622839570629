import styled from '@emotion/styled';

export const UserListEditModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const UserListEditCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.systemBackground};
    padding: 20px;
    border-radius: 10px;

    .closeButton {
        position: absolute;
        top: 10px;
        right: 10px;
    }
`;

export const UserListEditForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    height: 70vh;
    padding : 20px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.border};
        border-radius: 10px;
    }

    .ButtonZone {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 10px;

        .CancelButton {
            background-color: ${props => props.theme.disabled};
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
        }

        .UpdateButton {
            background-color: ${props => props.theme.primary};
            color: ${props => props.theme.buttonText};
            padding: 5px 10px;
            margin: 0 20px;
            border-radius: 5px;
            cursor: pointer;

            &:disabled {
                background-color: ${props => props.theme.disabled};
                cursor: not-allowed;
            }
        }
    }
`;


export const UserListInputItem = styled.div`

    padding: 0px 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    .leftZone {
        width: 25%;

        .phoneNumberNote {
            margin: 0px;
            font-size: 10px;
            color: ${props => props.theme.text};
        }
    }

    .rightZone {
        padding: 0px 10px 0px 0px;
        width: calc(75% - 10px);
        position: relative;
        display: flex;
        align-items: center;

        .inputField {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            position: relative;
        }

        .birthZone {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;

            .selectField {
                display: flex;
                align-items: center !important;
            }

            .year {
                width: calc(100% - 10px);
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin-right: 10px;
            }

            .month {
                width: calc(100% - 10px);
                margin-right: 10px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
            }

            .day {
                width: calc(100% - 10px);
                margin-right: 10px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
            }
        }

        input[type="text"] {
            width: calc(100% - 30px);
            height: 40px;
            margin-right: 10px;
            border: 1px solid ${props => props.theme.border};

            border-radius: 5px;
            padding: 0px 10px;
        }

        select {
            width: 205px;
            height: 45px;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 0px 10px;

        }

        .inputArea {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: calc(75% - 10px);

            .passwordInput {
                width: calc(100% - 20px);
                height: 40px;
                margin-right: 10px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 0px 10px;

            }

            .ToggleVisibilityButton {
                position: absolute;
                right: 20px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: ${props => props.theme.text};
            }
        }

        .schoolSuggestions {
            position: absolute;
            top: 40px;
            left: 0;
            width: 400px;
            z-index: 1000;
            background-color: ${props => props.theme.background};
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            max-height: 300px;
            overflow-y: auto;

            ${props => props.length > 0 && `
                height: fit-content;
            `}
            ${props => props.length === 0 && `
                display: none;
                padding: 0px !important;
            `}

            .schoolSuggestionsList {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            .schoolSuggestion {
                padding: 5px 10px;
                cursor: pointer;
                border-bottom: 1px solid ${props => props.theme.border};

                &:hover {
                    background-color: ${props => props.theme.backgroundHover};
                }
            }
        }

        .GeneratePasswordButton {
            width: calc(25% - 10px);
            height: 40px;
            margin: 0px 0 0 10px;
            background-color: transparent;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            cursor: pointer;
            color: ${props => props.theme.text};
            padding: 0px;

            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: all 0.3s ease;

            &:hover {
                background-color: ${props => props.theme.disabled};
                color: ${props => props.theme.buttonText};
            }
        }

        .errorMessage {
            color: red;
            font-size: 12px;
        }
    }

    input.error, select.error {
        border: 1px solid ${props => props.theme.error};
        background-color: ${props => props.theme.errorBackground};
    }

    .errorMessage {
        position: absolute;
        top: 100%;
        left: 0;
        color: ${props => props.theme.error};
        font-size: 12px;
    }

`;

