import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import CommonLayout from '../../WholeDesign/CommonLayout';
import UserListSearch from './UserListSearch';
import UserListResult from './UserListResult';
import Pagination from '../CommonDesign/Pagination';
import NotificationModal from '../../Modals/NotificationModal';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { SyncLoader } from 'react-spinners';

const UserList = () => {
    const { memberTypeList, setSelectedMenu, sideMenuPageList } = useOutletContext();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [displayLength, setDisplayLength] = useState(UserDetailDisplayLimitList[0]);
    const [lastLoginReverse, setLastLoginReverse] = useState(false);

    useEffect(() => {
        const storedNotification = localStorage.getItem('notificationMessage');
        if (storedNotification) {
            setNotificationMessage(storedNotification);
            setNotificationType('success');
            setIsNotificationOpen(true);
            localStorage.removeItem('notificationMessage'); // 使用後は削除
        }
    }, []);

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '01')?.id);
    }, [setSelectedMenu, sideMenuPageList]);

    // ページ
    const [page, setPage] = useState(1);
    // 検索結果
    const [searchResults, setSearchResults] = useState([]);

    const header = (
        <>
            <h2 className="title">会員一覧</h2>
        </>
    )

    const content = (
        <>
            <UserListSearch
                categoryList={categoryList}
                setCategoryList={setCategoryList}
                setSearchResults={setSearchResults}
                memberTypeList={memberTypeList}
                page={page}
                setPage={setPage}
                setIsLoading={setIsLoading}
                userDetailDisplayLimitList={UserDetailDisplayLimitList}
                displayLength={displayLength}
                setDisplayLength={setDisplayLength}
                lastLoginReverse={lastLoginReverse}
            />
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                    <SyncLoader color="#36d7b7" />
                </div>
            ) : (
                searchResults.searchResultLength === 0 ?
                    <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff4d4d', margin: '20px' }}>
                        該当するユーザーは見つかりませんでした。
                    </div>
                    :
                    <>
                        <UserListResult
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            memberTypeList={memberTypeList}
                            lastLoginReverse={lastLoginReverse}
                            setLastLoginReverse={setLastLoginReverse}
                        />
                        {searchResults.searchResultLength > displayLength && (
                            <Pagination
                                currentPage={page}
                                totalPages={Math.ceil(searchResults.searchResultLength / displayLength)}
                                onPageChange={setPage}
                            />
                        )}
                    </>
            )}
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                onClose={() => setIsNotificationOpen(false)}
                type={notificationType}
            />
        </>
    )

    return (
        <>
            <CommonLayout
                header={header}
                content={content}
            />
        </>
    );
};

export default UserList;