import React from 'react';
import { BulkDeleteModalContainer } from '../../../themes/Pages/OperationManagement/BulkDeleteModalTheme';
import { BulkDeleteInviteCode } from '../../../api/OperationManagement/inviteCode';

const BulkDeleteModal = ({ setOpenBulkDeleteModal, setNotificationMessage, setNotificationType, setIsNotificationOpen, navigate }) => {

    const handleDelete = async () => {
        try {
            const response = await BulkDeleteInviteCode(navigate);

            if (response.success) {
                setOpenBulkDeleteModal(false);
                setNotificationMessage(response.message);
                setNotificationType('success');
                setIsNotificationOpen(true);
                localStorage.setItem('inviteCodeHistory', JSON.stringify(response.message));
                window.location.reload();
            } else {
                setOpenBulkDeleteModal(false);
                setNotificationMessage(response.message);
                setNotificationType('error');
                setIsNotificationOpen(true);
            }
        } catch (error) {
            console.error('APIエラー：使用済み、期限切れの招待コードの削除', error);
            navigate('/error', { state: { statusCode: '', errorMessage: '使用済み、期限切れの招待コードの削除に失敗しました' } });
        }
    }

    return (
        <BulkDeleteModalContainer>
            <div className='ModalCard'>
                <div className='BulkDeleteModalTitle'>
                    <div className='BulkDeleteModalTitleText'>
                        期限切れ、使用済みの招待コードを一括削除します。
                    </div>
                </div>
                <div className='BulkDeleteModalButtonContainer'>
                    <button className='BulkDeleteModalCancelButton' onClick={() => setOpenBulkDeleteModal(false)}>キャンセル</button>
                    <button className='BulkDeleteModalDeleteButton' onClick={handleDelete}>削除</button>
                </div>
            </div>
        </BulkDeleteModalContainer>
    );
};

export default BulkDeleteModal;
