import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { UserListEditModalContainer, UserListEditCard, UserListInputItem, UserListEditForm } from '../../../themes/Pages/UserManagement/UserListEditModalTheme';
import { updateAdminUser } from '../../../api/UserManagement/adminUserList';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const AdminUserListEditModal = ({ selectedUser, setSelectedUser, setOpenEditor, setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {

    const navigate = useNavigate();
    const { adminMemberTypeList, accessTypeList } = useOutletContext();
    const { register, handleSubmit, formState: { errors, isValid }, trigger, setValue, clearErrors, watch, reset } = useFormContext({
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [passwordGenerated, setPasswordGenerated] = useState(false);

    // Update form values when selectedUser changes
    useEffect(() => {
        // Set form values using setValue
        setValue('id', selectedUser.id);
        setValue('adminUserLastName', selectedUser.adminUserLastName);
        setValue('adminUserFirstName', selectedUser.adminUserFirstName);
        setValue('adminUserLastNameKana', selectedUser.adminUserLastNameKana);
        setValue('adminUserFirstNameKana', selectedUser.adminUserFirstNameKana);
        setValue('mail', selectedUser.mail);
        setValue('password', '');
        setValue('adminMemberTypeCode', selectedUser.adminMemberTypeCode);
        setValue('accessTypeCode', selectedUser.accessTypeCode);

        setPasswordGenerated(false);

        // Trigger validation
        trigger();
    }, [selectedUser, setValue, trigger]);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    const handleCancel = () => {
        setOpenEditor(false);
        setSelectedUser(null);
        setValue('password', '');
        setPasswordGenerated(false);
        setIsPasswordVisible(false);
        reset();
    }
    //ランダムパスワード生成
    const generatePassword = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*-_';
        const allChars = letters + numbers + specialChars;
        let newPassword = '';
        // 少なくとも1文字、1数字、1記号を含むように生成
        newPassword += letters.charAt(Math.floor(Math.random() * letters.length));
        newPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
        newPassword += specialChars.charAt(Math.floor(Math.random() * specialChars.length));
        // パスワードの残りの桁数をランダムに生成
        for (let i = 3; i < 12; i++) {
            newPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }
        // シャッフル
        newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');
        setValue('password', newPassword);
        setPasswordGenerated(true);
        setIsPasswordVisible(true);
    }

    const handleIndividSubmit = async (data) => {
        // 変更検知のためのデータ比較
        const isDataChanged =
            data.id === selectedUser.id &&
            data.adminUserLastName === selectedUser.adminUserLastName &&
            data.adminUserFirstName === selectedUser.adminUserFirstName &&
            data.adminUserLastNameKana === selectedUser.adminUserLastNameKana &&
            data.adminUserFirstNameKana === selectedUser.adminUserFirstNameKana &&
            data.mail === selectedUser.mail &&
            data.adminMemberTypeCode === selectedUser.adminMemberTypeCode &&
            data.accessTypeCode === selectedUser.accessTypeCode &&
            data.password === '';

        if (isDataChanged) {
            setNotificationMessage('変更がありません');
            setNotificationType('error');
            setIsNotificationOpen(true);
            return;
        }

        const userData = {
            id: data.id,
            adminUserLastName: data.adminUserLastName,
            adminUserFirstName: data.adminUserFirstName,
            adminUserLastNameKana: data.adminUserLastNameKana,
            adminUserFirstNameKana: data.adminUserFirstNameKana,
            mail: data.mail,
            adminMemberTypeCode: data.adminMemberTypeCode,
            accessTypeCode: data.accessTypeCode,
            password: data.password
        };

        //API呼び出し
        const response = await updateAdminUser(navigate, userData);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            //成功した場合はローカルストレージに通知メッセージを保存し、リロード
            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    }

    useEffect(() => {
        // 初期値のバリデーションを実行
        trigger();
    }, [trigger]);

    // フォームの値が変更されたときにエラーをクリアする関数
    const handleFieldChange = (fieldName) => {
        clearErrors(fieldName);
        trigger(fieldName);
    };

    // パスワード入力フィールドの変更ハンドラを追加
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setValue('password', newPassword);
        if (newPassword === '') {
            setPasswordGenerated(false);
            setIsPasswordVisible(false);
        }
        clearErrors('password');
        trigger('password');
    }

    return (
        <UserListEditModalContainer>
            <UserListEditCard>
                <div className='closeButton'>
                    <FaTimes size={20} onClick={handleCancel} cursor="pointer" />
                </div>
                <UserListEditForm onSubmit={handleSubmit(handleIndividSubmit)} onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}>
                    <UserListInputItem>
                        <input type="hidden" name="id" value={selectedUser.id} {...register('id')} />
                        <label className='leftZone'>氏名</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="adminUserLastName" name="adminUserLastName"
                                    defaultValue={selectedUser.adminUserLastName}
                                    className={errors.adminUserLastName ? 'error' : ''}
                                    {...register('adminUserLastName', {
                                        required: '苗字を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '苗字に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('adminUserLastName')
                                    })} />
                                {errors.adminUserLastName && <span className='errorMessage'>{errors.adminUserLastName.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="adminUserFirstName" name="adminUserFirstName"
                                    defaultValue={selectedUser.adminUserFirstName}
                                    className={errors.adminUserFirstName ? 'error' : ''}
                                    {...register('adminUserFirstName', {
                                        required: '名前を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '名前に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('adminUserFirstName')
                                    })} />
                                {errors.adminUserFirstName && <span className='errorMessage'>{errors.adminUserFirstName.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>氏名(カナ)</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="adminUserLastNameKana" name="adminUserLastNameKana"
                                    defaultValue={selectedUser.adminUserLastNameKana}
                                    className={errors.adminUserLastNameKana ? 'error' : ''}
                                    {...register('adminUserLastNameKana', {
                                        required: '苗字のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('adminUserLastNameKana')
                                    })} />
                                {errors.adminUserLastNameKana && <span className='errorMessage'>{errors.adminUserLastNameKana.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="adminUserFirstNameKana" name="adminUserFirstNameKana"
                                    defaultValue={selectedUser.adminUserFirstNameKana}
                                    className={errors.adminUserFirstNameKana ? 'error' : ''}
                                    {...register('adminUserFirstNameKana', {
                                        required: '名前のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('adminUserFirstNameKana')
                                    })} />
                                {errors.adminUserFirstNameKana && <span className='errorMessage'>{errors.adminUserFirstNameKana.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>メールアドレス</label>
                        <div className='rightZone'>
                            <div className='inputField'>
                                <input type="text" id="mail" name="mail"
                                    defaultValue={selectedUser.mail}
                                    className={errors.mail ? 'error' : ''}
                                    {...register('mail', {
                                        required: 'メールアドレスを入力してください',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'メールアドレスの形式が不正です'
                                        },
                                        onChange: () => handleFieldChange('mail')
                                    })} />
                                {errors.mail && <span className='errorMessage'>{errors.mail.message}</span>}
                            </div>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>会員区分</label>
                        <div className='rightZone'>
                            <select value={watch('adminMemberTypeCode')} {...register('adminMemberTypeCode')}>
                                {adminMemberTypeList?.map((memberType) => (
                                    <option key={`memberType-${memberType.adminMemberTypeCode}`} value={memberType.adminMemberTypeCode}>{memberType.adminMemberTypeName}</option>
                                ))}
                            </select>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>アクセス区分</label>
                        <div className='rightZone'>
                            <select value={watch('accessTypeCode')} {...register('accessTypeCode')}>
                                {accessTypeList?.map((accessType) => (
                                    <option key={`accessType-${accessType.accessTypeCode}`} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                                ))}
                            </select>
                        </div>
                    </UserListInputItem>
                    <UserListInputItem>
                        <label className='leftZone'>パスワードリセット</label>
                        <div className='rightZone'>
                            <div className='inputArea'>
                                <input
                                    type={isPasswordVisible && passwordGenerated ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className={`passwordInput ${errors.password ? 'error' : ''}`}
                                    placeholder='パスワードは8文字以上で入力してください'
                                    defaultValue=""
                                    {...register('password', {
                                        validate: (value) => {
                                            if (!value || value === '') return true; // パスワードは必須ではない
                                            if (value.length < 8) return 'パスワードは8文字以上で入力してください';
                                            if (value.length > 64) return 'パスワードは64文字以内で入力してください';
                                            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-_])[A-Za-z\d!@#$%^&*-_]{8,}$/.test(value)) {
                                                return 'パスワードは英字、数字、記号(!@#$%^&*-_)をそれぞれ1文字以上含む必要があります';
                                            }
                                            return true;
                                        },
                                        onChange: (e) => {
                                            handlePasswordChange(e);
                                        }
                                    })}
                                />
                                <button type="button" onClick={togglePasswordVisibility} className='ToggleVisibilityButton'>
                                    {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                </button>
                                {errors.password && <span className='errorMessage'>{errors.password.message}</span>}
                            </div>
                            <div onClick={generatePassword} className='GeneratePasswordButton'>
                                自動生成
                            </div>
                        </div>
                    </UserListInputItem>
                    <div className='ButtonZone'>
                        <div className='CancelButton' onClick={handleCancel}>キャンセル</div>
                        <button type="submit" className='UpdateButton' disabled={!isValid}>更新</button>
                    </div>
                </UserListEditForm>

            </UserListEditCard>
        </UserListEditModalContainer>
    )
}

export default AdminUserListEditModal;