import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkDirective from 'remark-directive';
import { ChatResponseMarkdownCodeBlock, ChatResponseMarkdownCodeHeader, ChatResponseMarkdownCodeSpan } from '../../themes/MarkdownTheme/ChatResponseMarkdown';

const components = {
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <ChatResponseMarkdownCodeBlock>
                {match[1] &&
                    <ChatResponseMarkdownCodeHeader>
                        {match[1] === 'js' ? 'javascript' : match[1]}
                    </ChatResponseMarkdownCodeHeader>
                }
                <SyntaxHighlighter
                    style={atomDark}
                    customStyle={{
                        fontSize: '14px',
                        margin: '0',
                        ...(match[1] && { borderRadius: '0 0 0.3em 0.3em' })
                    }}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                >
                    {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
            </ChatResponseMarkdownCodeBlock>
        ) : (
            <ChatResponseMarkdownCodeSpan {...props} >
                {children}
            </ChatResponseMarkdownCodeSpan>
        );
    },
};

const ChatResponseMarkdown = ({ children }) => {
    return (
        <ReactMarkdown
            components={components}
            remarkPlugins={[remarkGfm, remarkDirective]}
        >
            {children}
        </ReactMarkdown>
    );
};

export default ChatResponseMarkdown;