import styled from '@emotion/styled';

export const AccessCategoryRegistForm = styled.form`
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding: 30px 20px 30px 20px;
    background-color: ${props => props.theme.background};
    border-radius: 5px;
    align-items: center;
`;

export const InputArea = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .CategoryInputArea {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .Category {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            label {
                width: 180px;
                margin-right: 10px;
            }

            .categoryInput {
                width: calc(100% - 180px);    
                height: 30px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 0 10px;
            }
        }

        .errorMessage {
            position: absolute;
            top: 100%;
            left: 140px;
            color: ${props => props.theme.error};
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .CategoryNameInputArea {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .CategoryName {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            label {
                width: 140px;
                margin-right: 10px;
            }

            .categoryNameInput {
                width: calc(100% - 140px);    
                height: 30px;
                border: 1px solid ${props => props.theme.border};
                border-radius: 5px;
                padding: 0 10px;
            }
        }

        .errorMessage {
            position: absolute;
            top: 100%;
            left: 140px;
            color: ${props => props.theme.error};
            font-size: 12px;
            margin-top: 5px;
        }
    }
`;

export const ButtonWrapper = styled.div`
    width: 15%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .registerButton {
        width: calc(100% - 40px);
        height: 30px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
        padding: 0 10px;
        margin: 0 10px;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.systemBackground};
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: ${props => props.theme.primaryHover};
        }
    }
`;
