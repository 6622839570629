export const fetchDashboardData = async (navigate, userIncRateCategory) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const userCountUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_COUNT}`;
        const userIncRateUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_INC_RATE}?category=${userIncRateCategory}`;
        const activeUserUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_ACTIVE_USER_COUNT}`;
        const userRegistrationStatusUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_USER_REGISTRATION_STATUS}`;
        const recentAiQuestionsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_RECENT_AI_QUESTIONS}`;
        const totalAiQuestionsUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_TOTAL_AI_QUESTIONS}`;

        const [userCountResponse, userIncRateResponse, activeUserResponse, userRegistrationStatusResponse, recentAiQuestionsResponse, totalAiQuestionsResponse] = await Promise.all([
            fetch(userCountUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(userIncRateUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    category: userIncRateCategory
                }),
                credentials: 'include'
            }),
            fetch(activeUserUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(userRegistrationStatusUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(recentAiQuestionsUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            }),
            fetch(totalAiQuestionsUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            })
        ]);

        if (userCountResponse.ok && userIncRateResponse.ok && activeUserResponse.ok && userRegistrationStatusResponse.ok && recentAiQuestionsResponse.ok && totalAiQuestionsResponse.ok) {
            const userCountData = await userCountResponse.json();
            const userIncRateData = await userIncRateResponse.json();
            const activeUserData = await activeUserResponse.json();
            const userRegistrationStatusData = await userRegistrationStatusResponse.json();
            const recentAiQuestionsData = await recentAiQuestionsResponse.json();
            const totalAiQuestionsData = await totalAiQuestionsResponse.json();

            return {
                success: true,
                userCount: userCountData,
                userIncRate: userIncRateData === 0 ? -9999 : parseFloat(((userCountData - userIncRateData) / userIncRateData) * 100).toFixed(1),
                activeUser: activeUserData,
                recentUsers: userRegistrationStatusData,
                recentQuestions: recentAiQuestionsData,
                totalQuestions: totalAiQuestionsData
            };
        } else {
            const errorMessages = [];
            if (!userCountResponse.ok) {
                if(userCountResponse.status === 401){
                    navigate('/login');
                }else{
                    errorMessages.push(`User Count Error: ${userCountResponse.statusText}`);
                }
            }
            if (!userIncRateResponse.ok) {
                if(userIncRateResponse.status === 401){
                    navigate('/login');
                }else{
                    const userIncRateError = await userIncRateResponse.json();
                    errorMessages.push(`User Inc Rate Error: ${userIncRateError.error}`);
                }
            }
            if (!activeUserResponse.ok) {
                if(activeUserResponse.status === 401){
                    navigate('/login');
                }else{
                    const activeUserError = await activeUserResponse.json();
                    errorMessages.push(`Active User Error: ${activeUserError.error}`);
                }
            }
            if (!userRegistrationStatusResponse.ok) {
                if(userRegistrationStatusResponse.status === 401){
                    navigate('/login');
                }else{
                    const userRegistrationStatusError = await userRegistrationStatusResponse.json();
                    errorMessages.push(`User Registration Status Error: ${userRegistrationStatusError.error}`);
                }
            }
            return { success: false, message: errorMessages.join('; ') };
        }
    } catch (error) {
        console.error('APIエラー:', error);
        navigate('/error',{state:{statusCode:500,errorMessage:'ダッシュボードデータ取得に失敗しました'}});
    }
};