import { React, useState, useEffect } from 'react';
import { issueToken } from '../../../api/Login/authentication';
import TopHeader from './TopHeader';
import { useNavigate } from 'react-router-dom';
import { LoginContainer } from '../../../themes/Pages/Login/LoginTheme';
import NotificationModal from '../../Modals/NotificationModal';

function Login() {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('isLogout')) {
            setNotificationMessage('ログアウトしました。');
            setNotificationType('success');
            setIsNotificationOpen(true);
            localStorage.removeItem('isLogout');
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const isAuthenticated = await issueToken(mail, password, navigate);
            if (isAuthenticated.success) {
                localStorage.setItem('token', isAuthenticated.token)
                navigate('/adminPanel');
            } else {
                setErrorMessage(isAuthenticated.message);
            }
        } catch (error) {
            setErrorMessage('システムエラーが発生しました。システム管理者にお問い合わせください。');
            console.error('Login.error:', error);
        }
    };

    return (
        <LoginContainer>
            <TopHeader />
            <div className='loginModal'>
                <div className='loginContent'>
                    <div className='loginHeader'>
                        <h2>ログイン</h2>
                        <span>アカウントにログインしてください</span>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='formGroup'>
                            <span>メールアドレス</span>
                            <input
                                type="email"
                                id="mail"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                                placeholder="EMAIL"
                                required
                            />
                        </div>
                        <div className='formGroup'>
                            <span>パスワード</span>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="PASSWORD"
                                required
                            />
                        </div>
                        <div className='errorContainer'>
                            {errorMessage && <p className='errorMessage'>{errorMessage}</p>}
                        </div>
                        <button type="submit" className='loginButton'>ログイン</button>
                    </form>
                    <div className='loginLinks'>
                        <span>パスワードをお忘れですか？</span>
                    </div>
                </div>
            </div>
            <NotificationModal
                isOpen={isNotificationOpen}
                message={notificationMessage}
                type={notificationType}
                onClose={() => setIsNotificationOpen(false)}
            />
        </LoginContainer>
    );
};

export default Login;