import React from 'react';
import { NewsCard } from '../../../themes/Pages/Home/Card_NewsTheme';
const News = () => {
    return (
        <NewsCard>
            <p className="Title">お知らせ</p>
            <div className="Content">
                <div className="News">ここにニュースが入る</div>
                <div className="News">ここにニュースが入る</div>
            </div>
        </NewsCard>
    );
};

export default News;