import CommonLayout from '../../WholeDesign/CommonLayout';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { UserPageContent, ChannelInfo, ChannelContent } from '../../../themes/Pages/UserManagement/UserPageTheme';
import { ChannelList } from '../../../utils/Constants';
import React from 'react';

const UserPage = () => {
    const { userId } = useParams();
    const [selectedChannel, setSelectedChannel] = useState(0);
    const [userName, setUserName] = useState('');

    const header = (
        <>
            <h2 className="title">{userName}さんの詳細情報</h2>
        </>
    );
    const content = (
        <>
            <UserPageContent>
                <ChannelInfo>
                    {ChannelList.map((channel) => (
                        <div className={`channelItem ${channel.id === selectedChannel ? 'selected' : ''}`} key={channel.id} onClick={() => setSelectedChannel(channel.id)}>{channel.name}</div>
                    ))}
                </ChannelInfo>
                <ChannelContent>
                    {React.createElement(ChannelList[selectedChannel].userPageComponent, { 
                        userId: userId, 
                        setUserName: selectedChannel === 0 ? setUserName : () => {} 
                    })}
                </ChannelContent>
            </UserPageContent>
        </>
    );

    return (
        <>
            <CommonLayout
                header={header}
                content={content}
            />
        </>
    );
};

export default UserPage;