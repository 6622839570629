import styled from '@emotion/styled';

export const QuestionHistoryResultContainer = styled.div`
    width: calc(100% - 20px);
    height: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: height 0.3s ease-in-out;
    margin: 0px;

    ${props => props.searchResults?.length > 0 && `
        height: fit-content;
        margin: 10px;
    `}
`;

export const ChatRoomHeader = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 1fr 1.8fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr;
    background-color: ${props => props.theme.background};
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.text};

    .roomItem{
        margin: 0px 5px 0px 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .ItemSort{
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: 1px solid ${props => props.theme.border};

        &:hover{
            background-color: ${props => props.theme.backgroundHover};
        }
    }
`;

export const RoomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .room{
        width: calc(100% - 20px);
        display: grid;
        grid-template-columns: 0.6fr 1fr 1.8fr 1fr 0.8fr 0.8fr 0.8fr 0.8fr;
        border-bottom: 1px solid ${props => props.theme.border};
        padding: 15px 10px;
        align-items: center;

        .questionContent{
            cursor: pointer;
            border: 1px solid ${props => props.theme.border};
            border-radius: 5px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            width: fit-content;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            background-color: ${props => props.theme.background};
            box-shadow: 3px 2px 5px 0px rgba(0, 0, 0, 0.1);

            &:hover{
                background-color: ${props => props.theme.backgroundHover};
            }
        }

        .roomItem{
            margin: 0px 5px 0px 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;

            .textContent{
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                color: ${props => props.theme.text};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }
    }
`;

