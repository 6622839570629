export const fetchLearningProgress = async (navigate, limit, page, searchPartId, searchDateStart, searchDateEnd, searchStatus, searchMemberTypeCode, searchCategoryCode, searchLastLoginDateStart, searchLastLoginDateEnd, progressReverse) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
    const learningProgressUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_LEARNING_PROGRESS}`;
    try {
        const requestBody = {};
        if (limit) requestBody.limit = limit;
        if (page) requestBody.page = page;
        if (searchPartId) requestBody.searchPartId = searchPartId;
        if (searchDateStart) requestBody.searchDateStart = searchDateStart;
        if (searchDateEnd) requestBody.searchDateEnd = searchDateEnd;
        if (searchStatus) requestBody.searchStatus = searchStatus;
        if (searchMemberTypeCode) requestBody.searchMemberTypeCode = searchMemberTypeCode;
        if (searchCategoryCode) requestBody.searchCategoryCode = searchCategoryCode;
        if (searchLastLoginDateStart) requestBody.searchLastLoginDateStart = searchLastLoginDateStart;
        if (searchLastLoginDateEnd) requestBody.searchLastLoginDateEnd = searchLastLoginDateEnd;
        if (progressReverse) requestBody.progressReverse = progressReverse;

        const response = await fetch(learningProgressUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestBody),
            credentials: 'include',
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '学習進捗の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：学習進捗の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '学習進捗の取得に失敗しました' } });
    }
}