import React, { useState, useEffect, useCallback } from 'react';
import { CreateForm, InputItem, AccountInfo, ProfileInfo } from '../../../themes/Pages/OperationManagement/CreateUserFormTheme';
import { createUser } from '../../../api/OperationManagement/createUser';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Prefectures } from '../../../utils/Constants';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { useMemo } from 'react';

const CreateUserForm = ({ setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, clearErrors, formState: { errors, isValid }, getValues, watch, trigger, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            mail: '',
            password: 'testPassword@1',
            confirmPassword: 'testPassword@1',
            memberTypeCode: '04',
            accessTypeCode: '01',
            categoryCode: '01',
            lastName: 'テスト',
            firstName: '太郎',
            lastNameKana: 'テスト',
            firstNameKana: 'タロウ',
            nickname: 'テストアカウント',
            gender: 'M',
            birthdate: new Date(1998, 0, 1),
            prefecture: '東京都',
            phoneNumber: '03123456789',
        }
    })

    const { memberTypeList, userCategoryList, accessTypeList } = useOutletContext();

    const [isPasswordVisible, setIsPasswordVisible] = useState(true);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'password') {
                trigger('confirmPassword');
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger]);

    useEffect(() => {
        trigger();
    }, [trigger]);

    const onSubmit = async (data) => {
        try {
            data.mail = data.mail + '@example.com';
            const response = await createUser(navigate, data);
            if (response) {
                setNotificationMessage(response.message);
                setNotificationType(response.success ? 'success' : 'error');
                setIsNotificationOpen(true);

                // 成功した場合はローカルストレージに通知メッセージを保存し、リロード
                if (response.success) {
                    localStorage.setItem('notificationMessage', response.message);
                    window.location.reload();
                }
            } else {
                navigate('/error', { state: { statusCode: '', errorMessage: response.message } });
            }
        } catch (error) {
            navigate('/error', { state: { statusCode: '', errorMessage: error.message } });
        }
    };

    const generatePassword = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specialChars = '!@#$%^&*-_';
        const allChars = letters + numbers + specialChars;

        let newPassword = '';
        // 少なくとも1文字、1数字、1記号を含むように生成
        newPassword += letters.charAt(Math.floor(Math.random() * letters.length));
        newPassword += numbers.charAt(Math.floor(Math.random() * numbers.length));
        newPassword += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

        // パスワードの残りの桁数をランダムに生成
        for (let i = 3; i < 12; i++) {
            newPassword += allChars.charAt(Math.floor(Math.random() * allChars.length));
        }

        // シャッフル
        newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');

        setValue('password', newPassword);
        trigger('password');
    };

    const years = useMemo(() => {
        return Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i).reverse();
    }, []);
    const months = useMemo(() => {
        return Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
    }, []);
    const [days, setDays] = useState(Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0')));

    const [selectedYear, setSelectedYear] = useState(() => {
        const date = new Date(getValues('birthdate'));
        return date.getFullYear().toString();
    });
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const date = new Date(getValues('birthdate'));
        return String(date.getMonth() + 1).padStart(2, '0');
    });
    const [selectedDay, setSelectedDay] = useState(() => {
        const date = new Date(getValues('birthdate'));
        return String(date.getDate()).padStart(2, '0');
    });

    const handleDayChange = useCallback((day) => {
        setSelectedDay(day);
    }, []);

    useEffect(() => {
        if (selectedYear && selectedMonth && selectedDay) {
            const maxDays = new Date(selectedYear, parseInt(selectedMonth), 0).getDate();
            setDays(Array.from({ length: maxDays }, (_, i) => String(i + 1).padStart(2, '0')));
            // 現在選択されている日が不正な場合、最初の日に設定
            if (parseInt(selectedDay) > maxDays) {
                handleDayChange('01');
            } else {
                const formattedDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;
                setValue('birthdate', formattedDate);
                trigger('birthdate');
            }
        }
    }, [selectedYear, selectedMonth, selectedDay, handleDayChange, setValue, trigger]);

    const changeYear = (e) => {
        setSelectedYear(e.target.value);
    }

    const changeMonth = (e) => {
        setSelectedMonth(e.target.value);
    }

    const changeDay = (e) => {
        setSelectedDay(e.target.value);
    }

    // フォームの値が変更されたときにエラーをクリアする関数
    const handleFieldChange = (fieldName) => {
        clearErrors(fieldName);
        trigger(fieldName);
    };

    return (
        <>
            <CreateForm onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            }}>
                <AccountInfo>
                    <h2 className='title'>アカウント情報</h2>
                    <InputItem>
                        <label className='titleZone'>メールアドレス</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <div className='mailZone'>
                                    <input type="text" id="mail" name="mail"
                                        defaultValue={getValues('mail')}
                                        autoComplete="off"
                                        className={errors.mail ? 'error' : ''}
                                        {...register('mail', {
                                            required: 'メールアドレスを入力してください',
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+$/,
                                                message: 'メールアドレスの形式が不正です'
                                            },
                                            onChange: () => handleFieldChange('mail')
                                        })} />
                                    <div className='domain'>@example.com</div>
                                </div>
                                {errors.mail && <span className='errorMessage'>{errors.mail.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>利用者区分</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select
                                    value={watch('categoryCode')}   
                                    {...register('categoryCode')}
                                >
                                    {userCategoryList?.map((category) => (
                                        <option key={`category-${category.userCategoryCode}`} value={category.userCategoryCode}>{category.userCategoryName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>アクセス区分</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select value={watch('accessTypeCode')} {...register('accessTypeCode')}>
                                    {accessTypeList?.map((accessType) => (
                                        accessType.deleteFlg === '0' && (
                                            <option key={`accessType-${accessType.accessTypeCode}`} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                                        )
                                    ))}
                                </select>
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>パスワード</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <div className='passwordInputZone'>
                                    <div className='InputArea'>
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            autoComplete="new-password"
                                            className={`passwordInput ${errors.password ? 'error' : ''}`}
                                            placeholder='パスワードは8文字以上で入力してください'
                                            defaultValue={getValues('password')}
                                            {...register('password', {
                                                validate: (value) => {
                                                    if (!value || value === '') return 'パスワードを入力してください';
                                                    if (value.length < 8) return 'パスワードは8文字以上で入力してください';
                                                    if (value.length > 64) return 'パスワードは64文字以内で入力してください';
                                                    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*-_])[A-Za-z\d!@#$%^&*-_]{8,}$/.test(value)) {
                                                        return 'パスワードは英字、数字、記号(!@#$%^&*-_)をそれぞれ1文字以上含む必要があります';
                                                    }
                                                    return true;
                                                },
                                                onChange: () => {
                                                    handleFieldChange('password');
                                                }
                                            })}
                                        />
                                        <button type="button" onClick={togglePasswordVisibility} className='ToggleVisibilityButton'>
                                            {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                        </button>
                                    </div>
                                    <div onClick={generatePassword} className='GeneratePasswordButton'>
                                        生成
                                    </div>
                                </div>
                                {errors.password && <span className='errorMessage'>{errors.password.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>パスワード確認</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="password" id="confirmPassword" name="confirmPassword"
                                    className={`passwordInput ${errors.confirmPassword ? 'error' : ''}`}
                                    placeholder='パスワードを再入力してください'
                                    {...register('confirmPassword', {
                                        required: 'パスワードを再入力してください',
                                        validate: (value) => value === getValues('password') || 'パスワードが一致しません'
                                    })}
                                />
                                {errors.confirmPassword && <span className='errorMessage'>{errors.confirmPassword.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>会員区分</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select 
                                    value={watch('memberTypeCode')}
                                    {...register('memberTypeCode')}
                                >
                                    {memberTypeList?.map((memberType) => (
                                        <option key={`memberType-${memberType.memberTypeCode}`} value={memberType.memberTypeCode}>{memberType.memberTypeName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </InputItem>
                </AccountInfo>
                <ProfileInfo>
                    <h2 className='title'>プロフィール情報</h2>
                    <InputItem>
                        <label className='titleZone'>氏名</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="lastName" name="lastName"
                                    defaultValue={getValues('lastName')}
                                    className={errors.lastName ? 'error' : ''}
                                    {...register('lastName', {
                                        required: '苗字を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '苗字に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('lastName')
                                    })} />
                                {errors.lastName && <span className='errorMessage'>{errors.lastName.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstName" name="firstName"
                                    defaultValue={getValues('firstName')}
                                    className={errors.firstName ? 'error' : ''}
                                    {...register('firstName', {
                                        required: '名前を入力してください',
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: '名前に全角文字を使用してください'
                                        },
                                        onChange: () => handleFieldChange('firstName')
                                    })} />
                                {errors.firstName && <span className='errorMessage'>{errors.firstName.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>氏名(カナ)</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="lastNameKana" name="lastNameKana"
                                    defaultValue={getValues('lastNameKana')}
                                    className={errors.lastNameKana ? 'error' : ''}
                                    {...register('lastNameKana', {
                                        required: '苗字のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('lastNameKana')
                                    })} />
                                {errors.lastNameKana && <span className='errorMessage'>{errors.lastNameKana.message}</span>}
                            </div>
                            <div className='inputField'>
                                <input type="text" id="firstNameKana" name="firstNameKana"
                                    defaultValue={getValues('firstNameKana')}
                                    className={errors.firstNameKana ? 'error' : ''}
                                    {...register('firstNameKana', {
                                        required: '名前のカナを入力してください',
                                        pattern: {
                                            value: /^[\u30A0-\u30FF]+$/,
                                            message: 'カナは全角カナで入力してください'
                                        },
                                        onChange: () => handleFieldChange('firstNameKana')
                                    })} />
                                {errors.firstNameKana && <span className='errorMessage'>{errors.firstNameKana.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>ニックネーム</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="nickName" name="nickName"
                                    defaultValue={getValues('nickname')}
                                    className={`${errors.nickName ? 'error' : ''}`}
                                    {...register('nickName', {
                                        maxLength: {
                                            value: 20,
                                            message: 'ニックネームは20文字以内で入力してください'
                                        },
                                        pattern: {
                                            value: /^[\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
                                            message: 'ニックネームは全角文字で入力してください'
                                        },
                                        onChange: () => handleFieldChange('nickName')
                                    })} />
                                {errors.nickName && <span className='errorMessage'>{errors.nickName.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>
                            <div className='phoneNumberZone'>
                                <div className='phoneNumberLabel'>電話番号</div>
                                <p className='phoneNumberNote'>　※半角数字のみ入力可能</p>
                                <p className='phoneNumberNote'>　※ハイフンは不要です</p>
                            </div>
                        </label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <input type="text" id="phoneNumber" name="phoneNumber"
                                    defaultValue={getValues('phoneNumber')}
                                    className={`phoneNumberInput ${errors.phoneNumber ? 'error' : ''}`}
                                    {...register('phoneNumber', {
                                        maxLength: {
                                            value: 15,
                                            message: '電話番号は15桁以内で入力してください'
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: '電話番号は数字で入力してください'
                                        },
                                        onChange: () => handleFieldChange('phoneNumber')
                                    })} />
                                {errors.phoneNumber && <span className='errorMessage'>{errors.phoneNumber.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>性別</label>
                        <div className='contentZone'>
                            <input type="radio" value="M" id="genderM" name="gender"
                                {...register('gender')}
                                defaultChecked={getValues('gender') === 'M'}
                            />男性
                            <input type="radio" value="F" id="genderF" name="gender"
                                {...register('gender')}
                                defaultChecked={getValues('gender') === 'F'}
                            />女性
                            <input type="radio" value="O" id="genderO" name="gender"
                                {...register('gender')}
                                defaultChecked={getValues('gender') === 'O'}
                            />その他
                            {errors.gender && <span className='errorMessage'>{errors.gender.message}</span>}
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>生年月日</label>
                        <div className='contentZone'>
                            <div className='birthZone'>
                                <div className='year'>
                                    <select
                                        {...register('birthYear')}
                                        className={`selectField year ${errors.birthYear ? 'error' : ''}`}
                                        value={selectedYear}
                                        onChange={changeYear}
                                    >
                                        {years?.map((year) => (
                                            <option key={`birthYear-${year}`} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                    <span>年</span>
                                </div>
                                <div className='month'>
                                    <select
                                        {...register('birthMonth')}
                                        className={`selectField month ${errors.birthMonth ? 'error' : ''}`}
                                        value={selectedMonth}
                                        onChange={changeMonth}
                                    >
                                        {months?.map((month) => (
                                            <option key={`birthMonth-${month}`} value={month}>
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                    <span>月</span>
                                </div>
                                <div className='day'>
                                    <select
                                        {...register('birthDay')}
                                        className={`selectField day ${errors.birthDay ? 'error' : ''}`}
                                        value={selectedDay}
                                        onChange={changeDay}
                                    >
                                        {days?.map((day) => (
                                            <option key={`birthDay-${day}`} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                    <span>日</span>
                                </div>
                                <input
                                    type="hidden" id="birthdate" name="birthdate"
                                    {...register('birthdate')}
                                />
                            </div>
                            {errors.birthdate && <span className='errorMessage'>{errors.birthdate.message}</span>}
                        </div>
                    </InputItem>
                    <InputItem>
                        <label className='titleZone'>都道府県</label>
                        <div className='contentZone'>
                            <div className='inputField'>
                                <select
                                    id="prefecture"
                                    name="prefecture"
                                    className={`selectField ${errors.prefecture ? 'error' : ''}`}
                                    {...register('prefecture')}
                                    defaultValue={getValues('prefecture')}
                                >
                                    {Prefectures?.map((prefecture) => (
                                        <option value={prefecture.name}>{prefecture.name}</option>
                                    ))}
                                </select>
                                {errors.prefecture && <span className='errorMessage'>{errors.prefecture.message}</span>}
                            </div>
                        </div>
                    </InputItem>
                    <div className='ButtonZone'>
                        <button type="submit" className='UpdateButton' disabled={!isValid}>作成</button>
                    </div>
                </ProfileInfo>

            </CreateForm >
        </>
    )
}

export default CreateUserForm;