import React from 'react';
import { LuAperture } from "react-icons/lu";
import { TopHeaderTheme } from '../../../themes/Pages/Login/TopHeaderTheme';

const TopHeader = () => {

  return (
    <TopHeaderTheme>
      <div className='logoContainer'>
        <LuAperture className='titleLogo' size={24} />
      </div>
    </TopHeaderTheme>
  );
};

export default TopHeader;