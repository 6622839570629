import styled from '@emotion/styled';

export const UserIncRateCard = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    height: calc(100% - 10px);
    justify-content: space-between;
    border: 1px solid ${props => props.theme.border};
    border-radius: 10px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 0px 10px 10px 10px;
    
    .Select {
        height: 40px;
        border: 1px solid ${props => props.theme.border};
        border-radius: 5px;
    }

    .Title {
        font-size: 15px;
        color: ${props => props.theme.text};
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Value {
        font-size: 20px;
        font-weight: bold;
        color: ${props => props.theme.text};
        p{
            margin: 0px;
        }
    }
`;