import styled from '@emotion/styled';

export const ContentRow1 = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
`;

export const TotalUserCard = styled.div`
    width: 100%;
    height: 100%;
`;

export const UserIncRateCard = styled.div`
    width: 100%;
    height: 100%;
`;

export const ActiveUserCard = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContentRow2 = styled.div`
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: fit-content;
`;

export const NewsCard = styled.div`
    width: 100%;
    height: 100%;
`;

export const RecentRegistrationCard = styled.div`
    width: 100%;
    height: 100%;
`;

