import React, { useEffect, useState, useCallback } from 'react';
import { fetchUserPageProfile } from '../../../api/UserManagement/userPageProfile';
import { useNavigate} from 'react-router-dom';
import { UserInfoContainer, UserInfoTitle, UserInfoItem, StatusDiv } from '../../../themes/Pages/UserManagement/UserPageProfileTheme';
import { formatDateDateAndTime, formatDateDateOnly } from '../../../utils/DateUtils';
import { fetchAccessTypeList } from '../../../api/Common/common';

const UserPageProfile = ({ userId, setUserName}) => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [accessTypeList, setAccessTypeList] = useState([]);

    useEffect(() => {
        const loadAccessTypeList = async () => {
            const list = await fetchAccessTypeList();
            setAccessTypeList(list);
        };
        loadAccessTypeList();
    }, []);

    const getUserPageProfile = useCallback(async () => {
        try {
            const userPageProfile = await fetchUserPageProfile(navigate, userId);
            setProfile(userPageProfile?.profile);
            setUserName(userPageProfile?.profile?.userLastName + userPageProfile?.profile?.userFirstName);
        } catch (error) {
            console.error('APIエラー：ユーザーページプロフィールの取得', error);
            navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーページプロフィールの取得に失敗しました' } });
        }
    }, [navigate, userId, setUserName]);

    useEffect(() => {
        getUserPageProfile();
    }, [getUserPageProfile]);

    return (
        <>
            <UserInfoContainer>
                <UserInfoTitle>アカウント情報</UserInfoTitle>
                <UserInfoItem>
                    <label>メールアドレス：</label>
                    <span>{profile?.mail}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>利用者区分：</label>
                    <span>{profile?.categoryName}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>アクセス区分：</label>
                    <span>{accessTypeList.find(type => type.accessTypeCode === profile?.accessTypeCode)?.accessTypeName}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>会員区分：</label>
                    <span>{profile?.memberTypeName}</span>
                </UserInfoItem>
                <StatusDiv status={profile?.loginStatus}>
                    <label>利用可否：</label>
                    <span>{profile?.memberTypeCode === '02' ? '未入会' 
                        : profile?.deleteFlg === '0' ? '入会中'
                        : profile?.leaveTypeCode ? '退会済(' + profile?.leaveTypeName + ')' : ''}</span>
                </StatusDiv>
                <UserInfoItem>
                    <label>登録日時：</label>
                    <span>{profile?.createDate ? formatDateDateAndTime(profile?.createDate) : '登録日時データ無し'}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>最終ログイン日時：</label>
                    <span>{profile?.loginHistory ? formatDateDateAndTime(profile?.loginHistory) : 'ログイン履歴無し'}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>最終更新日時：</label>
                    <span>{profile?.updateDate ? formatDateDateAndTime(profile?.updateDate) : '更新日時データ無し'}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>最終更新者：</label>
                    <span>{profile?.updaterLastName} {profile?.updaterFirstName}</span>
                </UserInfoItem>

                <UserInfoTitle>プロフィール情報</UserInfoTitle>
                <UserInfoItem>
                    <label>氏名：</label>
                    <span>{profile?.userLastName} {profile?.userFirstName}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>ニックネーム：</label>
                    <span>{profile?.nickName}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>性別：</label>
                    <span>{profile?.gender === 'M' ? '男性' 
                        : profile?.gender === 'F' ? '女性' 
                        : profile?.gender === 'O' ? 'その他' : ''}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>生年月日：</label>
                    <span>{profile?.birthDate ? formatDateDateOnly(profile?.birthDate) : ''}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>都道府県：</label>
                    <span>{profile?.prefecture}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>電話番号：</label>
                    <span>{profile?.phoneNumber}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>学校種別：</label>
                    <span>{profile?.schoolType}</span>
                </UserInfoItem>
                <UserInfoItem>
                    <label>学校名：</label>
                    <span>{profile?.schoolName}</span>
                </UserInfoItem>
            </UserInfoContainer>
        </>
    );
};

export default UserPageProfile;