import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import SideMenu from '../SideMenu/SideMenu';
import { useState, useEffect, useCallback } from 'react';
import Header from '../Header/Header';
import { AdminPanelContainer, MainContents } from '../../themes/WholeDesignTheme/AdminPanelTheme';
import { fetchUserCategoryList, fetchAccessTypeList, fetchAdminMemberTypes, fetchMemberTypes } from '../../api/Common/common';
import { getSideMenuSummary, getSideMenuPageList } from '../../api/SideMenu/sideMenu';

const AdminPanel = () => {
    const navigate = useNavigate();
    //選択されたページ
    const [selectedMenu, setSelectedMenu] = useState(null);
    //会員区分リスト
    const [memberTypeList, setMemberTypeList] = useState([]);
    //管理者会員区分リスト
    const [adminMemberTypeList, setAdminMemberTypeList] = useState([]);
    //メニューの表示/非表示
    const [toggleButton, setToggleButton] = useState(false);
    //ログインした管理者情報
    const [adminUserInfo, setAdminUserInfo] = useState(null);
    //ユーザーカテゴリリスト
    const [userCategoryList, setUserCategoryList] = useState([]);
    //アクセス区分リスト
    const [accessTypeList, setAccessTypeList] = useState([]);
    //サイドメニューのサマリー
    const [sideMenuSummary, setSideMenuSummary] = useState(null);
    //サイドメニューのページリスト
    const [sideMenuPageList, setSideMenuPageList] = useState([]);

    //管理者情報を取得
    const fetchAdminUserInfo = useCallback(async () => {
        try {
            const adminUserInfo = await fetch(`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_ENDPOINT_GET_ADMIN_USER_INFO}`, {
                method: 'GET',
                credentials: 'include',
            });

            const adminUserInfoData = await adminUserInfo.json();
            if (adminUserInfo.ok) {
                setAdminUserInfo(adminUserInfoData);
                // 管理者会員区分に対応したサイドメニューのサマリーとページリストを取得
                if (adminUserInfoData && adminUserInfoData.adminMemberTypeCode) {
                    // サイドメニューのサマリーを取得
                    try {
                        const sideMenuSummary = await getSideMenuSummary(navigate, adminUserInfoData.adminMemberTypeCode);
                        if (sideMenuSummary) {
                            setSideMenuSummary(sideMenuSummary);
                        }
                    } catch (error) {
                        console.error('Side menu summary error:', error);
                        navigate('/error', { state: { statusCode: 500, errorMessage: 'サイドメニューのサマリー取得に失敗しました' } });
                    }
                    
                    // サイドメニューのページリストを取得
                    try {
                        const sideMenuPageList = await getSideMenuPageList(navigate, adminUserInfoData.adminMemberTypeCode);
                        if (sideMenuPageList) {
                            setSideMenuPageList(sideMenuPageList);  
                        }
                    } catch (error) {
                        console.error('Side menu page list error:', error);
                        navigate('/error', { state: { statusCode: 500, errorMessage: 'サイドメニューのページリスト取得に失敗しました' } });
                    }
                } else {
                    console.error('Missing adminMemberTypeCode in user data:', adminUserInfoData);
                    navigate('/error', { state: { statusCode: 401, errorMessage: '管理者会員区分情報が見つかりません' } });
                }
            } else {
                navigate('/error', { state: { statusCode: 401, errorMessage: 'ユーザー情報の取得に失敗しました' } });
            }
        } catch (error) {
            console.error('APIエラー：ユーザー情報の取得', error);
            navigate('/error', { state: { statusCode: 500, errorMessage: 'ユーザー情報の取得に失敗しました' } });
        }
    }, [navigate]);

    // 会員区分リストを取得
    const getMemberTypes = useCallback(async () => {
        try {
            const memberTypes = await fetchMemberTypes(navigate);
            setMemberTypeList(memberTypes);
        } catch (error) {
            navigate('/error', { state: { statusCode: 500, errorMessage: '会員区分リストの取得に失敗しました' } });
        }
    }, [navigate]);

    // 管理者会員区分リストを取得
    const getAdminMemberTypes = useCallback(async () => {
        try {
            const adminMemberTypes = await fetchAdminMemberTypes(navigate);
            setAdminMemberTypeList(adminMemberTypes);
        } catch (error) {
            navigate('/error', { state: { statusCode: 500, errorMessage: '管理者会員区分リストの取得に失敗しました' } });
        }
    }, [navigate]);

    // ユーザーカテゴリリストを取得
    const getUserCategoryList = useCallback(async () => {
        try {
            const categories = await fetchUserCategoryList(navigate);
            setUserCategoryList(categories);
        } catch (error) {
            navigate('/error', { state: { statusCode: 500, errorMessage: 'ユーザーカテゴリリストの取得に失敗しました' } });
        }
    }, [navigate]);

    // アクセス区分リストを取得
    const getAccessTypeList = useCallback(async () => {
        try {
            const accessTypes = await fetchAccessTypeList(navigate);
            setAccessTypeList(accessTypes);
        } catch (error) {
            navigate('/error', { state: { statusCode: 500, errorMessage: 'アクセス区分リストの取得に失敗しました' } });
        }
    }, [navigate]);

    // トークンの存在を確認
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/error', { state: { statusCode: 401, errorMessage: '認証情報が存在しません' } });
            return; // APIリクエストを実行しない
        }

        // トークンが存在する場合のみAPIリクエストを実行
        getMemberTypes();
        getAdminMemberTypes();
        fetchAdminUserInfo();
        getUserCategoryList();
        getAccessTypeList();
    }, [getMemberTypes, getAdminMemberTypes, fetchAdminUserInfo, getUserCategoryList, getAccessTypeList, navigate]);


    return (
        <>
            <Header adminUserInfo={adminUserInfo} />
            <AdminPanelContainer>
                <SideMenu selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} toggleButton={toggleButton} setToggleButton={setToggleButton} sideMenuSummary={sideMenuSummary} sideMenuPageList={sideMenuPageList} />
                <MainContents isOpen={toggleButton}>
                    <Outlet context={{ memberTypeList, setMemberTypeList, setSelectedMenu, userCategoryList, setUserCategoryList, accessTypeList, setAccessTypeList, adminMemberTypeList, setAdminMemberTypeList, adminUserInfo, sideMenuSummary, sideMenuPageList }} />
                </MainContents>
            </AdminPanelContainer>
        </>
    );
};

export default AdminPanel;