import styled from '@emotion/styled';

export const BulkDeleteModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;

    .ModalCard {
        background-color: ${props => props.theme.systemBackground};
        padding: 20px;
        border-radius: 10px;
    
        .BulkDeleteModalTitle {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
        }

        .BulkDeleteModalButtonContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .BulkDeleteModalCancelButton {
            background-color: ${props => props.theme.background};
            color: ${props => props.theme.text};
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid ${props => props.theme.border};
            cursor: pointer;

            &:hover {
                background-color: ${props => props.theme.backgroundHover};
            }
        }

        .BulkDeleteModalDeleteButton {
            background-color: ${props => props.theme.error};
            color: ${props => props.theme.buttonText};
            padding: 10px 20px;
            border-radius: 5px;
            border: 1px solid ${props => props.theme.border};
            cursor: pointer;
            margin-left: 10px;

            &:hover {
                background-color: ${props => props.theme.errorHover};
            }
        }
    }
`;