import React, { useState, useEffect } from 'react';
import CommonLayout from '../../WholeDesign/CommonLayout';
import LearningProgressSearch from './LearningProgressSearch';
import LearningProgressResult from './LearningProgressResult';
import { useOutletContext } from 'react-router-dom';
import Pagination from '../CommonDesign/Pagination';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { SyncLoader } from 'react-spinners';

const LearningProgress = () => {

    const [page, setPage] = useState(1);
    const [searchResult, setSearchResult] = useState(null);
    const { memberTypeList, userCategoryList, setSelectedMenu, sideMenuPageList } = useOutletContext();
    const [progressReverse, setProgressReverse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSelectedMenu(sideMenuPageList.find(item => item.subMenuCode === '02')?.id);
    }, [setSelectedMenu, sideMenuPageList]);

    const header = (
        <>
            <h2 className="title">学習進捗検索</h2>
        </>
    );
    const content = (
        <>
            <LearningProgressSearch
                memberTypeList={memberTypeList}
                setSearchResult={setSearchResult}
                page={page}
                setPage={setPage}
                progressReverse={progressReverse}
                setIsLoading={setIsLoading}
            />
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                    <SyncLoader color="#36d7b7" />
                </div>
            ) : searchResult === null || searchResult.userList.length === 0 ? (
                <div style={{ textAlign: 'center', fontSize: '20px', color: '#ff4d4d', margin: '20px' }}>
                    データがありません
                </div>
            ) : (
                <>
                    <LearningProgressResult
                        memberTypeList={memberTypeList}
                        userCategoryList={userCategoryList}
                        searchResult={searchResult}
                        progressReverse={progressReverse}
                        setProgressReverse={setProgressReverse}
                    />
                    {searchResult.userCount > UserDetailDisplayLimitList[0] && (
                        <Pagination
                            currentPage={page}
                            totalPages={Math.ceil(searchResult.userCount / UserDetailDisplayLimitList[0])}
                            onPageChange={setPage}
                        />
                    )}
                </>
            )}
        </>
    );

    return (
        <>
            <CommonLayout
                header={header}
                content={content}
            />
        </>
    );
};

export default LearningProgress;