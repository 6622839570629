import React, { useState, useEffect } from 'react';
import {
    QuestionHistorySearchContainer,
    QuestionWordContainer,
    QuestionTimingContainer,
    QuestionLanguageContainer,
    KindOfQuestionContainer,
    SearchButtonContainer,
    StatusContainer
} from '../../../themes/CommonDesignTheme/QuestionHistorySearchTheme';
import { fetchPartList } from '../../../api/SearchCriteria/SearchCriteria';
import { fetchChatRoom } from '../../../api/UserManagement/userQuestionHistory';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';
import { useNavigate } from 'react-router-dom';

const QuestionHistorySearch = ({ userId, setSearchResults, page, setPage, reverseFLG, setIsLoading }) => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchDateStart, setSearchDateStart] = useState('');
    const [searchDateEnd, setSearchDateEnd] = useState('');
    const [searchPartId, setSearchPartId] = useState('00');
    const [searchKindOfQuestion, setSearchKindOfQuestion] = useState('');
    const [searchStatus, setSearchStatus] = useState('all');
    const [partList, setPartList] = useState([]);

    useEffect(() => {
        fetchPartList(navigate).then((data) => {
            setPartList(data);
        });
    }, [navigate]);

    const handlePartChange = (partId) => {
        setSearchPartId(partId);
    };

    const handleSearchKindOfQuestionChange = (type) => {
        setSearchKindOfQuestion(type);
    };

    const handleSearchStatusChange = (status) => {
        setSearchStatus(status);
    };

    const handleSearch = async (page) => {
        setPage(page);
        setIsLoading(true);
        try {
            const chatRoomList = await fetchChatRoom(
                navigate,
                userId,
                searchKeyword,
                searchDateStart,
                searchDateEnd,
                searchPartId,
                searchKindOfQuestion,
                searchStatus,
                page,
                UserDetailDisplayLimitList[0],
                reverseFLG
            );
            setSearchResults(chatRoomList);
        } catch (error) {
            console.error('Error fetching chat rooms:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleSearch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleSearch(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        setPage(1);
        handleSearch(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reverseFLG]);

    return (
        <QuestionHistorySearchContainer>
            <QuestionTimingContainer>
                <label>質問日:</label>
                <div className="date-container">
                    <input type="date" value={searchDateStart} onChange={(e) => setSearchDateStart(e.target.value)} />
                    <div className="date-separator">～</div>
                    <input type="date" value={searchDateEnd} onChange={(e) => setSearchDateEnd(e.target.value)} />
                </div>
            </QuestionTimingContainer>
            <QuestionWordContainer>
                <label>質問内容:</label>
                <input type="text" placeholder="質問内容を検索..." value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
            </QuestionWordContainer>
            <QuestionLanguageContainer>
                <label>教材:</label>
                <select
                    value={searchPartId}
                    onChange={(e) => handlePartChange(e.target.value)}
                    className="language"
                >
                    <option value="00">すべて</option>
                    {partList?.map((part) => (
                        <option key={part.partId} value={part.partId}>
                            {part.partTitle}
                        </option>
                    ))}
                </select>
            </QuestionLanguageContainer>
            <StatusContainer>
                <label>ステータス:</label>
                <select
                    value={searchStatus}
                    onChange={(e) => handleSearchStatusChange(e.target.value)}
                    className="status"
                >
                    <option value="all">全て</option>
                    <option value="0">入会中</option>
                    <option value="1">退会済</option>
                </select>
            </StatusContainer>
            <KindOfQuestionContainer>
                <label>質問種別:</label>
                <select
                    value={searchKindOfQuestion}
                    onChange={(e) => handleSearchKindOfQuestionChange(e.target.value)}
                    className="kinds"
                >
                    <option value="">全ての種別</option>
                    <option value="0">チャットボット</option>
                    <option value="1">コードレビュー</option>
                </select>
            </KindOfQuestionContainer>
            <SearchButtonContainer>
                <button onClick={() => handleSearch(1)}>検索</button>
            </SearchButtonContainer>
        </QuestionHistorySearchContainer>
    )
}

export default QuestionHistorySearch;