import styled from '@emotion/styled';

export const LoginContainer = styled.div`
    height: 100vh;
    overflow: hidden;

    .loginModal {
        height: calc(100% - 0px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${props => props.theme.systemBackground};
    }

    .loginContent {
        background-color: ${props => props.theme.systemBackground};
        padding: 20px;
        border-radius: 5px;
        width: 440px;
        position: relative;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .loginHeader {
        text-align: center;
        margin-bottom: 40px;

        h2 {
            margin: 0px;
        }

        span {
            font-size: 15px;
            color: ${props => props.theme.text};
        }
    }


    .formGroup {
        margin-bottom: 10px;

        span {
            display: block;
            margin-bottom: 5px;
            font-size: 15px;
        }

        input {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
            border-radius: 5px;
            border: 1px solid ${props => props.theme.border};
            color: ${props => props.theme.text};
        }

        input:focus {
            border: 1px solid ${props => props.theme.primary};
        }
    }

    .errorContainer {
        height: 40px;
        margin: 10px;
    }

    .errorMessage {
        font-size: 14px;
        color: red;
        margin: 0;
    }

    .loginButton {
        width: 100%;
        padding: 10px;
        background-color: ${props => props.theme.primary};
        color: ${props => props.theme.systemBackground};
        border: none;
        cursor: pointer;
        border-radius: 6px;
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 10px;

        &:hover {
            background-color: ${props => props.theme.primaryHover};
        }
    }


    .loginLinks {
        margin: 15px 0;
        display: flex;
        justify-content: center;

        span {
            display: block;
            color: ${props => props.theme.primary};
            font-size: 14px;
        }
    }

`;