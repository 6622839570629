import styled from '@emotion/styled';

export const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UserInfoTitle = styled.div`
    color: ${props => props.theme.text};
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const UserInfoItem = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    margin-bottom: 5px;

    label{
        color: ${props => props.theme.text};
    }
`;

export const StatusDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    margin-bottom: 5px;

    span{   
        width: fit-content;
        font-size: 16px;
        display: flex;
        align-items: center;
    }
`;


