import styled from '@emotion/styled';

export const SideMenuContainer = styled.div`
  height: calc(100vh - 50px);
  width: ${props => props.toggleButton ? '70px' : '250px'};
  overflow: ${props => props.toggleButton ? 'hidden' : 'visible'};
  transition: width 0.3s ease-in-out;
  position: relative;
  background-color: ${props => props.theme.background};
  border-right: 1px solid ${props => props.theme.border};
  display: flex;
  flex-direction: column;

  .LinkIcon {
    width: ${props => props.toggleButton ? '50px' : 'auto'};
  }
  
  .LinkText {
    display: ${props => props.toggleButton ? 'none' : 'block'};
  }
`;

export const UncleAunt = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: calc(100% - 20px);
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  background-color: ${props => props.isSelected ? props.theme.backgroundHover : 'transparent'};

  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${props => props.theme.text};
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .LinkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin: auto;
    height: auto;
  }

  .LinkText {
    display: flex;
    align-items: center;
    margin-left: 10px;
    width: calc(80% - 10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Mother = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: calc(100% - 30px);
  border-radius: 5px;
  margin: 10px;
  padding: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }

  .mother-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.toggleButton ? '40px' : '20%'};
  }

  .mother-text {
    display: ${props => props.toggleButton ? 'none' : 'flex'};
    align-items: center;
    margin-left: 10px;
    width: calc(80% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .UpDownIcon {
    display: ${props => props.toggleButton ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    width: 50px;
  }
`;

export const Children = styled.div`
  position: relative;
  overflow: hidden;
  height: ${props => props.open ? 'fit-content' : '0'};
  transition: height 0.3s ease;
  padding: 0 10px;

  .LinkIcon {
    margin-left: ${props => props.toggleButton ? '0' : '15px'};
    padding: ${props => props.toggleButton ? '0' : 'inherit'};
    flex-shrink: 0;
  }
  
  .LinkText {
    width: calc(80% - 15px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Child = styled.div`
  width: 100%;
  height: 40px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0px 5px 0px;
  background-color: ${props => props.isSelected ? props.theme.backgroundHover : 'transparent'};
  overflow: ${props => props.toggleButton ? 'hidden' : 'visible'};

  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${props => props.theme.text};
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .LinkIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${props => props.toggleButton ? '50px' : '20%'};
  }

  .LinkText {
    display: ${props => props.toggleButton ? 'none' : 'flex'};
    align-items: center;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ToggleButton = styled.div`
  flex: 1;
  position: absolute;
  bottom: 20px;
  right: 10px;
  cursor: pointer;

  .toggle-icon {
    position: relative;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SideMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${props => props.isSelected ? props.theme.backgroundHover : 'transparent'};

  &:hover {
    background-color: ${props => props.theme.backgroundHover};
  }
`;