
export const fetchChatRoom = async (navigate, userId, searchKeyword, searchDateStart, searchDateEnd, searchPartId, searchKindOfQuestion, searchStatus, page, limit, reverseFLG) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const chatRoomUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_CHAT_ROOM}`;

        // リクエストボディの作成
        const requestBody = {};
        if (userId) requestBody.userId = userId;
        if (searchKeyword) requestBody.searchKeyword = searchKeyword;
        if (searchDateStart) requestBody.searchDateStart = searchDateStart;
        if (searchDateEnd) requestBody.searchDateEnd = searchDateEnd;
        if (searchPartId) requestBody.searchPartId = searchPartId;
        if (searchKindOfQuestion) requestBody.searchKindOfQuestion = searchKindOfQuestion;
        if (searchStatus) requestBody.searchStatus = searchStatus;
        if (page) requestBody.page = page;
        if (limit) requestBody.limit = limit;
        requestBody.reverseFLG = reverseFLG;

        const response = await fetch(chatRoomUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(requestBody),
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: 'チャットルームの取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：チャットルームの取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'チャットルームの取得に失敗しました' } });
    }
};

export const fetchChatHistoryData = async (navigate, userId, conversationId) => {
    try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}/`;
        const chatHistoryUrl = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_GET_CHAT_HISTORY}?userId=${userId}&conversationId=${conversationId}`;

        const response = await fetch(chatHistoryUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include'
        });

        if (response.ok) {
            return await response.json();
        } else if (response.status === 401) {
            navigate('/login');
        } else {
            navigate('/error', { state: { statusCode: response.status, errorMessage: '質問履歴の取得に失敗しました' } });
        }
    } catch (error) {
        console.error('APIエラー：質問履歴の取得', error);
        navigate('/error', { state: { statusCode: '', errorMessage: '質問履歴の取得に失敗しました' } });
    }
};
