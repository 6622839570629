import styled from '@emotion/styled';

export const NotificationModalContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: ${props => props.theme.systemBackground};
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    min-width: 300px;
    max-width: 600px;
    text-align: center;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;    

    background-color: ${props => 
        props.modalClass === 'SuccessNotification' 
            ? props.theme.success
            : props.modalClass === 'ErrorNotification'
                ? props.theme.error
                : 'transparent'
    };

    .Message {
        flex-grow: 1;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .CloseButton {
        background-color: transparent;
        color: ${props => props.theme.systemBackground};
        border: none;
        width: 30px;
        height: 30px;
        font-size: 16px;
        cursor: pointer;
        padding: 5px;
        border-radius: 50%;
        transition: background-color 0.3s;
    }

    .CloseButton:hover {
        background-color: ${props => props.theme.backgroundHover};
    }
`;
