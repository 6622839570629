import React, { useEffect, useState } from 'react';
import { adminUserListSearchUser } from '../../../api/UserManagement/adminUserList';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AdminUserListSearchContainer } from '../../../themes/Pages/UserManagement/AdminUserListSearchTheme';
import { UserDetailDisplayLimitList } from '../../../utils/Constants';

const AdminUserListSearch = ({
    searchInput,
    setSearchInput,
    selectedAdminMemberType,
    setSelectedAdminMemberType,
    setSearchResults,
    adminMemberTypeList,
    page,
    setPage,
    setIsLoading }) => {
    const { accessTypeList } = useOutletContext();
    const navigate = useNavigate();
    const [selectedAccessType, setSelectedAccessType] = useState('00');
    const [selectedStatus, setSelectedStatus] = useState('all');

    const handleSearch = async (page) => {
        setPage(page);
        setIsLoading(true);
        try {
            const results = await adminUserListSearchUser(
                navigate,
                searchInput,
                selectedAccessType,
                selectedAdminMemberType,
                selectedStatus,
                page,
                UserDetailDisplayLimitList[0]
            );
            if (results) {
                setSearchResults(results);
            }
        } catch (error) {
            console.error('APIエラー：ユーザー検索', error);
            navigate('/error', { state: { statusCode: '', errorMessage: '検索結果の取得に失敗しました' } });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleSearch(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <AdminUserListSearchContainer>
            <div className='InputArea'>
                <label>氏名またはメールアドレス</label>
                <input type='text' placeholder='氏名またはメールアドレス' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
            </div>
            <div className='SelectArea'>
                <label>管理者会員区分</label>
                <select value={selectedAdminMemberType} onChange={(e) => setSelectedAdminMemberType(e.target.value)}>
                    <option value='00'>全て</option>
                    {adminMemberTypeList?.map((memberType) => (
                        <option key={memberType.adminMemberTypeCode} value={memberType.adminMemberTypeCode}>{memberType.adminMemberTypeName}</option>
                    ))}
                </select>
            </div>
            <div className='SelectArea'>
                <label>ステータス</label>
                <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                    <option value='all'>全て</option>
                    <option value='0'>在籍中</option>
                    <option value='1'>退会済</option>
                </select>
            </div>
            <div className='SelectArea'>
                <label>アクセス区分</label>
                <select value={selectedAccessType} onChange={(e) => setSelectedAccessType(e.target.value)}>
                    <option value='00'>全て</option>
                    {accessTypeList?.map((accessType) => (
                        accessType.deleteFlg === '0' && (
                            <option key={accessType.accessTypeCode} value={accessType.accessTypeCode}>{accessType.accessTypeName}</option>
                        )
                    ))}
                </select>
            </div>
            <div className='ButtonArea'>
                <button onClick={() => handleSearch(1)}>検索</button>
            </div>
        </AdminUserListSearchContainer>
    );
};

export default AdminUserListSearch;