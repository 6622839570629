export const createUser = async (navigate, data) => {
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/`
    const url = `${baseUrl}${process.env.REACT_APP_API_ENDPOINT_CREATE_TEST_USER}`;
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data)
        });
        if (response.ok) {
            return response.json();
        } else {
            navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの登録に失敗しました' } });
        }
    } catch (error) {
        console.error('createUser.error:', error);
        navigate('/error', { state: { statusCode: '', errorMessage: 'ユーザーの登録に失敗しました' } });
    }
}