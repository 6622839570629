import React, { useState, useEffect } from 'react';
import { InviteCodeGenerateContainer, GenerateCodeContainer, Wrapper, ButtonWrapper } from '../../../themes/Pages/OperationManagement/InviteCodeGenerateTheme';
import { insertInviteCode } from '../../../api/OperationManagement/inviteCode';

const InviteCodeGenerate = ({ memberTypeList, navigate, setErrorMessage, setNotificationMessage, setNotificationType, setIsNotificationOpen }) => {

    const [issueCount, setIssueCount] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedMemberTypeCode, setSelectedMemberTypeCode] = useState('');

    // 年の選択肢を生成（現在の年から5年分）
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // 0-based indexを1-basedに変換
    const currentDay = new Date().getDate();
    const years = Array.from({ length: 6 }, (_, i) => currentYear + i);

    // 月の選択肢を生成（現在の年の場合は現在の月以降のみ）
    const getAvailableMonths = () => {
        if (selectedYear === currentYear.toString()) {
            return Array.from({ length: 13 - currentMonth }, (_, i) => currentMonth + i);
        }
        return Array.from({ length: 12 }, (_, i) => i + 1);
    };

    // 選択された年月に基づいて日の選択肢を生成
    const getAvailableDays = () => {
        if (!selectedYear || !selectedMonth) return [];
        
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        let startDay = 1;
        
        // 現在の年月が選択されている場合、現在の日付以降のみを表示
        if (parseInt(selectedYear) === currentYear && parseInt(selectedMonth) === currentMonth) {
            startDay = currentDay;
        }
        
        return Array.from(
            { length: daysInMonth - startDay + 1 },
            (_, i) => startDay + i
        );
    };

    // 日付が変更されたときに有効期限を更新
    const updateExpiryDate = (year, month, day) => {
        if (year && month && day) {
            const formattedMonth = month.toString().padStart(2, '0');
            const formattedDay = day.toString().padStart(2, '0');
            setExpiryDate(`${year}-${formattedMonth}-${formattedDay}`);
        } else {
            setExpiryDate('');
        }
    };

    // 年が変更されたときの処理
    const handleYearChange = (e) => {
        const newYear = e.target.value;
        setSelectedYear(newYear);
        setSelectedMonth('');
        setSelectedDay('');
    };

    // 月が変更されたときの処理
    const handleMonthChange = (e) => {
        const newMonth = e.target.value;
        setSelectedMonth(newMonth);
        setSelectedDay('');
    };

    const handleGenerateCode = async (e) => {
        e.preventDefault();
        if (!issueCount && !expiryDate) {
            setErrorMessage('発行件数と有効期限を入力してください。');
            return;
        } else if (!issueCount) {
            setErrorMessage('発行件数を入力してください。');
            return;
        } else if (!expiryDate) {
            setErrorMessage('有効期限を入力してください。');
            return;
        }
        const response = await insertInviteCode(navigate, selectedMemberTypeCode, issueCount, expiryDate);
        if (response) {
            setNotificationMessage(response.message);
            setNotificationType(response.success ? 'success' : 'error');
            setIsNotificationOpen(true);

            if (response.success) {
                localStorage.setItem('notificationMessage', response.message);
                window.location.reload();
            }
        }
    };

    useEffect(() => {
        if (memberTypeList?.length > 0 && !selectedMemberTypeCode) {
            setSelectedMemberTypeCode(memberTypeList[0]?.memberTypeCode);
        }
        //eslint-disable-next-line
    }, [memberTypeList]);

    useEffect(() => {
        updateExpiryDate(selectedYear, selectedMonth, selectedDay);
    }, [selectedYear, selectedMonth, selectedDay]);

    return (
        <InviteCodeGenerateContainer>
            <GenerateCodeContainer onSubmit={handleGenerateCode}>
                <Wrapper>
                    <label>会員区分</label>
                    <select
                        className="valueInput"
                        onChange={(e) => setSelectedMemberTypeCode(e.target.value)}
                        value={selectedMemberTypeCode}
                    >
                        {memberTypeList?.length > 0 ? (
                            memberTypeList?.map((memberType) => (
                                <option key={memberType?.memberTypeCode} value={memberType?.memberTypeCode}>
                                    {memberType?.memberTypeName}
                                </option>
                            ))
                        ) : (
                            <option>Loading...</option>
                        )}
                    </select>
                </Wrapper>
                <Wrapper>
                    <label>発行件数</label>
                    <input
                        type="number"
                        className="valueInput"
                        min="1"
                        onChange={(e) => setIssueCount(e.target.value)}
                    />
                </Wrapper>
                <Wrapper>
                    <label>有効期限</label>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <select
                            className="valueInput"
                            style={{ width: '100px' }}
                            value={selectedYear}
                            onChange={handleYearChange}
                        >
                            <option value="">年</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}年
                                </option>
                            ))}
                        </select>
                        <select
                            className="valueInput"
                            style={{ width: '80px' }}
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            disabled={!selectedYear}
                        >
                            <option value="">月</option>
                            {getAvailableMonths().map((month) => (
                                <option key={month} value={month}>
                                    {month}月
                                </option>
                            ))}
                        </select>
                        <select
                            className="valueInput"
                            style={{ width: '80px' }}
                            value={selectedDay}
                            onChange={(e) => setSelectedDay(e.target.value)}
                            disabled={!selectedMonth}
                        >
                            <option value="">日</option>
                            {getAvailableDays().map((day) => (
                                <option key={day} value={day}>
                                    {day}日
                                </option>
                            ))}
                        </select>
                    </div>
                </Wrapper>
                <ButtonWrapper>
                    <button
                        className="GenerateCodeButton"
                        type="submit"
                    >
                        生成
                    </button>
                </ButtonWrapper>
            </GenerateCodeContainer>
        </InviteCodeGenerateContainer>
    );
};

export default InviteCodeGenerate;